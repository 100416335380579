const fb = require('../firebaseConfig')

export default {
  namespaced: true,
  state: {
    products: []
  },
  actions: {
    getProducts ({ commit }) {
      return fb.productsCollection.orderBy('createdOn', 'desc').get()
      .then((res) => {
        const productsArray = []

        res.forEach((doc) => {
          const product = doc.data()
          product._id = doc.id
          productsArray.push(product)
        })

        commit('setProducts', productsArray)
      })
    }
  },
  mutations: {
    setProducts (state, val) {
      state.products = val.products
    }
  },
  getters: {
    slugHelper: state => (slug) => {
      return state.products.filter(products => ((products.slug) ? products.slug.includes(slug) : false)).length
    },
    getProduct: state => (slug) => {
      return state.products.find(product => product.slug === slug)
    }
  }
}

module.exports = {
  no_products: 'Ingen produkter funnet...',

  all_index: {
    image: 'Produktbilde',
    name: 'Produktnavn',
    actions: 'Handlinger',
    showing: 'Viser'
  },

  add_new_form: {
    add_new: 'Legg til nytt produkt',
    add_new_description: 'Opprett et nytt produkt',
    create_product: 'Lagre nytt produkt'
  },

  delete_modal: {
    title: 'Slett produkt',
    desc: 'Slett et produkt fra databasen eller bare fra visningen',
    buttons: {
      soft_delete: 'Slett produktet',
      hard_delete: 'Slett permanent',
      deleting: 'Sletter...'
    },
    success: {
      soft: 'Produktet ble slettet',
      hard: 'Produktet ble slettet og fjernet!'
    }
  }
}

const fb = require('../firebaseConfig')

export default {
  namespaced: true,
  state: {
    prospects: []
  },
  actions: {
    getProspects ({ commit }) {
      return fb.prospectsCollection.orderBy('createdOn', 'desc').get()
      .then((res) => {
        const prospects = []

        res.forEach((doc) => {
          const prospect = doc.data()
          prospect._id = doc.id
          prospects.push(prospect)
        })

        commit('setProspects', { prospects })
      })
    }
  },
  mutations: {
    setProspects (state, val) {
      state.prospects = val.prospects
    }
  },
  getters: {
    slugHelper: state => (projectId, slug) => {
      return state.prospects.filter((prospect) => {
        const thisSlug = prospect.slug ? prospect.slug.includes(slug) : false
        const thisProjectId = prospect.projectId ? prospect.projectId === projectId : false

        return (thisSlug && thisProjectId)
      }).length
    },
    getProspect: state => (slug) => {
      return state.prospects.find(prospect => prospect.slug === slug)
    },
    getProspects: (state) => {
      return state.prospects
    }
  }

}

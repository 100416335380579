module.exports = {
  none_found: 'Ingen prospekter funnet...',
  create: 'Lag nytt prospekt',
  create_description: 'Lag et nytt prospekt fra en enhet i dette prosjektet',
  prospects_in_this_project: 'Prospekter i dette prosjektet',
  save: 'Lag prospektet',
  statistics_description: 'Detailjert oversikt over prospektets statistikk',
  leads_description: 'Hvem viser interesse for prospektet',
  userconfigurations_description: 'Hva slags tilpasninger har hvilke brukere gjort',
  references_description: 'Hvem sender trafikk til dette prospektet',
  copy_prospect: 'Kopier',
  copy_prospect_desc: 'Kopier prospektet',

  form_copy: {
    name: 'Skriv inn et navn til det nye prospektet',
    name_error: 'Navn kreves og det må være lenger enn 4 bokstaver'
  },

  styles: {
    add_new: 'Legg til en ny stiltype',
    add_new_description: 'Legg til en ny stiltype',
    no_styles: 'Fant ingen stiltyper tilknyttet dette prospektet. Legg til en stiltype, klikk på knappen oppe til høyre.',
    save_style: 'Lagre stiltype',

    form_add_new: {
      error_name_length: 'Navnet må være lenger enn 3 bokstaver',
      error_name_required: 'Stiltypen må ha et navn',
      error_description_required: 'Stiltypen må ha en lang beskrivende tekst',

      name_placeholder: 'Skriv inn navnet på stiltypen',
      description_placeholder: 'Skriv inn en detaljert tekst om stiltypen',

      image: 'Stiltype bilde',
      image_placeholder: 'Velg et bilde',
      image_description: 'Last opp et stiltype presentasjonsbilde'
    }
  },

  landingpage: {
    update_prospect_button: 'Oppdater prospekt',

    title: 'Prospektets tittel',
    title_placeholder: 'Skriv inn en tittel på prospektet',
    title_error_minlength: 'Tittelen på prospektet må være lenger enn 4 bokstaver',

    image: 'Visningsbilde på landingssiden',
    image_description: 'Last opp hovedbildet til landingssiden',

    realestateinformation: 'Eiendomsinformasjon',
    realestateinformation_price: 'Startpris',
    realestateinformation_expenses: 'Utgifter',
    realestateinformation_type: 'Boligtype',
    realestateinformation_ovnership: 'Eieform',
    realestateinformation_bedrooms: 'Soverom',
    realestateinformation_primary_size: 'Primærrom',
    realestateinformation_usage_size: 'Bruksareal',
    realestateinformation_floors: 'Etasjer',
    realestateinformation_rooms: 'Rom',
    realestateinformation_energy: 'Energimrk',

    select: 'Velg fra listen',

    contactinfo: 'Kontaktinformasjon',
    contactinfo_person: 'Kontaktperson',
    contactinfo_name_placeholder: 'Skriv inn hele navnet til kontaktpersonen',
    contactinfo_jobtitle_placeholder: 'Skriv inn personens arbeidstittel',
    contactinfo_email_placeholder: 'Skriv inn personens epost',
    add_contact: 'Legg til en kontakt',

    description_placeholder: 'Skriv inn en salgstext til prospektet',

    notifications: {
      update_ok: 'Landingssiden ble oppdatert!'
    },

    add_new_content: 'Legg til innhold',
    choose_block: 'Velg landingssideinnhold',
    reorder_blocks_success: 'Blokkrekkefølgen ble lagret',
    reorder_blocks_error: 'Blokkrekkefølgen ble IKKE endret',
    reorder_images_success: 'Bilderekkefølgen ble lagret',
    reorder_images_error: 'Bilderekkefølgen ble IKKE endret',
    delete_block_success: 'Blokken ble slettet',
    delete_block_error: 'Blokken ble IKKE slettet',
    delete_block_confirm: 'Er du sikker på at du vil slette :item',
    drag_files_to_upload: 'Klikk, eller dra og slipp filer her for å laste de opp',
    are_you_sure: 'Er du sikker?',
    carousel_image_deletion_success: 'Bildet ble slettet',
    carousel_image_deletion_error: 'Bildet ble IKKE slettet',

    carousel_empty: 'Ingen bilder funnet',
    carousel_image_upload_success: 'Bildene ble lastet opp til karusellen',
    carousel_image_upload_error: 'ERROR: Bildene ble IKKE lastet opp til karusellen',

    blocks: {
      carousel: 'Karusell',
      intro: 'Intro',
      salestask: 'Salgsoppgave',
      plans: 'Plantegninger',
      maps: 'Kart',
      tour: '3d tour',
      contactinfo: 'Kontaktinformasjon',
      add_success: 'Blokken ble lagt til',
      add_error: 'En feil oppstod når blokken skulle legges til'
    },

    maps_block: {
      add_coordinates_success: 'Koordinatene ble lagret',
      add_coordinates_error: 'Koordinatene ble lagret',
      save: 'Lagre koordinatene'
    },

    intro_block: {
      save: 'Lagre informasjonen',
      save_success: 'Informasjonen ble lagret!',
      save_error: 'Det oppstod en feil under lagring av informasjonen...',
      description: 'Kun kategorier med data vil vises i prospektet',
      name_placeholder: 'Skriv inn prosjektets navn',
      postal_placeholder: 'XXXX (Postkode)',
      salestext_placeholder: 'Skriv inn en kort salgstekst',
      projectinfo: 'Informasjon',
      projectpricing: 'Økonomi',
      projectdetails: 'Detaljer',
      price: 'Prisantydning',
      publicdebt: 'Fellesgjeld',
      costs: 'Omkostninger',
      totalprice: 'Totalpris',

      details: {
        address: 'Adresse',
        publicexpenses: 'Felleskostnader',
        type: 'Boligtype',
        ownershiptype: 'Eieform',
        primaryspace: 'Primærrom',
        usagearea: 'Bruksareal',
        secondaryspace: 'Sekundærrom',
        bedrooms: 'Soverom',
        rooms: 'Rom',
        grossarea: 'Bruttoareal',
        plotarea: 'Tomteareal',
        built: 'Byggeår',
        floor: 'Etasje',
        energy: 'Energimerking'
      }
    },

    contactinfo_block: {
      companyinfo: 'Firmainformasjon',
      upload_companylogo: 'Last opp en firmalogo',
      contacts: 'Kontaktpersoner',
      add_contact_button: 'Legg til en kontaktperson',
      contactinfo_for_person_number: 'Kontaktinfo for person nr: :personid',
      upload_profileimage: 'Last opp profilbilde',
      save_success: 'Salgstekstblokken ble oppdatert!',
      save_error: 'Det oppstod en feil under lagring.',
      delete_confirm: 'Er du sikker på at du vil slette :name ?',
      delete_confirm_title: 'Er du sikker?',
      delete_success: 'Kontaktpersonen ble slettet',
      delete_error: 'Kontaktpersonen ble IKKE slettet',
      contact_person: 'kontaktpersonen',
      add_phone: 'Legg til et telefonnummer',
      phone_number: 'telefonnummeret',
      delete_number_confirm: 'Er du sikker på at du vil slette :number ?',
      delete_number_success: 'Telefonnummeret ble slettet',
      delete_number_error: 'Telefonnummeret ble IKKE slettet',
      no_phonenumbers: 'Ingen telefonnummer...',
      add_contact_success: 'Kontaktperson ble lagt til',
      add_contact_error: 'Kontaktperson ble IKKE lagt til',
      links: 'Linker',
      add_links_button: 'Legg til en link',
      no_links_found: 'Ingen linker ble funnet.',
      no_emails_found: 'Ingen eposter',
      no_contacts_found: 'Ingen kontaktpersoner ble funnet',
      add_email: 'Legg til en epost',
      delete_email_confirm: 'Er du sikker på at du vil slette eposten?',
      delete_email_confirm_title: 'Er du sikker?',
      delete_email_success: 'Eposten ble slettet!',
      delete_email_error: 'Eposten ble IKKE slettet',
      delete_link_success: 'Linken ble slettet!',
      delete_link_error: 'Linken ble IKKE slettet',
      linkinfo_for_link_number: 'Informasjon om link nr: :linkid',
      link_address: 'Linkaddresse (http)',
      link_title_placeholder: 'Navnet på linken',
      link_href_placeholder: 'https://www.linkendin.no'
    },

    salestask_block: {
      salestask: 'Komplett salgsoppgave',
      save: 'Lagre salgsoppgave',
      save_success: 'Salgsoppgaven ble lagret',
      save_error: 'Salgsoppgaven ble ikke lagret'
    },

    tour_block: {
      intro: '3D Tour detaljer',
      url: 'Link til Metareal 3D visningen',
      url_placeholder: 'https://tour.metareal.com/apps/......'
    }
  },

  form_add_new: {
    unitid: 'Velg enheten som skal prospekteres',
    unitid_placeholder: 'Velg enheten som prospektet skal lages til',
    choose_rooms: 'Velg rommene som skal være med i prospektet',

    errors: {
      unitid: 'Enheten som skal prospekters må velges',
      rooms: 'Minst ett rom må være med i prospektet',
      no_rooms: 'Denne enheten har ingen rom'
    }
  },

  designer: {
    image_uploaded_successfully: 'Bildet ble oppdatert',
    no_styletypes: 'Ingen stiltyper',
    no_categories: 'Ingen kategorier',
    no_folders: 'Ingen mapper',
    choose_category: 'Velg kategori',
    choose_folder: 'Velg mappe',
    product_updated: ':product ble oppdatert!',
    product_updated_failed: ':product ble IKKE oppdatert!',
    no_render: 'Mangler render',
    no_overlay: 'Ingen overlay',
    no_folder_img: 'Ingen mappebilde',
    no_image: 'Mangler bilde',
    add: 'Legg til',
    delete_product_are_you_sure: 'Er du sikker på at du vil slette: :product',
    delete_confirm: 'Bekreft sletting av produktet',
    delete_confirmed: 'Produktet ble slettet',
    linked: 'Koblet',
    not_linked: 'Ingen kobling',
    link_success: 'Produktkoblingen ble opprettet',
    linked_products: 'Linkede produkter',
    remove_link: 'Fjern kobling',
    short_txt: 'Kort produktinformasjon',
    long_txt: 'Lang produktinformasjon',
    no_txt: 'Ingen produktinformasjon',

    form_manage_rooms: {
      updated: ':room ble oppdatert!',
      administrate_rooms: 'Administrer rom',
      administrate_rooms_desc: 'Gi navn til rommene eller endre på rekkefølgen de vises i.',
      custom_name: 'Egendefinert navn',
      update_success: 'Egendefinert romnavn ble oppdatert!',
      update_error: 'Egendefinert romnavn ble ikke oppdatert...',
      delete_room: 'Er du sikker på at du vil slette :roomname',
      delete_room_title: 'Slette rom fra prospekt'
    },

    form_manage_room_products: {
      administrate_products: 'Administrer produkter',
      administrate_products_desc: 'Sett rekkefølgen på produktene',
      product_updated: ':product i kategorien :category ble oppdatert!'
    },

    form_link_products: {
      link_products: 'Koble sammen produkter',
      create_link: 'Opprett en produktkobling',
      roomid_title: 'Velg rommet hvor produktet som skal linkes er',
      roomid_placeholder: 'Velg rom',
      choose_product: 'Velg produkt',
      choose_room_first: 'Velg et rom først...',
      link_products_button: 'Koble sammen',
      link_product_success: 'Produktene ble koblet sammen',
      link_product_removed: 'Produktlinken ble fjernet'
    },

    form_add_room_category: {
      administrate_categories: 'Administrer kategorier',
      administrate_categories_description: 'Legg til eller fjern kategorier',
      add: 'Legg til ny kategori',
      add_placeholder: 'Skriv inn navnet på den nye kategorien',
      no_categories: 'Ingen kategorier funnet, legg til en ny.',
      add_success: 'Kategorien ble lagret'
    },

    form_manage_folders: {
      title: 'Administrer mapper',
      desc: 'Legg til, editer eller fjern mapper'
    },

    form_edit_room_category: {
      update_success: 'Kategorinavnet ble endret',
      update_error: 'Feil under endring av navnet'
    },

    form_add_room_product: {
      add: 'Legg til produkt',
      add_description: 'Legg til eksisterende produkter eller opprett nytt produkt',
      add_new: 'Legg til nytt produkt',
      add_existing: 'Legg til eksisterende produkt',
      filter_on_product_name: 'Søk på prduktnavn'
    },

    form_add_room_new_product: {
      name: 'Produktnavn',
      name_description: 'Skriv inn produktets navn',
      name_min_length: 'Navnet må være lenger enn 2 bokstaver',
      name_required: 'Produktet må ha et navn',
      short_information: 'Kort beskrivelse av produktet',
      short_information_description: 'Skriv inn en kort beskrivelse av produktet',
      long_information: 'Detaljert beskrivelse av produktet',
      long_information_description: 'Skriv inn en detaljert beskrivelse av produktet',
      image: 'Produktbilde',
      image_description: 'Legg til et bilde av produktet',
      image_placeholder: 'Velg bilde',
      product_added_successfully: 'Produktet ble lagt til prospekt og produktdatabase',
      product_added_to_prospect: 'Produktet ble lagt til prospektet',
      product_added_to_prospect_failed: 'Produktet ble ikke lagt til prospektet...'
    },

    form_edit_product: {
      edit: 'Rediger produkt',
      edit_description: 'Endre informasjonen til produktet'
    },

    form_delete_category: {
      delete_category: 'Slett kategorien ":cat"',
      delete_category_description: 'Slett kategorien fra dette rommet i prospektet',
      delete_safe: 'Ingen andre produkter er knyttet til denne kategorien',
      delete_alert: 'Disse produktene er koblet til kategorien og vil miste denne hvis du sletter den',
      delete_confirm: 'Slett kategorien ":cat"',
      delete_confirm_all: 'Slett kategorien ":cat" fra :count produkter',
      delete_success: 'Kategorien ble slettet',
      delete_error: 'Kategorien kunne ikke slettes'
    }
  },

  maptool: {
    tool_global_settings: 'Innstillinger',
    tiles_uploaded_successfully: 'Kartet er klart!',
    zoomlevel: 'Kartlag :layer',
    add_map_layer: 'Legg til et kartlag',
    no_levels_added: 'Ingen kartlag funnet. Legg til et nytt fra knappen over.',
    added_level_successfully: 'Kartlaget ble opprettet',
    added_level_failed: 'Det oppstod en feil ved opprettelse av kartlaget',
    no_map_levels_found: 'Ingen kartlag',
    image_not_divisible: 'Bildet kan ikke deles på 256 - endre størrelse!'
  }
}

module.exports = {
  add_project: 'Nytt prosjekt',
  information: 'Prosjektinformasjon',
  information_description: 'Legg til informasjon om prosjektet',
  name_placeholder: 'Skriv inn prosjektets navn',
  description: 'Beskrivelse',
  description_placeholder: 'Skriv en kort beskrivelse av prosjektet',
  add_project_button: 'Opprett prosjektet',
  image: 'Prosjektbilde',
  image_placeholder: 'Velg et bilde',
  image_description: 'Last opp et presentasjonsbilde',
  image_success: 'Bildet ble lastet opp og lagret',
  project_type: 'Prosjekttype',
  project_type_desc: 'Velg prosjekttype',
  people_description: 'Kontaktpersoner fra forskjellige leverandører',
  go_to_planning: 'Gå til førprosjektering',
  go_to_sales: 'Gå til salg',
  units_in_this_project: 'Enheter i dette prosjektet',
  eventslog_description: 'De siste hendelsene',
  tasks_description: 'Kommende oppgaver',
  roles_description: 'Personer i dette prosjektet',

  form_errors: {
    name: 'Navnet må være lenger enn 4 bokstaver',
    type: 'Du må velge en prosjekttype.'
  },

  settings_description: 'Endre instillinger for prosjektet her',

  tools: {
    map: {
      image_placeholder: 'Last opp et bilde',
      button_text: 'Velg bilde'
    }
  }
}

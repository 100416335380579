module.exports = {
  all_projects: 'Alle prosjekter',
  add_new_project: 'Legg til nytt prosjekt',
  all_units: 'Alle enheter',
  add_new_unit: 'Ny enhet',
  all_products: 'Alle produkter',
  add_new_product: 'Legg til nytt produkt',
  all_users: 'Alle brukere',
  add_new_user: 'Legg til ny bruker',
  all_suppliers: 'Alle leverandører',
  add_new_supplier: 'Legg til ny leverandør'
}

import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'
import 'firebase/analytics'

let envsettings = {}

if (process.env.VUE_APP_NODE_ENV === 'prod' || process.env.VUE_APP_NODE_ENV === 'prodlive') {
  // Vary-Frontend (production!)
  envsettings = {
    apiKey: 'AIzaSyCAO6p4pXKAW76gXafwQ5pIOBbV94o6Uew',
    authDomain: 'houseofliving-v1-prod.firebaseapp.com',
    projectId: 'houseofliving-v1-prod',
    storageBucket: 'houseofliving-v1-prod.appspot.com',
    messagingSenderId: '72066971565',
    appId: '1:72066971565:web:2001136e007b0a31569324',
    measurementId: 'G-9YVHCQBN24'
  }
} else {
  // Vary-Frontend-Development (default)
  envsettings = {
    apiKey: 'AIzaSyAU8u0nixWyxZkXFIPXCcKqSEtp0HFqV-I',
    authDomain: 'houseofliving-v1-dev.firebaseapp.com',
    projectId: 'houseofliving-v1-dev',
    storageBucket: 'houseofliving-v1-dev.appspot.com',
    messagingSenderId: '186399723492',
    appId: '1:186399723492:web:4d59e2b67ac71500c0c1f1'
  }
}

const config = envsettings

// firebase init goes here
!firebase.apps.length && firebase.initializeApp(config)

// Add analytics to the production unit
if (process.env.VUE_APP_NODE_ENV === 'prod' || process.env.VUE_APP_NODE_ENV === 'prodLive') {
  firebase.analytics()
}

if (process.env.VUE_APP_LOCAL_FUNCTIONS === 'enabled') {
  console.log('IMPORTANT: Running local FUNCTIONS mode')
  // firebase.functions().useFunctionsEmulator('http://192.168.50.97:5001') // Local development only, no network support
  firebase.functions().useFunctionsEmulator('http://10.0.0.254:5001') // Network mode
}

// firebase utils
const storage = firebase.storage()
const db = firebase.firestore()
const auth = firebase.auth()
const func = firebase.functions()
const currentUser = auth.currentUser

// firebase collections
// const usersCollection = db.collection('users')
const productsCollection = db.collection('products')
const projectsCollection = db.collection('projects')
const unitsCollection = db.collection('units')
const filesCollection = db.collection('file')
const prospectsCollection = db.collection('prospects')
const varycodesCollection = db.collection('varycodes')
const snapsCollection = db.collection('snaps')
const prospectChoicesCollection = db.collection('prospectChoices')
const usersCollection = db.collection('users')
const ordersCollection = db.collection('orders')
const requestsCollection = db.collection('requests')

export {
  config,
  firebase,
  storage,
  db,
  auth,
  func,
  currentUser,
  projectsCollection,
  unitsCollection,
  filesCollection,
  prospectsCollection,
  varycodesCollection,
  productsCollection,
  snapsCollection,
  prospectChoicesCollection,
  usersCollection,
  ordersCollection,
  requestsCollection
}

<template>
  <b-form class="margin-bottom-16" @submit.prevent>
    <b-modal id="add-user-modal">
      <template v-slot:modal-header>
        <b-container>
          <b-row>
            <b-col>
              <h3>{{ trans('navigation.add_new_user') }}</h3>
              <p class="description">
                {{ trans('users.add_new_form.add_new_description') }}
              </p>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <template v-slot:default>
        <b-container>
          <b-row>
            <b-col>
              <p style="color: #777777; text-align: center; margin-top: 80px; margin-bottom: 60px;">
                Upcoming ADD-NEW-USER form
              </p>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="light" size="md" class="padding-left-60 padding-right-60" @click="cancel()">
          {{ trans('global.cancel') }}
        </b-button>
        <b-button variant="primary" size="md" class="padding-left-60 padding-right-60" @click="cancel()">
          <span>{{ trans('users.add_new_form.create_user') }}</span>
        </b-button>
      </template>
    </b-modal>
  </b-form>
</template>

<script>
  export default {
    name: 'ModalAddUser',
    methods: {
      hideModal (id) {
        this.$root.$emit('bv::hide::modal', id)
      }
    }
  }
</script>

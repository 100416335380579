import Vue from 'vue'
import { BootstrapVue, VBModal } from 'bootstrap-vue'
import App from './App.vue'
import { createRouter } from './router'
import { store } from './store'

import { truncate } from './filters/truncate'

// Layouts
import DefaultLayout from './components/Layouts/Default'
import ProjectsDefault from './components/Layouts/ProjectsDefault'
import FullLayout from './components/Layouts/Full'

// Styles
import './sass/light.scss'

// Mixins
import Translate from './mixins/lang'
import Sluggify from './mixins/generateslug'
import Breadcrumb from './mixins/breadcrumb'
import Paddy from './mixins/paddy'
import RandomTxt from './mixins/randomtxt'

// Directives
Vue.directive('b-modal', VBModal)

Vue.mixin(Translate)
Vue.mixin(Sluggify)
Vue.mixin(Breadcrumb)
Vue.mixin(Paddy)
Vue.mixin(RandomTxt)

// Filters
Vue.filter('truncate', truncate)

const moment = require('moment')
const fb = require('./firebaseConfig')
const router = createRouter()

// Components
// Layout-components (template-wrappers)
Vue.component('default-layout', DefaultLayout)
Vue.component('projects-default-layout', ProjectsDefault)
Vue.component('full-layout', FullLayout)

// BootstrapVue components (when this list gets too long, import the whole BootstrapVue module
Vue.use(BootstrapVue)

require('moment/locale/nb')
Vue.use(require('vue-moment'), {
  moment
})

Vue.config.productionTip = false

// Handle page reloads
// This makes sure Firebase initializes before loading the app
let app
fb.auth.onAuthStateChanged(() => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')
  }
})

import Vue from 'vue'
import Vuex from 'vuex'

// Stores
import Auth from './auth'
import Projects from './projects'
import Breadcrumb from './breadcrumb'
import Navigation from './navigation'
import Units from './units'
import Configs from './configs'
import Prospects from './prospects'
import Products from './products'
import Dashboard from './dashboard'

const fb = require('../firebaseConfig')

Vue.use(Vuex)

// Check for user on page-load/page-reload to keep a user logged in when refreshing/returning
fb.auth.onAuthStateChanged((user) => {
  if (user) {
    store.commit('auth/setUserProfile', user)
    // store.dispatch('auth/fetchUserProfile') // Upcoming when usersCollection is made

    // Enable live updates for projects - WHERE does NOT work as the snapshot is not updated upon change!
    fb.projectsCollection.where('deleted', '==', false).orderBy('createdOn', 'desc').onSnapshot((projectsQuerySnapshot) => {
      const projectsArray = []

      projectsQuerySnapshot.forEach((doc) => {
        const project = doc.data()
        project._id = doc.id
        projectsArray.push(project)
      })

      store.commit('projects/setProjects', projectsArray)
    })

    // Enable live updates for units
    fb.unitsCollection.orderBy('createdOn', 'desc').onSnapshot((querySnapshot) => {
      const units = []

      querySnapshot.forEach((doc) => {
        const unit = doc.data()

        if (unit.deleted === undefined || unit.deleted !== true) {
          unit._id = doc.id
          units.push(unit)
        }
      })

      store.commit('units/setUnits', { units })
    })

    // Enable live updates for prospects - WHERE does NOT work as the snapshot is not updated upon change!
    fb.prospectsCollection.where('deleted', '==', false).orderBy('createdOn', 'desc').onSnapshot((querySnapshot) => {
      const prospects = []

      querySnapshot.forEach((doc) => {
        const prospect = doc.data()
        prospect._id = doc.id
        prospects.push(prospect)
      })

      store.commit('prospects/setProspects', { prospects })
    })

    // Enable Live updates for VaryCode - fetching only the last one for future increments.
    fb.varycodesCollection.orderBy('createdOn', 'desc').limit(1).onSnapshot((querySnapshot) => {
      let varycode = ''

      // Looping over results even though it's only one result is the correct way of doing it
      querySnapshot.forEach((doc) => {
        varycode = doc.id
      })

      store.commit('configs/setVaryCode', { varycode })
    })

    // Enable live updates for products
    fb.productsCollection.where('deleted', '==', false).orderBy('createdOn', 'desc').onSnapshot((querySnapshot) => {
      const products = []

      querySnapshot.forEach((doc) => {
        const product = doc.data()
        product._id = doc.id
        products.push(product)
      })

      store.commit('products/setProducts', { products })
    })

    // // Update ALL products with deleted = false
    // fb.productsCollection.get().then((res) => {
    //   res.forEach(doc => {
    //     fb.productsCollection.doc(doc.id).set({
    //       deleted: false
    //     }, {merge: true}).then(() => {
    //       console.log('Updated:' + doc.id)
    //     })
    //   })
    // })

    // Update ALL prospects with deleted = false if not already set
    // fb.prospectsCollection.get().then((res) => {
    //   res.forEach((doc) => {
    //     const data = doc.data()

    //     if (data.deleted === undefined) {
    //       fb.prospectsCollection.doc(doc.id).set({
    //         deleted: false
    //       }, { merge: true }).then(() => {
    //         console.log('Updated:' + doc.id)
    //       })
    //     } else {
    //       console.log('Already set: ' + doc.id)
    //     }
    //   })
    // })
  }
})

export const store = new Vuex.Store({
  modules: {
    auth: Auth,
    projects: Projects,
    breadcrumb: Breadcrumb,
    navigation: Navigation,
    units: Units,
    configs: Configs,
    prospects: Prospects,
    products: Products,
    dashboard: Dashboard
  }
})

// Randomtext generator

export default {
  methods: {
    /**
     * randomTxt:
     * creates a random string from the "possible" chars at the specified length
     */
    randomTxt (length = 16) {
      let randomTxt = ''
      const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

      for (let i = 0; i < length; i++) {
        randomTxt += possible.charAt(Math.floor(Math.random() * possible.length))
      }

      return randomTxt
    }
  }
}

const fb = require('../firebaseConfig')

export default {
  namespaced: true,
  state: {
    projects: []
  },
  actions: {
    getProjects ({ commit }) {
      return fb.projectsCollection.orderBy('createdOn', 'desc').get()
      .then((res) => {
        const projectsArray = []

        res.forEach((doc) => {
          const project = doc.data()
          project._id = doc.id

          projectsArray.push(project)
        })

        commit('setProjects', projectsArray)
        return projectsArray
      })
    }
  },
  mutations: {
    setProjects (state, val) {
      state.projects = val
    }
  },
  getters: {
    slugHelper: state => (slug) => {
      return state.projects.filter(projects => ((projects.slug) ? projects.slug.includes(slug) : false)).length
    },
    slugUnitHelper: () => {
      // Check all units belonging to the Project and check the names
      return 1 // state.projects.filter(projects => ((projects.slug) ? projects.slug.includes(slug) : false)).length
    },
    getProject: state => (slug) => {
      return state.projects.find(project => project.slug === slug)
    }
  }
}

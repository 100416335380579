<template>
  <li class="nav-item nav-item-sub">
    <b-link class="nav-link router-link-exact-active router-link-active" target="_self" aria-controls="`mi-${category}`" @click="changeCat">
      <span class="ico-wrp">
        <i :class="ico + ' padding-right-16'"></i>
      </span>
      <span>{{ title }}</span>
      <i :class="'fal fa-chevron-' + direction + ' float-right'" style="margin-top: 3px"></i>
    </b-link>
    <b-collapse :id="`mi-${category}`" v-model="showCollapse">
      <b-nav vertical>
        <slot></slot>
      </b-nav>
    </b-collapse>
  </li>
</template>

<script>
  export default {
    name: 'VNavSubItem',
    props: {
      title: {
        type: String,
        default: 'Menu item'
      },
      ico: {
        type: String,
        default: 'fas fa-tv'
      },
      category: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        // showCollapse: false
      }
    },
    computed: {
      showCollapse: {
        // eslint-disable-next-line object-shorthand
        get: function () {
          return this.$store.state.navigation.activecat === this.category
        },
        // eslint-disable-next-line object-shorthand
        set: function () {
          return this.$store.state.navigation.activecat === this.category
        }
      },
      direction () {
        return this.$store.state.navigation.activecat === this.category ? 'up' : 'down'
      }
    },
    methods: {
      changeCat () {
        this.$store.commit('navigation/setCategory', (this.$store.state.navigation.activecat === this.category) ? '' : this.category)
      }
    }
  }
</script>

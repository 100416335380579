export default {
  namespaced: true,
  state: {
    projectTopbarBtn: {
      activeTab: ''
    },
    projectTopbarMenu: {
      activeTab: ''
    },
    varyCode: ''
  },
  actions: {

  },
  mutations: {
    setProjectTopbarBtn (state, tab) {
      state.projectTopbarBtn.activeTab = tab
    },
    setProjectTopbarMenu (state, tab) {
      state.projectTopbarMenu.activeTab = tab
    },
    setVaryCode (state, id) {
      state.varyCode = id.varycode
    }
  }
}

export default {
  namespaced: true,
  state: {
    breadcrumb: [

    ]
  },
  actions: {

  },
  mutations: {
    setPath (state, breads) {
      state.breadcrumb = breads
    }
  },
  getters: {
    getCrumbs: (state) => {
      return state.breadcrumb
    }
  }
}

module.exports = {
  units_overview: 'Enhetsoversikt',
  units_fase_prepare: 'Førprosjektering',
  units_fase_prepare_description: 'Enheter som er i planleggings fasen.',
  units_fase_sale: 'Salg',
  units_fase_sale_description: 'Prospekter for enhetene i prosjektet',
  units_fase_building: 'Byggefase',
  units_fase_building_description: 'Enheter som er i en byggefase.',
  units_fase_followup: 'Ettermarked',
  units_fase_followup_description: 'Enheter som er solgt og som er under oppfølging',

  unit_type: 'Enhetstype',
  unit_type_desc: 'Velg enhetstype',
  no_units_found: 'Ingen enheter funnet...',
  back_to_project: 'Tilbake til :project',
  to_prospect: 'Gå til prospektet',

  add_unit_loading: 'Laster inn...',
  add_unit_cabin: 'Legg til ny hytte',
  add_unit_cabin_description: 'Legg en ny hytte til prosjektet',
  add_unit_apartment: 'Legg til ny leilighet',
  add_unit_apartment_description: 'Legg en ny leilighet til prosjektet',
  add_unit_house: 'Legg til nytt hus',
  add_unit_house_description: 'Legg et nytt hus til prosjektet',
  add_unit_industry: 'Legg til nytt næringsbygg',
  add_unit_industry_description: 'Legg et nytt næringsbygg til prosjektet',
  add_unit_other: 'Legg til ny enhet',
  add_unit_other_description: 'Legg en ny enhet til prosjektet',
  save_unit_loading: 'Laster inn...',
  save_unit_cabin: 'Opprett hytte',
  save_unit_cabin_description: '',
  save_unit_apartment: 'Opprett leilighet',
  save_unit_apartment_description: '',
  save_unit_house: 'Opprett hus',
  save_unit_house_description: '',
  save_unit_industry: 'Opprett næringsbygg',
  save_unit_industry_description: '',
  save_unit_other: 'Opprett enhet',
  save_unit_other_description: '',

  form_add_new: {
    salestext: 'Salgstekst',
    salestext_placeholder: 'Legg inn salgstext',
    details: 'Detaljer',
    details_placeholder: 'Skriv inn detaljene',
    contactinfo: 'Kontaktinformasjon',
    contactinfo_placeholder: 'Legg til kontaktinformasjon',
    description: 'Beskrivelse',
    description_placeholder: 'Skriv inn en beskrivelse av enheten'
  },

  form_add_new_errors: {
    name: 'Navnet må være lenger enn 4 bokstaver',
    type: 'Du må gi enheten en kategori'
  }
}

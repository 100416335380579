<template>
  <div id="app">
    <component :is="layout"></component>
  </div>
</template>

<script>
  const defaultLayout = 'default'

  export default {
    name: 'App',
    computed: {
      layout () {
        return (this.$route.meta.layout || defaultLayout) + '-layout'
      }
    }
  }
</script>

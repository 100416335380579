<template>
  <b-navbar id="topnavbar-wrapper" type="light">
    <b-breadcrumb v-if="breads" :items="breads"></b-breadcrumb>
    <b-navbar-nav class="ml-auto">
      <b-nav-item-dropdown no-caret right>
        <template slot="button-content">
          {{ userProfile.email }} <i class="fas fa-caret-down"></i>
        </template>
        <b-nav-item @click="logout">
          {{ trans('auth.global.logout') }}
        </b-nav-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
  const fb = require('../../firebaseConfig')

  export default {
    name: 'TopNavbar',
    props: {
      userProfile: {
        type: Object,
        default: null
      }
    },
    computed: {
      breads () {
        return (this.$store.state.breadcrumb.breadcrumb !== undefined) ? this.$store.state.breadcrumb.breadcrumb : false
      }
    },
    methods: {
      logout () {
        fb.auth.signOut().then(() => {
          this.$store.dispatch('auth/clearData')
          this.$router.push('/login')
        }).catch((err) => {
          console.log(err)
        })
      }
    }
  }
</script>

<template>
  <div id="leftsidebar-wrapper">
    <div class="heading padding-top-26 padding-bottom-26">
      <b-img :src="`${publicPath}img/brand/hol_logo.png`" class="d-block mx-auto pb-1 pt-1" alt="House Of Living" />
    </div>
    <b-nav vertical class="margin-top-30">
      <b-nav-item to="/dashboard" @click="resetSubCategory">
        <i class="fal fa-shapes padding-right-16"></i>{{ trans('global.dashboard') }}
      </b-nav-item>
      <vary-nav-sub-item ico="fal fa-home-alt" :title="trans('global.projects')" category="projects">
        <b-nav-item to="/projects">
          {{ trans('navigation.all_projects') }}
        </b-nav-item>
        <b-nav-item v-b-modal.add-project-modal>
          {{ trans('navigation.add_new_project') }}
        </b-nav-item>
      </vary-nav-sub-item>
      <vary-nav-sub-item ico="fal fa-couch" :title="trans('global.products')" category="products">
        <b-nav-item to="/products">
          {{ trans('navigation.all_products') }}
        </b-nav-item>
        <b-nav-item v-b-modal.add-product-modal>
          {{ trans('navigation.add_new_product') }}
        </b-nav-item>
      </vary-nav-sub-item>
      <vary-nav-sub-item ico="fal fa-user" :title="trans('global.users')" category="users">
        <b-nav-item to="/users">
          {{ trans('navigation.all_users') }}
        </b-nav-item>
        <b-nav-item v-b-modal.add-user-modal>
          {{ trans('navigation.add_new_user') }}
        </b-nav-item>
      </vary-nav-sub-item>
      <vary-nav-sub-item ico="fal fa-truck" :title="trans('global.suppliers')" category="suppliers">
        <b-nav-item to="/suppliers">
          {{ trans('navigation.all_suppliers') }}
        </b-nav-item>
        <b-nav-item v-b-modal.add-supplier-modal>
          {{ trans('navigation.add_new_supplier') }}
        </b-nav-item>
      </vary-nav-sub-item>
    </b-nav>
    <ModalAddProject></ModalAddProject>
    <ModalAddProduct></ModalAddProduct>
    <ModalAddUser></ModalAddUser>
    <ModalAddSupplier></ModalAddSupplier>
  </div>
</template>

<script>
  import ModalAddProject from '../Views/Projects/Modals/AddProject'
  import ModalAddProduct from '../Views/Products/Modals/AddProduct'
  import ModalAddUser from '../Views/Users/Modals/AddUser'
  import ModalAddSupplier from '../Views/Suppliers/Modals/AddSupplier'
  import NavItem from './LeftSidebar/NavSubItem'

  export default {
    name: 'LeftSidebar',
    components: {
      'vary-nav-sub-item': NavItem,
      ModalAddProject,
      ModalAddProduct,
      ModalAddUser,
      ModalAddSupplier
    },
    data () {
      return {
        publicPath: process.env.BASE_URL
      }
    },
    methods: {
      resetSubCategory () {
        return this.$store.commit('navigation/setCategory', '')
      }
    }
  }
</script>

module.exports = {
  new_room: 'Nytt rom',
  add_new: 'Legg til nytt rom',
  no_rooms: 'Ingen rom',
  no_rooms_found: 'Ingen rom funnet...',
  rooms_description: 'Rom i denne enheten',
  room_category_desc: 'Velg en kategori for dette rommet',
  save_room: 'Lagre rommet',
  square_meters: 'Størrelse (kvm)',
  square_meters_description: 'Skriv inn størrelsen på rommet i kvadratmeter',

  notifications: {
    no_rooms_found: 'Fant ingen rom tilknyttet denne enheten. Legg til et rom, klikk på knappen oppe til høyre.',
    square_meters_success: 'Størrelsen på :room ble oppdatert'
  },

  form_add_new_errors: {
    category: 'Du må kategorisere romtypen'
  }
}

export default {
  namespaced: true,
  state: {
    activecat: ''
  },
  mutations: {
    setCategory (state, val) {
      state.activecat = val
    }
  }
}

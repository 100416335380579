// Varycode mixin

export default {
  methods: {
    /**
     * Paddy:
     * creates x-numbers of zeroes before the actual ID
     * makes the ID look cleaner.
     * Current total amount of numbers: 10 ( 10 - 1 = 9)
     */
    paddy (num) {
      return ('00000000' + num).substr(-9)
    },
    unPaddy (num) {
      return parseInt(num, 10)
    },
    doPaddy (num) {
      const n = (num !== undefined && num !== '') ? this.unPaddy(num) : 0
      return this.paddy(n + 1)
    }
  }
}

<template>
  <div id="wrapper" class="d-flex">
    <LeftSidebar />
    <div id="content-wrapper">
      <TopNavbar :user-profile="userProfile" />
      <div class="container margin-top-30">
        <router-view :key="$route.path"></router-view>
      </div>
    </div>
    <AppMode v-if="showMode"></AppMode>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import LeftSidebar from '../Navigation/LeftSidebar'
  import TopNavbar from '../Navigation/TopNavbar'
  import AppMode from '../Core/AppMode'

  export default {
    name: 'Default',
    components: {
      LeftSidebar,
      TopNavbar,
      AppMode
    },
    data () {
      return {
        showMode: process.env.VUE_APP_LIVE === undefined
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile
      })
    }
  }
</script>

import Vue from 'vue'
import cloneDeep from 'clone-deep'

const fb = require('../firebaseConfig')

let prospectChoicesUnsubscribe = null
let ordersUnsubscribe = null
let requestsUnsubscribe = null
let prospectChoicesCache = []

export default {
  namespaced: true,
  state: {
    params: {},
    loadingmessage: '',
    prospects: [], // Prospects cache for "deleted" prospects (prospects where deleted = true)
    users: [], // Registered users ONLY (cache)
    projects: [], // List of unique projects found in DB
    activeProjects: [], // List of unique project-names (for sorting)
    orders: [],
    requests: [],
    prospectChoices: [] // Ready prospectChoices including ALL required data
  },
  actions: {
    listenToProspectChoices ({ commit, dispatch }, params) {
      commit('setLoadingMessage', 'Setter opp live snapshot')

      // Avoid having multiple listeners...
      if (typeof prospectChoicesUnsubscribe === 'function') {
        prospectChoicesUnsubscribe()
      }

      // Set up a new prospectChoices listener
      prospectChoicesUnsubscribe = fb.prospectChoicesCollection
        .orderBy('modified', 'desc')
        .onSnapshot((pcSnapshot) => {
          prospectChoicesCache = []

          pcSnapshot.forEach((doc) => {
            const data = doc.data()

            // We only add the data we need from the prospectChoice
            const prospectChoice = {
              _id: doc.id || null,
              created: data.created || null,
              modified: data.modified || null,
              name: data.name || null,
              userId: data.userId || null,
              userIp: data.userIp || null,
              prospectId: data.prospectId || null,
              projectId: data.projectId || null
            }

            prospectChoicesCache.push(prospectChoice)
          })

          dispatch('synchronizeProspectChoicesData')
        })
    },
    unsubscribeFromProspectChoices () {
      prospectChoicesUnsubscribe()
    },
    async synchronizeProspectChoicesData ({ dispatch, commit, state }) {
      const params = state.params || {}
      const limit = params.limit || 5
      const sortByProject = params.project || null
      const sortByUsergroup = params.group || null

      // Cache registered users...
      commit('setLoadingMessage', 'Henter registrerte brukere...')
      if (Object.keys(state.users).length === 0) {
        await fb.usersCollection.get().then((docs) => {
          const users = []

          docs.forEach((doc) => {
            const userdata = doc.data()

            // Only fetch the required data AND only fetch registered users
            if (userdata.profile !== undefined) {
              users.push({
                _id: doc.id,
                profile: userdata.profile
              })
            }
          })

          commit('setUsers', users)
        })
      }

      let count = 0

      let choices = []
      const projects = [] // List of unique project-names (for sorting on projects in UI -> dropdown)
      for (const choice of prospectChoicesCache) {
        commit('setLoadingMessage', 'Henter prospektdesigns [' + count + ' av max ' + limit + ']')
        const userId = choice.userId || null
        const prospectId = choice.prospectId || null
        const projectId = choice.projectId || null
        let includeProspect = true

        // Sort by projects AND usergroups
        if (sortByProject && sortByUsergroup) {
          choice.user = userId ? await dispatch('getInfo', { id: userId, collection: 'users' }) : {}
          includeProspect = sortByProject === projectId && choice.user.profile !== undefined
        } else if (sortByProject) {
          includeProspect = sortByProject === projectId
        } else if (sortByUsergroup) {
          choice.user = userId ? await dispatch('getInfo', { id: userId, collection: 'users' }) : {}
          includeProspect = choice.user.profile !== undefined
        }

        if (includeProspect) {
          if (choice.user === undefined) {
            choice.user = userId ? await dispatch('getInfo', { id: userId, collection: 'users' }) : {}
          }
          choice.prospect = prospectId ? await dispatch('getInfo', { id: prospectId, collection: 'prospects' }) : {}
          choice.project = projectId ? await dispatch('getInfo', { id: projectId, collection: 'projects' }) : {}

          choices.push(choice)

          const saveProject = projects.findIndex(p => p.value === projectId)

          if (saveProject === -1) {
            projects.push({
              value: projectId,
              text: choice.project.name
            })
          }

          count++
        }

        if (count >= limit) {
          break
        }
      }

      // Fetch names of ALL projects (not already registered) that should be in the "Sort by projects" dropdown list
      for (const choice of prospectChoicesCache) {
        const saveProject = projects.findIndex(p => p.value === choice.projectId)

        if (saveProject === -1) {
          const projectData = choice.projectId ? await dispatch('getInfo', {
            id: choice.projectId,
            collection: 'projects'
          }) : {}

          projects.push({
            value: choice.projectId,
            text: projectData.name
          })
        }
      }

      if (choices.length === 0) choices = null

      Vue.set(state, 'activeProjects', projects) // For sorting
      Vue.set(state, 'prospectChoices', choices) // Ready prospectChoices
    },
    async getInfo ({ state, commit, rootState }, { id, collection }) {
      const dataSources = {
        users: {
          collection: 'usersCollection', // If we need to query the firestore
          datasource: [state.users], // Origin of VUEX data
          requiredFields: ['userId', 'profile'] // Required datafields
        },
        prospects: {
          collection: 'prospectsCollection',
          datasource: [rootState.prospects.prospects, state.prospects], // Deleted prospects end up in state.prospects
          requiredFields: ['brand', 'createdOn', 'projectId', 'title', 'unitId', 'varycode']
        },
        projects: {
          collection: 'projectsCollection',
          datasource: [rootState.projects.projects, state.projects], // Deleted projects end up in state.projects
          requiredFields: ['createdOn', 'name', 'userId', 'varycode', 'image']
        }
      }

      // Return data from VUEX if exists
      if (dataSources[collection] !== undefined && dataSources[collection].datasource !== undefined) {
        let result = null
        // Search in both snapshot data AND in cached data in this store (dashboard)
        dataSources[collection].datasource.every((source) => {
          result = source.find(el => el._id === id)
          return result === undefined // Break if found
        })

        if (result !== undefined && result !== null) return result
      }

      // Return empty obj if user is not cached -> unregistered user
      if (collection === 'users') return {}

      // Return data from DB if not found in vuex, and store it in VUEX
      const data = await fb[dataSources[collection].collection].doc(id).get().then((doc) => {
        if (doc.exists) {
          const docData = doc.data()

          const fields = {}
          if (dataSources[collection].requiredFields.length > 0) {
            fields._id = doc.id
            dataSources[collection].requiredFields.forEach((field) => {
              fields[field] = docData[field]
            })
            return fields // Return only required fields
          } else {
            docData._id = doc.id
            return docData // Fallback to return everything!
          }
        } else {
          return {}
        }
      }).catch(() => {
        return {}
      })

      commit('setData', { data, collection }) // If fetched from VUEX, store to vuex (as cache)
      return data
    },
    listenToOrders ({ commit, dispatch }, params) {
      // Avoid having multiple listeners...
      if (typeof ordersUnsubscribe === 'function') {
        ordersUnsubscribe()
      }

      ordersUnsubscribe = fb.ordersCollection
      .orderBy('createdOn', 'desc')
      .limit(5)
      .onSnapshot((orderSnapshot) => {
        const orders = []
        orderSnapshot.forEach((doc) => {
          const orderData = doc.data()
          orderData._id = doc.id
          orders.push(orderData)
        })

        commit('setOrders', orders)
      })
    },
    unsubscribeFromOrders () {
      ordersUnsubscribe()
    },
    listenToRequests ({ commit, dispatch }, params) {
      // Avoid having multiple listeners...
      if (typeof requestsUnsubscribe === 'function') {
        requestsUnsubscribe()
      }

      requestsUnsubscribe = fb.requestsCollection
      .orderBy('createdOn', 'desc')
      .limit(5)
      .onSnapshot((requestsSnapshot) => {
        const requests = []
        requestsSnapshot.forEach((doc) => {
          const requestData = doc.data()
          requestData._id = doc.id
          requests.push(requestData)
        })

        commit('setRequests', requests)
      })
    },
    unsubscribeFromRequests () {
      requestsUnsubscribe()
    }
  },
  mutations: {
    setData (state, { data, collection }) {
      state[collection].push(data)
    },
    setProjects (state, projects) {
      Vue.set(state, 'activeProjects', projects)
    },
    setUsers (state, users) {
      Vue.set(state, 'users', users)
    },
    setOrders (state, orders) {
      Vue.set(state, 'orders', orders)
    },
    setRequests (state, requests) {
      Vue.set(state, 'requests', requests)
    },
    setParams (state, data) {
      Vue.set(state, 'params', data)
    },
    setLoadingMessage (state, text) {
      state.loadingmessage = text
    }
  },
  getters: {
    getProspectChoices: (state) => {
      return cloneDeep(state.prospectChoices)
    },
    getActiveProjects: (state) => {
      return cloneDeep(state.activeProjects)
    },
    getLoadingMessage: (state) => {
      return cloneDeep(state.loadingmessage)
    },
    getOrders: (state) => {
      return cloneDeep(state.orders)
    },
    getRequests: (state) => {
      return cloneDeep(state.requests)
    }
  }
}

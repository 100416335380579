const fb = require('../firebaseConfig')

export default {
  namespaced: true,
  state: {
    units: []
  },
  actions: {
    getUnits ({ commit }) {
      return fb.unitsCollection.orderBy('createdOn', 'desc').get()
      .then((res) => {
        const units = []

        res.forEach((doc) => {
          const unit = doc.data()
          unit._id = doc.id
          units.push(unit)
        })

        commit('setUnits', { units })
      })
    },
    getProjectUnits () {
      console.log('getProjectUnits')
    }
  },
  mutations: {
    setUnits (state, val) {
      state.units = val.units
    }
  },
  getters: {
    slugHelper: state => (projectId, slug) => {
      return state.units.filter((unit) => {
        const thisSlug = unit.slug ? unit.slug.includes(slug) : false
        const thisProjectId = unit.projectId ? unit.projectId === projectId : false

        return (thisSlug && thisProjectId)
      }).length
    }
  }
}

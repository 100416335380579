const cloneDeep = require('clone-deep')
const fb = require('../firebaseConfig')
// const deepMerge = require('deepmerge')

export default {
  namespaced: true,
  state: {
    currentUser: null,
    userProfile: {}
  },
  mutations: {
    setUserProfile (state, val) {
      state.userProfile = val
    }
  },
  actions: {
    clearData ({ commit }) {
      // commit ('setCurrentUser', null) // Enable this when we have a seperate user-table
      commit('setUserProfile', {})
    },

    // eslint-disable-next-line
    fetchUserProfile ({ commit, state}) {
      if (fb.auth.currentUser) {
        commit('setUserProfile', fb.auth.currentUser)
      } else {
        console.log('No user found')
      }

      // TODO: Upcoming usersCollection user data fetching
      // fb.usersCollection.doc(state.currentUser.uid).get().then(res => {
      //   console.log('FetchUserProfile:')
      //   console.log(res)
      //   commit ('setUserProfile', res.data())
      // }).catch(err => {
      //   console.log(err)
      // })
    }
  },
  getters: {
    get (state) {
      if (Object.keys(state.userProfile).length > 0) {
        const requiredData = {
          uid: state.userProfile.uid !== undefined ? state.userProfile.uid : null,
          profile: cloneDeep(state.userProfile)
        }

        return requiredData
      } else {
        return null
      }
    }
  }
}

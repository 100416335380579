// Vary breadcrumb mixin
import { upperFirst } from 'bootstrap-vue/esm/utils/string'

export default {
  data () {
    return {
      breadcrumbs: []
    }
  },
  methods: {
    /**
     * Fetches breadcrumbs from params or from the Vuex store
     * REQUIREMENTS:
     * Needs a getItems (Ex: getProjects, getFiles) action in the Vuex store
     */
    updateBreadcrumb (breadcrumbs) {
      const stores = []

      breadcrumbs.forEach((bc) => {
        // Fetch the stores we need data from
        if (typeof bc.text === 'object') {
          if (bc.text.store !== undefined) {
            stores.push(bc.text.store)
          } else {
            console.error('BreadCrumb Mixin: Error! No store defined!')
          }
        }
      })

      // Check if the stores are loaded
      return Promise.all(
        stores.map((storename) => {
          if (this.$store.state[storename] !== undefined && this.$store.state[storename][storename].length > 0) {
            return Promise.resolve()
          } else {
            const storeAction = storename + '/get' + upperFirst(storename)
            return this.$store.dispatch(storeAction).catch((err) => {
              console.error('Error while reading data from the store: ' + storename)
              console.error(err)
            })
          }
        })
      ).then(() => {
        let parentId = ''
        const breads = breadcrumbs.map((val) => {
          let text = 'Undefined'

          // If we have a parent, we need it to fetch the child data since children are stored in
          // objects with parent-id as keys
          if (parentId !== '' && val.childmode !== undefined && val.childmode === true && val.parentidkey !== undefined && val.text !== undefined && typeof (val.text) === 'object' && val.text.store !== undefined && val.text.searchin !== undefined && val.text.searchfor !== undefined) {
            const obj = this.$store.state[val.text.store][val.text.store].find(item => item[val.text.searchin] === val.text.searchfor && item[val.parentidkey] === parentId)
            text = obj.name !== undefined ? obj.name : obj.title
            parentId = obj._id
          } else if (val.text !== undefined && typeof (val.text) === 'object' && val.text.store !== undefined && val.text.searchin !== undefined && val.text.searchfor !== undefined) {
            // Then we have a object in a store...
            const obj = this.$store.state[val.text.store][val.text.store].find(item => item[val.text.searchin] === val.text.searchfor)
            text = obj.name !== undefined ? obj.name : obj.title
            parentId = obj._id
          } else {
            text = val.text !== undefined ? val.text : 'No title set (ERROR)'
          }

          const url = val.to !== undefined ? val.to : '#'
          return { text, to: url }
        })

        // this.$store.commit('breadcrumb/setPath', [])
        this.$store.commit('breadcrumb/setPath', breads)
      })
    }
  }
}

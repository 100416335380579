<template>
  <div>
    <b-dropdown id="appmode" size="sm" variant="success">
      <template v-slot:button-content>
        <i :class="modemessage.icon"></i><span>{{ modemessage.text }}</span>
      </template>
      <b-dropdown-item v-for="(link, index) in envLinks" :key="'environment-'+ index" :href="envLinks[index]">
        {{ index }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
  export default {
    name: 'AppMode',
    data () {
      return {
        mode: process.env.VUE_APP_NODE_ENV !== undefined ? process.env.VUE_APP_NODE_ENV : 'dev'
      }
    },
    computed: {
      modemessage () {
        const message = {}
        if (this.mode === 'prod') {
          message.text = 'Production'
          message.cls = 'bg-danger'
          message.icon = 'far fa-exclamation-triangle'
        } else {
          message.text = 'Development'
          message.cls = 'bg-success'
          message.icon = 'far fa-thumbs-up'
        }

        return message
      },
      envLinks () {
        const envs = {
          Production: 'https://hol-admin.vary.no',
          Development: 'https://hol-dev-admin.vary.no'
        }

        const links = {}
        Object.keys(envs).filter((env) => {
          if (env !== this.modemessage.text) {
            links[env] = envs[env]
          }
        })

        return links
      }
    }
  }
</script>

<style lang="scss" scoped>
  #appmode {
    position: fixed;
    top: 0;
    right: 0;
    font-size: 12px;
    border-radius: 0 0 0 4px;

    /deep/ button {
      &.btn-sm {
        border-radius: 0 0 0 4px !important;
      }

      i {
        color: white;
      }
      span {
        color: white;
      }
    }

    span {
      margin-left: 6px;
    }

    &.text {
      padding: 4px 10px;
    }
  }
</style>

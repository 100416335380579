import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase/app'
import 'firebase/auth'

// Views - lazyloaded
const Login = () => import('@/views/Login')
const Dashboard = () => import('@/views/Dashboard')

const Projects = () => import('@/views/Projects/AllProjects')
const ProjectIndex = () => import('@/views/Projects/Project/Index')

const ProjectPlannerIndex = () => import('@/views/Projects/Planner/Index')
const ProjectPlannerUnitIndex = () => import('@/views/Projects/Planner/Unit/Index')

const ProjectSalesIndex = () => import('@/views/Projects/Sales/Index')
const ProjectSalesProspectIndex = () => import('@/views/Projects/Sales/Prospect/Index')

const Products = () => import('@/views/Products/AllProducts')
const ProductsEdit = () => import('@/views/Products/Edit')

const Users = () => import('@/views/Users/AllUsers')

const Suppliers = () => import('@/views/Suppliers/AllSuppliers')

Vue.use(Router)

export function createRouter () {
  const router = new Router({
    mode: 'history',
    fallback: false,
    scrollBehavior: () => ({ y: 0 }),
    routes: [
      {
        path: '*',
        redirect: '/dashboard'
      },
      {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
          layout: 'full',
          requiresAuth: false
        }
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {
          requiresAuth: true
        }
      },
      // Projects
      {
        path: '/projects',
        name: 'Projects',
        component: Projects,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/projects/:projectslug',
        name: 'ProjectPage',
        component: ProjectIndex,
        meta: {
          layout: 'projects-default',
          requiresAuth: true
        }
      },
      {
        path: '/projects/:projectslug/planner',
        name: 'ProjectPlanner',
        component: ProjectPlannerIndex,
        meta: {
          layout: 'projects-default',
          requiresAuth: true
        }
      },
      {
        path: '/projects/:projectslug/planner/:unitslug',
        name: 'UnitPage',
        component: ProjectPlannerUnitIndex,
        meta: {
          layout: 'projects-default',
          requiresAuth: true
        }
      },
      {
        path: '/projects/:projectslug/sales',
        name: 'SalesPage',
        component: ProjectSalesIndex,
        meta: {
          layout: 'projects-default',
          requiresAuth: true
        }
      },
      {
        path: '/projects/:projectslug/sales/:prospectslug',
        name: 'ProspectPage',
        component: ProjectSalesProspectIndex,
        meta: {
          layout: 'projects-default',
          requiresAuth: true
        }
      },
      // Products
      {
        path: '/products',
        name: 'Products',
        component: Products,
        meta: {
          requiresAuth: true
        }
      },
      // Products - EDIT
      {
        path: '/products/edit/:productvid',
        name: 'ProductsEdit',
        component: ProductsEdit,
        meta: {
          requiresAuth: true
        }
      },
      // Users
      {
        path: '/users',
        name: 'Users',
        component: Users,
        meta: {
          requiresAuth: true
        }
      },
      // Suppliers
      {
        path: '/suppliers',
        name: 'Suppliers',
        component: Suppliers,
        meta: {
          requiresAuth: true
        }
      }
    ]
  })

  router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
    const currentUser = firebase.auth().currentUser

    if (requiresAuth && !currentUser) {
      next('/login')
    } else if (!requiresAuth && currentUser) {
      next('/dashboard')
    } else {
      next()
    }
  })

  return router
}

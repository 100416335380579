<template>
  <div id="wrapper" class="d-flex">
    <LeftSidebar />
    <router-view :key="$route.path"></router-view>
    <AppMode v-if="showMode"></AppMode>
  </div>
</template>

<script>
  import LeftSidebar from '../Navigation/LeftSidebar'
  import AppMode from '../Core/AppMode'

  export default {
    name: 'ProjectsDefault',
    components: {
      LeftSidebar,
      AppMode
    },
    data () {
      return {
        showMode: process.env.VUE_APP_LIVE === undefined
      }
    }
  }
</script>

// Vary language mixin

export default {
  methods: {
    /**
     * createSlug():
     * #1: usage: slug = this.createSlug(string_to_convert_to_slug, category)
     * #1: example: slug = this.createSlug(this.projectName, 'projects/slugHelper')
     * #2: usage: slug = this.createSlug(string_to_convert_to_slug, category, projectId)
     * #2: example: slug = this.createSlug(this.projectName, 'projects/slugHelper', projectId)
     *
     * @param slug = the title you want to sluggify
     * @param category = the getter funtion in the store related to the model
     * @returns {string}
     */
    createSlug (slug, category, pid) {
      let cleanSlug = ''
      if (category !== undefined && slug !== undefined) {
        const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·_,:;!"#%'
        const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz---------'
        const p = new RegExp(a.split('').join('|'), 'g')

        // Generate a slug based on the title
        cleanSlug = slug.toString().toLowerCase() // all chars to lowercase
          .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
          .replace(/\s+/g, '-') // Replace spaces with -
          .replace(/\?/g, '-questionmark-') // Replace & with 'and'
          .replace(/\//ig, '-slash-') // Replace & with 'and'
          .replace(/&/g, '-and-') // Replace & with 'and'
          .replace(/-+/g, '-') // Replace multiple - with single -
          .replace(/^-+/, '') // Trim - from start of text
          .replace(/-+$/, '') // Trim - from end of text

        const numberOfSimilarSlugs = (pid !== undefined) ? this.$store.getters[category](pid, cleanSlug) : this.$store.getters[category](cleanSlug)

        console.log('NumberOfSimilarSlugs: ' + numberOfSimilarSlugs)

        cleanSlug = numberOfSimilarSlugs > 0 ? cleanSlug + '-' + numberOfSimilarSlugs : cleanSlug
      } else {
        // Generate a random slug
        const length = 16
        const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

        for (let i = 0; i < length; i++) {
          cleanSlug += possible.charAt(Math.floor(Math.random() * possible.length))
        }
      }

      return cleanSlug
    }
  }
}

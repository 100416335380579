module.exports = {
  global: {
    logout: 'Logg ut'
  },
  login: {
    login_admin: 'Velkommen til House Of Living admin',
    login_email: 'Eller logg inn med epost',
    email: 'E-post',
    email_placeholder: 'din@epost.no',
    password: 'Passord',
    password_placeholder: '******',
    remember_me: 'Husk meg',
    forgot_password: 'Glemt passordet?',
    btn_cta: 'Logg inn',
    btn_loading: 'Sjekker data...',
    msg_email_required: 'Du må skrive inn en epostaddresse',
    msg_email_email: 'Du må skrive inn en epostaddresse',
    msg_password_required: 'Du må skrive inn ett passord',
    msg_password_minlength: 'Passordet må være mer enn 5 tegn',
    msg_email_password_notfound: 'Feil passord eller epostaddresse',
    msg_email_password_wrongpassword: 'Feil passord eller epostaddresse',
    msg_email_passsword_too_many_requests: 'For mange feil innlogginger, prøv igjen senere'
  }
}

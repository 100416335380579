<template>
  <b-form class="margin-bottom-16" @submit.prevent>
    <b-modal id="add-project-modal">
      <template v-slot:modal-header>
        <b-container>
          <b-row>
            <b-col>
              <h3>{{ trans('navigation.add_new_project') }}</h3>
              <p class="description">
                {{ trans('projects.information_description') }}
              </p>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <template v-slot:default>
        <b-container>
          <b-row>
            <b-col>
              <b-form-group id="project_name_group" :label="trans('global.name')" label-for="project_name">
                <b-form-input
                  id="project_name"
                  v-model="$v.projectForm.name.$model"
                  name="project_name"
                  class="form-control-lg"
                  :state="formErrors.name"
                  type="text"
                  :placeholder="trans('projects.name_placeholder')"
                  aria-describedby="name-msg"
                  trim
                  @keyup.13="createProject"
                ></b-form-input>
                <b-form-invalid-feedback id="name-msg">
                  <span v-if="!$v.projectForm.name.required">{{ trans('projects.form_errors.name') }}</span>
                  <span v-if="!$v.projectForm.name.minlength">{{ trans('projects.form_errors.name') }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="project_description_group" :label="trans('projects.description')" label-for="project_description">
                <b-form-textarea
                  id="project_description"
                  v-model="projectForm.description"
                  name="project_description"
                  class="form-control-lg"
                  :placeholder="trans('projects.description_placeholder')"
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
              <b-form-group id="project_type_group" :label="trans('projects.project_type')" label-for="project_type">
                <b-form-select
                  id="project_type"
                  v-model="$v.projectForm.type.$model"
                  name="project_type"
                  size="lg"
                  class="form-control-lg"
                  :state="formErrors.type"
                  aria-describedby="type-msg"
                >
                  <option :value="null">
                    {{ trans('projects.project_type_desc') }}
                  </option>
                  <option v-for="(type, index) in projectTypes" :key="index" :value="index">
                    {{ trans('global.' + type ) }}
                  </option>
                </b-form-select>
                <b-form-invalid-feedback id="type-msg">
                  <span v-if="!$v.projectForm.type.required">{{ trans('projects.form_errors.type') }}</span>
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
        </b-container>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <b-button variant="light" size="md" class="padding-left-60 padding-right-60" @click="cancel()">
          {{ trans('global.cancel') }}
        </b-button>
        <b-button variant="primary" size="md" class="padding-left-60 padding-right-60" @click="createProject">
          <span v-if="!loading">{{ trans('projects.add_project_button') }}</span>
          <b-spinner v-else variant="white"></b-spinner>
        </b-button>
      </template>
    </b-modal>
  </b-form>
</template>

<script>
  import { mapState } from 'vuex'
  import { validationMixin } from 'vuelidate'
  import { required, minLength } from 'vuelidate/lib/validators'

  const fb = require('../../../../firebaseConfig')
  const varyConfig = require('../../../../varyConfig')

  export default {
    name: 'ModalAddProject',
    mixins: [validationMixin],
    data () {
      return {
        loading: false,
        projectForm: {
          name: '',
          description: '',
          type: null
        }
      }
    },
    validations () {
      return {
        projectForm: {
          name: {
            required,
            minlength: minLength(4)
          },
          type: {
            required
          }
        }
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile
      }),
      formErrors () {
        return {
          name: (this.$v.projectForm.name.$dirty && this.submitted) ? !this.$v.projectForm.name.$error : null,
          type: (this.$v.projectForm.type.$dirty && this.submitted) ? !this.$v.projectForm.type.$error : null
        }
      },
      projectTypes () {
        console.log(varyConfig.projectTypes)
        return varyConfig.projectTypes
      }
    },
    methods: {
      createProject () {
        this.loading = true
        this.submitted = true

        this.$v.projectForm.$touch()

        if (this.$v.projectForm.$anyError) {
          this.loading = false
          return
        }

        const slug = this.createSlug(this.projectForm.name, 'projects/slugHelper')

        const varycode = this.doPaddy(this.$store.state.configs.varyCode)

        fb.varycodesCollection.doc(varycode).set({
          createdOn: new Date(),
          varycode,
          userId: this.userProfile.uid,
          type: 1 // 1 = project, 2 = unit, 3 = prospect, 4 = product
        }).then(() => {
          fb.projectsCollection.add({
            createdOn: new Date(),
            varycode,
            name: this.projectForm.name,
            slug,
            description: this.projectForm.description,
            userId: this.userProfile.uid,
            type: this.projectForm.type,
            deleted: false
          }).then((res) => {
            if (res.id !== undefined && res.id !== '') {
              fb.varycodesCollection.doc(varycode).set({
                typeId: res.id
              }, { merge: true })
            }
            this.projectForm.name = ''
            this.projectForm.description = ''
            this.projectForm.type = null
            this.submitted = false
            this.loading = false
            this.hideModal('add-project-modal')
            this.$router.push({ name: 'ProjectPage', params: { projectslug: slug } })
          }).catch((err) => {
            console.log('Could not save project:')
            console.log(err)
          })
        })
      },
      hideModal (id) {
        this.$root.$emit('bv::hide::modal', id)
      }
    }
  }
</script>

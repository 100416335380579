/*
  Room-types (hardcoded for now):
  1. Kjøkken
  2. Kjøkken/Stue
  3. Stue
  4. Soverom
  5. Bad
  6. WC
  7. Vaskerom
  8. WC/Vaskerom
  9. Exteriør
  10. Tomt
  11. Hall
  12: Hems

  // REMOVED:
  // 12: 'house',
  // 13: 'residential',
 */
const roomCategories = {
  1: 'kitchen',
  2: 'kitchen_livingroom',
  3: 'livingroom',
  4: 'bedroom',
  5: 'bath',
  6: 'wc',
  7: 'laundryroom',
  8: 'wc_laundryroom',
  9: 'exterior',
  10: 'plot',
  11: 'hall',
  12: 'loft'
}

/*
  Project-types (hardcoded for now):
  1. Hytter
  2. Leiligheter
  3. Hus
  4. Næring
  5. Annet
*/
const projectTypes = {
  1: 'cabins',
  2: 'apartments',
  3: 'house',
  4: 'industry',
  5: 'other'
}

/*
  Ovnership-types (hardcoded for now):
  1. Eier
  2. Andel
  3. Aksje
  4. Obligasjon
  5. Annen
*/
const ownershipTypes = {
  1: 'owner',
  2: 'share',
  3: 'stock',
  4: 'bond',
  5: 'other'
}

const energyTypes = {
  1: 'A',
  2: 'B',
  3: 'C',
  4: 'D',
  5: 'E',
  6: 'F',
  7: 'G'
}

const postalCodes = [
  { id: 1, postal: '0001', name: 'OSLO' },
  { id: 2, postal: '0009', name: 'OSLO' },
  { id: 3, postal: '0010', name: 'OSLO' },
  { id: 4, postal: '0014', name: 'OSLO' },
  { id: 5, postal: '0015', name: 'OSLO' },
  { id: 6, postal: '0016', name: 'OSLO' },
  { id: 7, postal: '0017', name: 'OSLO' },
  { id: 8, postal: '0018', name: 'OSLO' },
  { id: 9, postal: '0019', name: 'OSLO' },
  { id: 10, postal: '0020', name: 'OSLO' },
  { id: 11, postal: '0021', name: 'OSLO' },
  { id: 12, postal: '0022', name: 'OSLO' },
  { id: 13, postal: '0023', name: 'OSLO' },
  { id: 14, postal: '0024', name: 'OSLO' },
  { id: 15, postal: '0025', name: 'OSLO' },
  { id: 16, postal: '0026', name: 'OSLO' },
  { id: 17, postal: '0027', name: 'OSLO' },
  { id: 18, postal: '0035', name: 'OSLO' },
  { id: 19, postal: '0037', name: 'OSLO' },
  { id: 20, postal: '0040', name: 'OSLO' },
  { id: 21, postal: '0041', name: 'OSLO' },
  { id: 22, postal: '0042', name: 'OSLO' },
  { id: 23, postal: '0043', name: 'OSLO' },
  { id: 24, postal: '0045', name: 'OSLO' },
  { id: 25, postal: '0046', name: 'OSLO' },
  { id: 26, postal: '0047', name: 'OSLO' },
  { id: 27, postal: '0048', name: 'OSLO' },
  { id: 28, postal: '0051', name: 'OSLO' },
  { id: 29, postal: '0055', name: 'OSLO' },
  { id: 30, postal: '0056', name: 'OSLO' },
  { id: 31, postal: '0060', name: 'OSLO' },
  { id: 32, postal: '0061', name: 'OSLO' },
  { id: 33, postal: '0062', name: 'OSLO' },
  { id: 34, postal: '0063', name: 'OSLO' },
  { id: 35, postal: '0064', name: 'OSLO' },
  { id: 36, postal: '0065', name: 'OSLO' },
  { id: 37, postal: '0066', name: 'OSLO' },
  { id: 38, postal: '0067', name: 'OSLO' },
  { id: 39, postal: '0068', name: 'OSLO' },
  { id: 40, postal: '0069', name: 'OSLO' },
  { id: 41, postal: '0070', name: 'OSLO' },
  { id: 42, postal: '0071', name: 'OSLO' },
  { id: 43, postal: '0072', name: 'OSLO' },
  { id: 44, postal: '0073', name: 'OSLO' },
  { id: 45, postal: '0074', name: 'OSLO' },
  { id: 46, postal: '0075', name: 'OSLO' },
  { id: 47, postal: '0076', name: 'OSLO' },
  { id: 48, postal: '0077', name: 'OSLO' },
  { id: 49, postal: '0078', name: 'OSLO' },
  { id: 50, postal: '0079', name: 'OSLO' },
  { id: 51, postal: '0080', name: 'OSLO' },
  { id: 52, postal: '0081', name: 'OSLO' },
  { id: 53, postal: '0085', name: 'OSLO' },
  { id: 54, postal: '0108', name: 'OSLO' },
  { id: 55, postal: '0109', name: 'OSLO' },
  { id: 56, postal: '0126', name: 'OSLO' },
  { id: 57, postal: '0127', name: 'OSLO' },
  { id: 58, postal: '0128', name: 'OSLO' },
  { id: 59, postal: '0132', name: 'OSLO' },
  { id: 60, postal: '0136', name: 'OSLO' },
  { id: 61, postal: '0137', name: 'OSLO' },
  { id: 62, postal: '0140', name: 'OSLO' },
  { id: 63, postal: '0194', name: 'OSLO' },
  { id: 64, postal: '0195', name: 'OSLO' },
  { id: 65, postal: '0217', name: 'OSLO' },
  { id: 66, postal: '0218', name: 'OSLO' },
  { id: 67, postal: '0240', name: 'OSLO' },
  { id: 68, postal: '0241', name: 'OSLO' },
  { id: 69, postal: '0242', name: 'OSLO' },
  { id: 70, postal: '0243', name: 'OSLO' },
  { id: 71, postal: '0244', name: 'OSLO' },
  { id: 72, postal: '0245', name: 'OSLO' },
  { id: 73, postal: '0246', name: 'OSLO' },
  { id: 74, postal: '0247', name: 'OSLO' },
  { id: 75, postal: '0252', name: 'OSLO' },
  { id: 76, postal: '0279', name: 'OSLO' },
  { id: 77, postal: '0310', name: 'OSLO' },
  { id: 78, postal: '0312', name: 'OSLO' },
  { id: 79, postal: '0313', name: 'OSLO' },
  { id: 80, postal: '0320', name: 'OSLO' },
  { id: 81, postal: '0340', name: 'OSLO' },
  { id: 82, postal: '0341', name: 'OSLO' },
  { id: 83, postal: '0342', name: 'OSLO' },
  { id: 84, postal: '0349', name: 'OSLO' },
  { id: 85, postal: '0407', name: 'OSLO' },
  { id: 86, postal: '0408', name: 'OSLO' },
  { id: 87, postal: '0410', name: 'OSLO' },
  { id: 88, postal: '0415', name: 'OSLO' },
  { id: 89, postal: '0424', name: 'OSLO' },
  { id: 90, postal: '0425', name: 'OSLO' },
  { id: 91, postal: '0426', name: 'OSLO' },
  { id: 92, postal: '0440', name: 'OSLO' },
  { id: 93, postal: '0441', name: 'OSLO' },
  { id: 94, postal: '0442', name: 'OSLO' },
  { id: 95, postal: '0444', name: 'OSLO' },
  { id: 96, postal: '0445', name: 'OSLO' },
  { id: 97, postal: '0507', name: 'OSLO' },
  { id: 98, postal: '0514', name: 'OSLO' },
  { id: 99, postal: '0530', name: 'OSLO' },
  { id: 100, postal: '0531', name: 'OSLO' },
  { id: 101, postal: '0532', name: 'OSLO' },
  { id: 102, postal: '0540', name: 'OSLO' },
  { id: 103, postal: '0610', name: 'OSLO' },
  { id: 104, postal: '0615', name: 'OSLO' },
  { id: 105, postal: '0626', name: 'OSLO' },
  { id: 106, postal: '0630', name: 'OSLO' },
  { id: 107, postal: '0640', name: 'OSLO' },
  { id: 108, postal: '0645', name: 'OSLO' },
  { id: 109, postal: '0703', name: 'OSLO' },
  { id: 110, postal: '0759', name: 'OSLO' },
  { id: 111, postal: '0840', name: 'OSLO' },
  { id: 112, postal: '0908', name: 'OSLO' },
  { id: 113, postal: '0914', name: 'OSLO' },
  { id: 114, postal: '1102', name: 'OSLO' },
  { id: 115, postal: '1107', name: 'OSLO' },
  { id: 116, postal: '1108', name: 'OSLO' },
  { id: 117, postal: '1202', name: 'OSLO' },
  { id: 118, postal: '1206', name: 'OSLO' },
  { id: 119, postal: '1212', name: 'OSLO' },
  { id: 120, postal: '1277', name: 'OSLO' },
  { id: 121, postal: '1304', name: 'SANDVIKA' },
  { id: 122, postal: '1306', name: 'BÆRUM POSTTERMINAL' },
  { id: 123, postal: '1307', name: 'FORNEBU' },
  { id: 124, postal: '1308', name: 'JAR' },
  { id: 125, postal: '1311', name: 'HØVIKODDEN' },
  { id: 126, postal: '1311', name: 'KUNSTSENTRET HØVIKODDEN' },
  { id: 127, postal: '1328', name: 'HØVIK' },
  { id: 128, postal: '1329', name: 'LOMMEDALEN' },
  { id: 129, postal: '1331', name: 'FORNEBU' },
  { id: 130, postal: '1342', name: 'GJETTUM' },
  { id: 131, postal: '1350', name: 'LOMMEDALEN' },
  { id: 132, postal: '1360', name: 'FORNEBU' },
  { id: 133, postal: '1364', name: 'FORNEBU' },
  { id: 134, postal: '1404', name: 'SIGGERUD' },
  { id: 135, postal: '1407', name: 'VINTERBRO' },
  { id: 136, postal: '1413', name: 'TÅRNÅSEN' },
  { id: 137, postal: '1418', name: 'KOLBOTN' },
  { id: 138, postal: '1422', name: 'SIGGERUD' },
  { id: 139, postal: '1423', name: 'SKI' },
  { id: 140, postal: '1424', name: 'SKI' },
  { id: 141, postal: '1425', name: 'SKI' },
  { id: 142, postal: '1429', name: 'VINTERBRO' },
  { id: 143, postal: '1432', name: 'ÅS' },
  { id: 144, postal: '1433', name: 'ÅS' },
  { id: 145, postal: '1434', name: 'ÅS' },
  { id: 146, postal: '1435', name: 'ÅS' },
  { id: 147, postal: '1443', name: 'OSCARSBORG' },
  { id: 148, postal: '1444', name: 'DRØBAK' },
  { id: 149, postal: '1444', name: 'SKIPHELLE' },
  { id: 150, postal: '1445', name: 'HEER' },
  { id: 151, postal: '1446', name: 'DRØBAK' },
  { id: 152, postal: '1447', name: 'DRØBAK' },
  { id: 153, postal: '1448', name: 'DRØBAK' },
  { id: 154, postal: '1449', name: 'DRØBAK' },
  { id: 155, postal: '1452', name: 'NESODDTANGEN' },
  { id: 156, postal: '1454', name: 'FAGERSTRAND' },
  { id: 157, postal: '1456', name: 'NESODDTANGEN' },
  { id: 158, postal: '1457', name: 'FAGERSTRAND' },
  { id: 159, postal: '1459', name: 'NESODDEN' },
  { id: 160, postal: '1459', name: 'FJELLSTRAND' },
  { id: 161, postal: '1461', name: 'LØRENSKOG' },
  { id: 162, postal: '1462', name: 'FJELLHAMAR' },
  { id: 163, postal: '1463', name: 'FJELLHAMAR' },
  { id: 164, postal: '1464', name: 'FJELLHAMAR' },
  { id: 165, postal: '1465', name: 'STRØMMEN' },
  { id: 166, postal: '1466', name: 'STRØMMEN' },
  { id: 167, postal: '1467', name: 'STRØMMEN' },
  { id: 168, postal: '1468', name: 'FINSTADJORDET' },
  { id: 169, postal: '1469', name: 'RASTA' },
  { id: 170, postal: '1472', name: 'FJELLHAMAR' },
  { id: 171, postal: '1474', name: 'NORDBYHAGEN' },
  { id: 172, postal: '1475', name: 'FINSTADJORDET' },
  { id: 173, postal: '1476', name: 'RASTA' },
  { id: 174, postal: '1477', name: 'FJELLHAMAR' },
  { id: 175, postal: '1478', name: 'LØRENSKOG' },
  { id: 176, postal: '1479', name: 'KURLAND' },
  { id: 177, postal: '1482', name: 'NITTEDAL' },
  { id: 178, postal: '1483', name: 'SKYTTA' },
  { id: 179, postal: '1484', name: 'HAKADAL' },
  { id: 180, postal: '1484', name: 'ÅNEBY' },
  { id: 181, postal: '1485', name: 'HAKADAL' },
  { id: 182, postal: '1486', name: 'NITTEDAL' },
  { id: 183, postal: '1487', name: 'HAKADAL' },
  { id: 184, postal: '1487', name: 'TØYENHAUGEN' },
  { id: 185, postal: '1504', name: 'MOSS' },
  { id: 186, postal: '1505', name: 'MOSS' },
  { id: 187, postal: '1508', name: 'MOSS' },
  { id: 188, postal: '1542', name: 'VESTBY' },
  { id: 189, postal: '1543', name: 'VESTBY' },
  { id: 190, postal: '1544', name: 'VESTBY' },
  { id: 191, postal: '1550', name: 'HØLEN' },
  { id: 192, postal: '1560', name: 'LARKOLLEN' },
  { id: 193, postal: '1561', name: 'LARKOLLEN' },
  { id: 194, postal: '1583', name: 'RYGGE' },
  { id: 195, postal: '1590', name: 'RYGGE FLYSTASJON' },
  { id: 196, postal: '1590', name: 'RYGGE' },
  { id: 197, postal: '1592', name: 'VÅLER I ØSTFOLD' },
  { id: 198, postal: '1593', name: 'SVINNDAL' },
  { id: 199, postal: '1594', name: 'VÅLER I ØSTFOLD' },
  { id: 200, postal: '1602', name: 'FREDRIKSTAD' },
  { id: 201, postal: '1603', name: 'FREDRIKSTAD' },
  { id: 202, postal: '1609', name: 'FREDRIKSTAD' },
  { id: 203, postal: '1621', name: 'GRESSVIK' },
  { id: 204, postal: '1622', name: 'GRESSVIK' },
  { id: 205, postal: '1623', name: 'GRESSVIK' },
  { id: 206, postal: '1628', name: 'ENGALSVIK' },
  { id: 207, postal: '1629', name: 'GAMLE FREDRIKSTAD' },
  { id: 208, postal: '1631', name: 'GAMLE FREDRIKSTAD' },
  { id: 209, postal: '1638', name: 'GAMLE FREDRIKSTAD' },
  { id: 210, postal: '1642', name: 'SALTNES' },
  { id: 211, postal: '1643', name: 'RÅDE' },
  { id: 212, postal: '1680', name: 'SKJÆRHALLEN' },
  { id: 213, postal: '1682', name: 'SKJÆRHALLEN' },
  { id: 214, postal: '1683', name: 'VESTERØY' },
  { id: 215, postal: '1684', name: 'VESTERØY' },
  { id: 216, postal: '1704', name: 'SARPSBORG' },
  { id: 217, postal: '1714', name: 'GRÅLUM' },
  { id: 218, postal: '1714', name: 'SARPSBORG' },
  { id: 219, postal: '1730', name: 'ISE' },
  { id: 220, postal: '1755', name: 'HALDEN' },
  { id: 221, postal: '1756', name: 'HALDEN' },
  { id: 222, postal: '1757', name: 'HALDEN' },
  { id: 223, postal: '1758', name: 'HALDEN' },
  { id: 224, postal: '1759', name: 'HALDEN' },
  { id: 225, postal: '1762', name: 'HALDEN' },
  { id: 226, postal: '1787', name: 'BERG I ØSTFOLD' },
  { id: 227, postal: '1788', name: 'BERG I ØSTFOLD' },
  { id: 228, postal: '1798', name: 'AREMARK' },
  { id: 229, postal: '1799', name: 'AREMARK' },
  { id: 230, postal: '1821', name: 'SPYDEBERG' },
  { id: 231, postal: '1827', name: 'HOBØL' },
  { id: 232, postal: '1828', name: 'HOBØL' },
  { id: 233, postal: '1833', name: 'ASKIM' },
  { id: 234, postal: '1852', name: 'MYSEN' },
  { id: 235, postal: '1892', name: 'DEGERNES' },
  { id: 236, postal: '1893', name: 'DEGERNES' },
  { id: 237, postal: '1894', name: 'RAKKESTAD' },
  { id: 238, postal: '1911', name: 'FLATEBY' },
  { id: 239, postal: '1912', name: 'ENEBAKK' },
  { id: 240, postal: '1914', name: 'YTRE ENEBAKK' },
  { id: 241, postal: '1916', name: 'FLATEBY' },
  { id: 242, postal: '1917', name: 'YTRE ENEBAKK' },
  { id: 243, postal: '1923', name: 'SØRUM' },
  { id: 244, postal: '1924', name: 'SØRUM' },
  { id: 245, postal: '1925', name: 'BLAKER' },
  { id: 246, postal: '1926', name: 'BLAKER' },
  { id: 247, postal: '1928', name: 'AULI' },
  { id: 248, postal: '1929', name: 'AULI' },
  { id: 249, postal: '1930', name: 'AURSKOG' },
  { id: 250, postal: '1931', name: 'AURSKOG' },
  { id: 251, postal: '1960', name: 'LØKEN' },
  { id: 252, postal: '1961', name: 'LØKEN' },
  { id: 253, postal: '1970', name: 'HEMNES' },
  { id: 254, postal: '1971', name: 'HEMNES' },
  { id: 255, postal: '2005', name: 'RÆLINGEN' },
  { id: 256, postal: '2006', name: 'LØVENSTAD' },
  { id: 257, postal: '2012', name: 'LILLESTRØM' },
  { id: 258, postal: '2016', name: 'FROGNER' },
  { id: 259, postal: '2017', name: 'FROGNER' },
  { id: 260, postal: '2018', name: 'LØVENSTAD' },
  { id: 261, postal: '2023', name: 'SKEDSMOKORSET' },
  { id: 262, postal: '2028', name: 'LILLESTRØM' },
  { id: 263, postal: '2029', name: 'RÆLINGEN' },
  { id: 264, postal: '2032', name: 'MAURA' },
  { id: 265, postal: '2034', name: 'HOLTER' },
  { id: 266, postal: '2035', name: 'HOLTER' },
  { id: 267, postal: '2036', name: 'MAURA' },
  { id: 268, postal: '2052', name: 'JESSHEIM' },
  { id: 269, postal: '2053', name: 'JESSHEIM' },
  { id: 270, postal: '2058', name: 'SESSVOLLMOEN' },
  { id: 271, postal: '2059', name: 'TRANDUM' },
  { id: 272, postal: '2062', name: 'JESSHEIM' },
  { id: 273, postal: '2063', name: 'JESSHEIM' },
  { id: 274, postal: '2065', name: 'GARDERMOEN' },
  { id: 275, postal: '2066', name: 'JESSHEIM' },
  { id: 276, postal: '2067', name: 'JESSHEIM' },
  { id: 277, postal: '2068', name: 'JESSHEIM' },
  { id: 278, postal: '2069', name: 'JESSHEIM' },
  { id: 279, postal: '2076', name: 'DAL' },
  { id: 280, postal: '2092', name: 'MINNESUND' },
  { id: 281, postal: '2093', name: 'FEIRING' },
  { id: 282, postal: '2094', name: 'MINNESUND' },
  { id: 283, postal: '2120', name: 'SAGSTUA' },
  { id: 284, postal: '2121', name: 'SAGSTUA' },
  { id: 285, postal: '2130', name: 'KNAPPER' },
  { id: 286, postal: '2132', name: 'GARDVIK' },
  { id: 287, postal: '2133', name: 'GARDVIK' },
  { id: 288, postal: '2160', name: 'VORMSUND' },
  { id: 289, postal: '2161', name: 'VORMSUND' },
  { id: 290, postal: '2163', name: 'SKOGBYGDA' },
  { id: 291, postal: '2164', name: 'SKOGBYGDA' },
  { id: 292, postal: '2165', name: 'HVAM' },
  { id: 293, postal: '2167', name: 'HVAM' },
  { id: 294, postal: '2170', name: 'FENSTAD' },
  { id: 295, postal: '2207', name: 'KONGSVINGER' },
  { id: 296, postal: '2215', name: 'ROVERUD' },
  { id: 297, postal: '2216', name: 'ROVERUD' },
  { id: 298, postal: '2224', name: 'AUSTMARKA' },
  { id: 299, postal: '2225', name: 'KONGSVINGER' },
  { id: 300, postal: '2226', name: 'KONGSVINGER' },
  { id: 301, postal: '2227', name: 'AUSTMARKA' },
  { id: 302, postal: '2230', name: 'SKOTTERUD' },
  { id: 303, postal: '2231', name: 'SKOTTERUD' },
  { id: 304, postal: '2240', name: 'MAGNOR' },
  { id: 305, postal: '2241', name: 'MAGNOR' },
  { id: 306, postal: '2242', name: 'MOROKULIEN' },
  { id: 307, postal: '2251', name: 'GRUE FINNSKOG' },
  { id: 308, postal: '2256', name: 'GRUE FINNSKOG' },
  { id: 309, postal: '2260', name: 'KIRKENÆR' },
  { id: 310, postal: '2261', name: 'KIRKENÆR' },
  { id: 311, postal: '2311', name: 'HAMAR' },
  { id: 312, postal: '2312', name: 'OTTESTAD' },
  { id: 313, postal: '2313', name: 'OTTESTAD' },
  { id: 314, postal: '2314', name: 'OTTESTAD' },
  { id: 315, postal: '2318', name: 'HAMAR' },
  { id: 316, postal: '2320', name: 'FURNES' },
  { id: 317, postal: '2322', name: 'RIDABU' },
  { id: 318, postal: '2324', name: 'VANG PÅ HEDMARKEN' },
  { id: 319, postal: '2324', name: 'VANG PÅ HEDMARK' },
  { id: 320, postal: '2325', name: 'HAMAR' },
  { id: 321, postal: '2326', name: 'HAMAR' },
  { id: 322, postal: '2327', name: 'FURNES' },
  { id: 323, postal: '2327', name: 'HAMAR' },
  { id: 324, postal: '2328', name: 'RIDABU' },
  { id: 325, postal: '2328', name: 'HAMAR' },
  { id: 326, postal: '2329', name: 'VANG PÅ HEDMARKEN' },
  { id: 327, postal: '2329', name: 'HAMAR' },
  { id: 328, postal: '2330', name: 'VALLSET' },
  { id: 329, postal: '2331', name: 'VALLSET' },
  { id: 330, postal: '2333', name: 'ROMEDAL' },
  { id: 331, postal: '2334', name: 'ROMEDAL' },
  { id: 332, postal: '2337', name: 'TANGEN' },
  { id: 333, postal: '2339', name: 'TANGEN' },
  { id: 334, postal: '2344', name: 'ILSENG' },
  { id: 335, postal: '2346', name: 'ILSENG' },
  { id: 336, postal: '2350', name: 'NES PÅ HEDMARKEN' },
  { id: 337, postal: '2350', name: 'NES PÅ HEDMARK' },
  { id: 338, postal: '2351', name: 'NES PÅ HEDMARKEN' },
  { id: 339, postal: '2353', name: 'STAVSJØ' },
  { id: 340, postal: '2360', name: 'RUDSHØGDA' },
  { id: 341, postal: '2361', name: 'RUDSHØGDA' },
  { id: 342, postal: '2372', name: 'BRØTTUM' },
  { id: 343, postal: '2373', name: 'BRØTTUM' },
  { id: 344, postal: '2382', name: 'BRUMUNDDAL' },
  { id: 345, postal: '2383', name: 'BRUMUNDDAL' },
  { id: 346, postal: '2384', name: 'BRUMUNDDAL' },
  { id: 347, postal: '2385', name: 'BRUMUNDDAL' },
  { id: 348, postal: '2386', name: 'BRUMUNDDAL' },
  { id: 349, postal: '2387', name: 'BRUMUNDDAL' },
  { id: 350, postal: '2388', name: 'BRUMUNDDAL' },
  { id: 351, postal: '2389', name: 'BRUMUNDDAL' },
  { id: 352, postal: '2404', name: 'ELVERUM' },
  { id: 353, postal: '2407', name: 'ELVERUM' },
  { id: 354, postal: '2409', name: 'ELVERUM' },
  { id: 355, postal: '2410', name: 'HERNES' },
  { id: 356, postal: '2413', name: 'ELVERUM' },
  { id: 357, postal: '2414', name: 'ELVERUM' },
  { id: 358, postal: '2415', name: 'HERADSBYGD' },
  { id: 359, postal: '2417', name: 'ELVERUM' },
  { id: 360, postal: '2418', name: 'ELVERUM' },
  { id: 361, postal: '2419', name: 'ELVERUM' },
  { id: 362, postal: '2422', name: 'NYBERGSUND' },
  { id: 363, postal: '2423', name: 'ØSTBY' },
  { id: 364, postal: '2424', name: 'ØSTBY' },
  { id: 365, postal: '2425', name: 'LJØRDALEN' },
  { id: 366, postal: '2426', name: 'LJØRDALEN' },
  { id: 367, postal: '2428', name: 'SØRE OSEN' },
  { id: 368, postal: '2430', name: 'JORDET' },
  { id: 369, postal: '2434', name: 'BRASKEREIDFOSS' },
  { id: 370, postal: '2435', name: 'BRASKEREIDFOSS' },
  { id: 371, postal: '2436', name: 'VÅLER I SOLØR' },
  { id: 372, postal: '2437', name: 'HASLEMOEN' },
  { id: 373, postal: '2439', name: 'VÅLER I SOLØR' },
  { id: 374, postal: '2440', name: 'ENGERDAL' },
  { id: 375, postal: '2441', name: 'ENGERDAL' },
  { id: 376, postal: '2442', name: 'HERADSBYGD' },
  { id: 377, postal: '2443', name: 'DREVSJØ' },
  { id: 378, postal: '2444', name: 'DREVSJØ' },
  { id: 379, postal: '2447', name: 'SØRE OSEN' },
  { id: 380, postal: '2460', name: 'OSEN' },
  { id: 381, postal: '2461', name: 'OSEN' },
  { id: 382, postal: '2484', name: 'RENDALEN' },
  { id: 383, postal: '2485', name: 'RENDALEN' },
  { id: 384, postal: '2486', name: 'RENDALEN' },
  { id: 385, postal: '2487', name: 'RENDALEN' },
  { id: 386, postal: '2488', name: 'RENDALEN' },
  { id: 387, postal: '2512', name: 'KVIKNE' },
  { id: 388, postal: '2513', name: 'KVIKNE' },
  { id: 389, postal: '2540', name: 'TOLGA' },
  { id: 390, postal: '2541', name: 'TOLGA' },
  { id: 391, postal: '2550', name: 'OS I ØSTERDALEN' },
  { id: 392, postal: '2551', name: 'OS I ØSTERDALEN' },
  { id: 393, postal: '2607', name: 'VINGROM' },
  { id: 394, postal: '2608', name: 'LILLEHAMMER' },
  { id: 395, postal: '2609', name: 'LILLEHAMMER' },
  { id: 396, postal: '2610', name: 'MESNALI' },
  { id: 397, postal: '2611', name: 'LILLEHAMMER' },
  { id: 398, postal: '2612', name: 'SJUSJØEN' },
  { id: 399, postal: '2617', name: 'LILLEHAMMER' },
  { id: 400, postal: '2617', name: 'JØRSTADMOEN' },
  { id: 401, postal: '2620', name: 'MESNALI' },
  { id: 402, postal: '2621', name: 'VINGROM' },
  { id: 403, postal: '2622', name: 'LILLEHAMMER' },
  { id: 404, postal: '2623', name: 'LILLEHAMMER' },
  { id: 405, postal: '2624', name: 'LILLEHAMMER' },
  { id: 406, postal: '2625', name: 'FÅBERG' },
  { id: 407, postal: '2626', name: 'LILLEHAMMER' },
  { id: 408, postal: '2627', name: 'FÅBERG' },
  { id: 409, postal: '2628', name: 'SJUSJØEN' },
  { id: 410, postal: '2629', name: 'LILLEHAMMER' },
  { id: 411, postal: '2635', name: 'TRETTEN' },
  { id: 412, postal: '2638', name: 'TRETTEN' },
  { id: 413, postal: '2641', name: 'KVAM' },
  { id: 414, postal: '2642', name: 'KVAM' },
  { id: 415, postal: '2643', name: 'SKÅBU' },
  { id: 416, postal: '2644', name: 'SKÅBU' },
  { id: 417, postal: '2653', name: 'VESTRE GAUSDAL' },
  { id: 418, postal: '2654', name: 'VESTRE GAUSDAL' },
  { id: 419, postal: '2656', name: 'FOLLEBU' },
  { id: 420, postal: '2665', name: 'LESJA' },
  { id: 421, postal: '2671', name: 'LESJA' },
  { id: 422, postal: '2672', name: 'SEL' },
  { id: 423, postal: '2676', name: 'HEIDAL' },
  { id: 424, postal: '2678', name: 'SEL' },
  { id: 425, postal: '2679', name: 'HEIDAL' },
  { id: 426, postal: '2681', name: 'LALM' },
  { id: 427, postal: '2682', name: 'LALM' },
  { id: 428, postal: '2806', name: 'GJØVIK' },
  { id: 429, postal: '2808', name: 'GJØVIK' },
  { id: 430, postal: '2809', name: 'GJØVIK' },
  { id: 431, postal: '2810', name: 'GJØVIK' },
  { id: 432, postal: '2812', name: 'GJØVIK' },
  { id: 433, postal: '2819', name: 'GJØVIK' },
  { id: 434, postal: '2820', name: 'NORDRE TOTEN' },
  { id: 435, postal: '2833', name: 'RAUFOSS' },
  { id: 436, postal: '2834', name: 'RAUFOSS' },
  { id: 437, postal: '2835', name: 'RAUFOSS' },
  { id: 438, postal: '2836', name: 'BIRI' },
  { id: 439, postal: '2838', name: 'SNERTINGDAL' },
  { id: 440, postal: '2841', name: 'SNERTINGDAL' },
  { id: 441, postal: '2844', name: 'KOLBU' },
  { id: 442, postal: '2845', name: 'BØVERBRU' },
  { id: 443, postal: '2846', name: 'BØVERBRU' },
  { id: 444, postal: '2847', name: 'KOLBU' },
  { id: 445, postal: '2863', name: 'VESTSIDA' },
  { id: 446, postal: '2880', name: 'NORD-TORPA' },
  { id: 447, postal: '2909', name: 'AURDAL' },
  { id: 448, postal: '2910', name: 'AURDAL' },
  { id: 449, postal: '2953', name: 'BEITOSTØLEN' },
  { id: 450, postal: '2954', name: 'BEITOSTØLEN' },
  { id: 451, postal: '2965', name: 'SLIDRE' },
  { id: 452, postal: '2966', name: 'SLIDRE' },
  { id: 453, postal: '2972', name: 'RYFOSS' },
  { id: 454, postal: '2973', name: 'RYFOSS' },
  { id: 455, postal: '3004', name: 'DRAMMEN' },
  { id: 456, postal: '3008', name: 'DRAMMEN' },
  { id: 457, postal: '3009', name: 'DRAMMEN' },
  { id: 458, postal: '3010', name: 'DRAMMEN' },
  { id: 459, postal: '3020', name: 'DRAMMEN' },
  { id: 460, postal: '3023', name: 'DRAMMEN' },
  { id: 461, postal: '3024', name: 'DRAMMEN' },
  { id: 462, postal: '3028', name: 'DRAMMEN' },
  { id: 463, postal: '3034', name: 'DRAMMEN' },
  { id: 464, postal: '3038', name: 'DRAMMEN' },
  { id: 465, postal: '3063', name: 'DRAMMEN' },
  { id: 466, postal: '3064', name: 'DRAMMEN' },
  { id: 467, postal: '3065', name: 'DRAMMEN' },
  { id: 468, postal: '3066', name: 'DRAMMEN' },
  { id: 469, postal: '3072', name: 'SANDE I VESTFOLD' },
  { id: 470, postal: '3073', name: 'SANDE I VESTFOLD' },
  { id: 471, postal: '3074', name: 'SANDE I VESTFOLD' },
  { id: 472, postal: '3076', name: 'SANDE I VESTFOLD' },
  { id: 473, postal: '3077', name: 'SANDE I VESTFOLD' },
  { id: 474, postal: '3080', name: 'HOLMESTRAND' },
  { id: 475, postal: '3081', name: 'HOLMESTRAND' },
  { id: 476, postal: '3082', name: 'HOLMESTRAND' },
  { id: 477, postal: '3083', name: 'HOLMESTRAND' },
  { id: 478, postal: '3084', name: 'HOLMESTRAND' },
  { id: 479, postal: '3085', name: 'HOLMESTRAND' },
  { id: 480, postal: '3086', name: 'HOLMESTRAND' },
  { id: 481, postal: '3087', name: 'HOLMESTRAND' },
  { id: 482, postal: '3087', name: 'BOTNE' },
  { id: 483, postal: '3088', name: 'HOLMESTRAND' },
  { id: 484, postal: '3088', name: 'BOTNE' },
  { id: 485, postal: '3089', name: 'HOLMESTRAND' },
  { id: 486, postal: '3090', name: 'HOF' },
  { id: 487, postal: '3091', name: 'HOF' },
  { id: 488, postal: '3092', name: 'SUNDBYFOSS' },
  { id: 489, postal: '3095', name: 'EIDSFOSS' },
  { id: 490, postal: '3102', name: 'TØNSBERG' },
  { id: 491, postal: '3106', name: 'NØTTERØY' },
  { id: 492, postal: '3106', name: 'TØNSBERG' },
  { id: 493, postal: '3108', name: 'VEAR' },
  { id: 494, postal: '3113', name: 'TØNSBERG' },
  { id: 495, postal: '3119', name: 'TØNSBERG' },
  { id: 496, postal: '3120', name: 'NØTTERØY' },
  { id: 497, postal: '3120', name: 'TØNSBERG' },
  { id: 498, postal: '3121', name: 'NØTTERØY' },
  { id: 499, postal: '3121', name: 'TØNSBERG' },
  { id: 500, postal: '3127', name: 'TØNSBERG' },
  { id: 501, postal: '3128', name: 'NØTTERØY' },
  { id: 502, postal: '3129', name: 'TØNSBERG' },
  { id: 503, postal: '3131', name: 'HUSØYSUND' },
  { id: 504, postal: '3132', name: 'HUSØYSUND' },
  { id: 505, postal: '3133', name: 'DUKEN' },
  { id: 506, postal: '3134', name: 'TØNSBERG' },
  { id: 507, postal: '3135', name: 'TORØD' },
  { id: 508, postal: '3137', name: 'TORØD' },
  { id: 509, postal: '3138', name: 'SKALLESTAD' },
  { id: 510, postal: '3139', name: 'SKALLESTAD' },
  { id: 511, postal: '3140', name: 'NØTTERØY' },
  { id: 512, postal: '3140', name: 'BORGHEIM' },
  { id: 513, postal: '3141', name: 'KJØPMANNSKJÆR' },
  { id: 514, postal: '3142', name: 'VESTSKOGEN' },
  { id: 515, postal: '3143', name: 'KJØPMANNSKJÆR' },
  { id: 516, postal: '3144', name: 'VEIERLAND' },
  { id: 517, postal: '3145', name: 'TJØME' },
  { id: 518, postal: '3147', name: 'VERDENS ENDE' },
  { id: 519, postal: '3148', name: 'HVASSER' },
  { id: 520, postal: '3156', name: 'MELSOMVIK' },
  { id: 521, postal: '3158', name: 'ANDEBU' },
  { id: 522, postal: '3159', name: 'MELSOMVIK' },
  { id: 523, postal: '3160', name: 'STOKKE' },
  { id: 524, postal: '3161', name: 'STOKKE' },
  { id: 525, postal: '3162', name: 'ANDEBU' },
  { id: 526, postal: '3163', name: 'NØTTERØY' },
  { id: 527, postal: '3163', name: 'BORGHEIM' },
  { id: 528, postal: '3165', name: 'TJØME' },
  { id: 529, postal: '3168', name: 'MELSOMVIK' },
  { id: 530, postal: '3169', name: 'STOKKE' },
  { id: 531, postal: '3173', name: 'VEAR' },
  { id: 532, postal: '3176', name: 'UNDRUMSDAL' },
  { id: 533, postal: '3180', name: 'NYKIRKE' },
  { id: 534, postal: '3184', name: 'BORRE' },
  { id: 535, postal: '3197', name: 'NYKIRKE' },
  { id: 536, postal: '3197', name: 'HORTEN' },
  { id: 537, postal: '3198', name: 'HORTEN' },
  { id: 538, postal: '3199', name: 'BORRE' },
  { id: 539, postal: '3199', name: 'HORTEN' },
  { id: 540, postal: '3201', name: 'SANDEFJORD' },
  { id: 541, postal: '3202', name: 'SANDEFJORD' },
  { id: 542, postal: '3203', name: 'SANDEFJORD' },
  { id: 543, postal: '3204', name: 'SANDEFJORD' },
  { id: 544, postal: '3205', name: 'SANDEFJORD' },
  { id: 545, postal: '3206', name: 'SANDEFJORD' },
  { id: 546, postal: '3207', name: 'SANDEFJORD' },
  { id: 547, postal: '3208', name: 'SANDEFJORD' },
  { id: 548, postal: '3209', name: 'SANDEFJORD' },
  { id: 549, postal: '3210', name: 'SANDEFJORD' },
  { id: 550, postal: '3211', name: 'SANDEFJORD' },
  { id: 551, postal: '3212', name: 'SANDEFJORD' },
  { id: 552, postal: '3213', name: 'SANDEFJORD' },
  { id: 553, postal: '3214', name: 'SANDEFJORD' },
  { id: 554, postal: '3215', name: 'SANDEFJORD' },
  { id: 555, postal: '3216', name: 'SANDEFJORD' },
  { id: 556, postal: '3217', name: 'SANDEFJORD' },
  { id: 557, postal: '3218', name: 'SANDEFJORD' },
  { id: 558, postal: '3219', name: 'SANDEFJORD' },
  { id: 559, postal: '3220', name: 'SANDEFJORD' },
  { id: 560, postal: '3221', name: 'SANDEFJORD' },
  { id: 561, postal: '3222', name: 'SANDEFJORD' },
  { id: 562, postal: '3223', name: 'SANDEFJORD' },
  { id: 563, postal: '3224', name: 'SANDEFJORD' },
  { id: 564, postal: '3225', name: 'SANDEFJORD' },
  { id: 565, postal: '3226', name: 'SANDEFJORD' },
  { id: 566, postal: '3227', name: 'SANDEFJORD' },
  { id: 567, postal: '3228', name: 'SANDEFJORD' },
  { id: 568, postal: '3229', name: 'SANDEFJORD' },
  { id: 569, postal: '3230', name: 'SANDEFJORD' },
  { id: 570, postal: '3231', name: 'SANDEFJORD' },
  { id: 571, postal: '3232', name: 'SANDEFJORD' },
  { id: 572, postal: '3233', name: 'SANDEFJORD' },
  { id: 573, postal: '3234', name: 'SANDEFJORD' },
  { id: 574, postal: '3235', name: 'SANDEFJORD' },
  { id: 575, postal: '3236', name: 'SANDEFJORD' },
  { id: 576, postal: '3237', name: 'SANDEFJORD' },
  { id: 577, postal: '3238', name: 'SANDEFJORD' },
  { id: 578, postal: '3239', name: 'SANDEFJORD' },
  { id: 579, postal: '3240', name: 'SANDEFJORD' },
  { id: 580, postal: '3241', name: 'SANDEFJORD' },
  { id: 581, postal: '3242', name: 'SANDEFJORD' },
  { id: 582, postal: '3243', name: 'KODAL' },
  { id: 583, postal: '3244', name: 'SANDEFJORD' },
  { id: 584, postal: '3245', name: 'KODAL' },
  { id: 585, postal: '3246', name: 'SANDEFJORD' },
  { id: 586, postal: '3247', name: 'SANDEFJORD' },
  { id: 587, postal: '3248', name: 'SANDEFJORD' },
  { id: 588, postal: '3249', name: 'SANDEFJORD' },
  { id: 589, postal: '3251', name: 'LARVIK' },
  { id: 590, postal: '3252', name: 'LARVIK' },
  { id: 591, postal: '3253', name: 'LARVIK' },
  { id: 592, postal: '3254', name: 'LARVIK' },
  { id: 593, postal: '3255', name: 'LARVIK' },
  { id: 594, postal: '3256', name: 'LARVIK' },
  { id: 595, postal: '3257', name: 'LARVIK' },
  { id: 596, postal: '3258', name: 'LARVIK' },
  { id: 597, postal: '3259', name: 'LARVIK' },
  { id: 598, postal: '3260', name: 'LARVIK' },
  { id: 599, postal: '3261', name: 'LARVIK' },
  { id: 600, postal: '3262', name: 'LARVIK' },
  { id: 601, postal: '3263', name: 'LARVIK' },
  { id: 602, postal: '3264', name: 'LARVIK' },
  { id: 603, postal: '3265', name: 'LARVIK' },
  { id: 604, postal: '3267', name: 'LARVIK' },
  { id: 605, postal: '3268', name: 'LARVIK' },
  { id: 606, postal: '3269', name: 'LARVIK' },
  { id: 607, postal: '3270', name: 'LARVIK' },
  { id: 608, postal: '3271', name: 'LARVIK' },
  { id: 609, postal: '3274', name: 'LARVIK' },
  { id: 610, postal: '3275', name: 'SVARSTAD' },
  { id: 611, postal: '3276', name: 'SVARSTAD' },
  { id: 612, postal: '3277', name: 'STEINSHOLT' },
  { id: 613, postal: '3280', name: 'TJODALYNG' },
  { id: 614, postal: '3281', name: 'TJODALYNG' },
  { id: 615, postal: '3282', name: 'KVELDE' },
  { id: 616, postal: '3284', name: 'KVELDE' },
  { id: 617, postal: '3285', name: 'LARVIK' },
  { id: 618, postal: '3290', name: 'STAVERN' },
  { id: 619, postal: '3291', name: 'STAVERN' },
  { id: 620, postal: '3292', name: 'STAVERN' },
  { id: 621, postal: '3294', name: 'STAVERN' },
  { id: 622, postal: '3295', name: 'HELGEROA' },
  { id: 623, postal: '3296', name: 'NEVLUNGHAVN' },
  { id: 624, postal: '3296', name: 'NEVLUNGHAMN' },
  { id: 625, postal: '3297', name: 'HELGEROA' },
  { id: 626, postal: '3302', name: 'HOKKSUND' },
  { id: 627, postal: '3303', name: 'HOKKSUND' },
  { id: 628, postal: '3322', name: 'DARBU' },
  { id: 629, postal: '3357', name: 'EGGEDAL' },
  { id: 630, postal: '3359', name: 'EGGEDAL' },
  { id: 631, postal: '3387', name: 'SNARUM' },
  { id: 632, postal: '3400', name: 'LIER' },
  { id: 633, postal: '3402', name: 'LIER' },
  { id: 634, postal: '3403', name: 'LIER' },
  { id: 635, postal: '3404', name: 'LIER' },
  { id: 636, postal: '3405', name: 'LIER' },
  { id: 637, postal: '3406', name: 'TRANBY' },
  { id: 638, postal: '3409', name: 'TRANBY' },
  { id: 639, postal: '3413', name: 'LIER' },
  { id: 640, postal: '3414', name: 'LIERSTRANDA' },
  { id: 641, postal: '3426', name: 'GULLAUG' },
  { id: 642, postal: '3427', name: 'GULLAUG' },
  { id: 643, postal: '3428', name: 'LIER' },
  { id: 644, postal: '3478', name: 'NÆRSNES' },
  { id: 645, postal: '3479', name: 'NÆRSNES' },
  { id: 646, postal: '3480', name: 'FILTVET' },
  { id: 647, postal: '3485', name: 'FILTVET' },
  { id: 648, postal: '3491', name: 'KLOKKARSTUA' },
  { id: 649, postal: '3504', name: 'HØNEFOSS' },
  { id: 650, postal: '3505', name: 'HØNEFOSS' },
  { id: 651, postal: '3506', name: 'HØNEFOSS' },
  { id: 652, postal: '3507', name: 'HØNEFOSS' },
  { id: 653, postal: '3527', name: 'BJONEROA' },
  { id: 654, postal: '3532', name: 'TYRISTRAND' },
  { id: 655, postal: '3533', name: 'TYRISTRAND' },
  { id: 656, postal: '3536', name: 'NORESUND' },
  { id: 657, postal: '3539', name: 'FLÅ' },
  { id: 658, postal: '3543', name: 'NORESUND' },
  { id: 659, postal: '3544', name: 'TUNHOVD' },
  { id: 660, postal: '3545', name: 'FLÅ' },
  { id: 661, postal: '3588', name: 'DAGALI' },
  { id: 662, postal: '3596', name: 'HALNE FJELLSTOVE' },
  { id: 663, postal: '3598', name: 'DYRANUT' },
  { id: 664, postal: '3599', name: 'SANDHAUG' },
  { id: 665, postal: '3601', name: 'KONGSBERG' },
  { id: 666, postal: '3607', name: 'KONGSBERG' },
  { id: 667, postal: '3608', name: 'HEISTADMOEN' },
  { id: 668, postal: '3609', name: 'KONGSBERG' },
  { id: 669, postal: '3610', name: 'KONGSBERG' },
  { id: 670, postal: '3618', name: 'SKOLLENBORG' },
  { id: 671, postal: '3625', name: 'SKOLLENBORG' },
  { id: 672, postal: '3629', name: 'NORE' },
  { id: 673, postal: '3634', name: 'NORE' },
  { id: 674, postal: '3666', name: 'TINN AUSTBYGD' },
  { id: 675, postal: '3687', name: 'NOTODDEN' },
  { id: 676, postal: '3688', name: 'NOTODDEN' },
  { id: 677, postal: '3689', name: 'NOTODDEN' },
  { id: 678, postal: '3699', name: 'GAUSTATOPPEN TURISTHYTTE' },
  { id: 679, postal: '3706', name: 'SKIEN' },
  { id: 680, postal: '3708', name: 'SKIEN' },
  { id: 681, postal: '3709', name: 'SKIEN' },
  { id: 682, postal: '3710', name: 'SKIEN' },
  { id: 683, postal: '3772', name: 'KRAGERØ' },
  { id: 684, postal: '3782', name: 'KRAGERØ SOMMERRUTE' },
  { id: 685, postal: '3783', name: 'KRAGERØ SKJÆRGÅRDSRUTE' },
  { id: 686, postal: '3784', name: 'LANGØY GRUVER' },
  { id: 687, postal: '3785', name: 'SKIEN' },
  { id: 688, postal: '3786', name: 'SKIEN' },
  { id: 689, postal: '3797', name: 'SKIEN' },
  { id: 690, postal: '3799', name: 'SKIEN' },
  { id: 691, postal: '3801', name: 'BØ I TELEMARK' },
  { id: 692, postal: '3802', name: 'BØ I TELEMARK' },
  { id: 693, postal: '3803', name: 'BØ I TELEMARK' },
  { id: 694, postal: '3804', name: 'BØ I TELEMARK' },
  { id: 695, postal: '3805', name: 'BØ I TELEMARK' },
  { id: 696, postal: '3811', name: 'HØRTE' },
  { id: 697, postal: '3844', name: 'ÅMOTSDAL' },
  { id: 698, postal: '3852', name: 'VRÅDAL' },
  { id: 699, postal: '3853', name: 'VRÅDAL' },
  { id: 700, postal: '3907', name: 'PORSGRUNN' },
  { id: 701, postal: '3908', name: 'PORSGRUNN' },
  { id: 702, postal: '3909', name: 'PORSGRUNN' },
  { id: 703, postal: '3932', name: 'PORSGRUNN' },
  { id: 704, postal: '3939', name: 'PORSGRUNN' },
  { id: 705, postal: '3945', name: 'PORSGRUNN' },
  { id: 706, postal: '3961', name: 'STATHELLE' },
  { id: 707, postal: '3962', name: 'STATHELLE' },
  { id: 708, postal: '3966', name: 'STATHELLE' },
  { id: 709, postal: '3967', name: 'BAMBLE SOMMERRUTE' },
  { id: 710, postal: '4031', name: 'STAVANGER' },
  { id: 711, postal: '4035', name: 'STAVANGER' },
  { id: 712, postal: '4036', name: 'STAVANGER' },
  { id: 713, postal: '4051', name: 'SOLA' },
  { id: 714, postal: '4054', name: 'TJELTA' },
  { id: 715, postal: '4055', name: 'STAVANGER LUFTHAVN' },
  { id: 716, postal: '4057', name: 'TANANGER' },
  { id: 717, postal: '4058', name: 'TANANGER' },
  { id: 718, postal: '4059', name: 'RØYNEBERG' },
  { id: 719, postal: '4063', name: 'TJELTA' },
  { id: 720, postal: '4064', name: 'STAVANGER' },
  { id: 721, postal: '4065', name: 'STAVANGER' },
  { id: 722, postal: '4066', name: 'STAVANGER' },
  { id: 723, postal: '4067', name: 'STAVANGER' },
  { id: 724, postal: '4069', name: 'STAVANGER' },
  { id: 725, postal: '4071', name: 'RANDABERG' },
  { id: 726, postal: '4072', name: 'RANDABERG' },
  { id: 727, postal: '4073', name: 'RANDABERG' },
  { id: 728, postal: '4076', name: 'VASSØY' },
  { id: 729, postal: '4077', name: 'HUNDVÅG' },
  { id: 730, postal: '4083', name: 'HUNDVÅG' },
  { id: 731, postal: '4091', name: 'HAFRSFJORD' },
  { id: 732, postal: '4091', name: 'STAVANGER' },
  { id: 733, postal: '4094', name: 'STAVANGER' },
  { id: 734, postal: '4099', name: 'STAVANGER' },
  { id: 735, postal: '4103', name: 'JØRPELAND' },
  { id: 736, postal: '4104', name: 'JØRPELAND' },
  { id: 737, postal: '4105', name: 'JØRPELAND' },
  { id: 738, postal: '4121', name: 'TAU' },
  { id: 739, postal: '4122', name: 'FISKÅ' },
  { id: 740, postal: '4157', name: 'UTSTEIN KLOSTER' },
  { id: 741, postal: '4174', name: 'SJERNARØY' },
  { id: 742, postal: '4174', name: 'HELGØYSUND' },
  { id: 743, postal: '4180', name: 'KVITSØY' },
  { id: 744, postal: '4181', name: 'KVITSØY' },
  { id: 745, postal: '4244', name: 'NESFLATEN' },
  { id: 746, postal: '4272', name: 'SANDVE' },
  { id: 747, postal: '4274', name: 'STOL' },
  { id: 748, postal: '4276', name: 'VEDAVÅGEN' },
  { id: 749, postal: '4295', name: 'VEDAVÅGEN' },
  { id: 750, postal: '4303', name: 'SANDNES' },
  { id: 751, postal: '4304', name: 'SANDNES' },
  { id: 752, postal: '4305', name: 'SANDNES' },
  { id: 753, postal: '4308', name: 'SANDNES' },
  { id: 754, postal: '4320', name: 'SANDNES' },
  { id: 755, postal: '4331', name: 'ÅLGÅRD' },
  { id: 756, postal: '4333', name: 'OLTEDAL' },
  { id: 757, postal: '4334', name: 'ÅLGÅRD' },
  { id: 758, postal: '4336', name: 'SANDNES' },
  { id: 759, postal: '4337', name: 'SANDNES' },
  { id: 760, postal: '4338', name: 'SANDNES' },
  { id: 761, postal: '4341', name: 'BRYNE' },
  { id: 762, postal: '4344', name: 'BRYNE' },
  { id: 763, postal: '4345', name: 'BRYNE' },
  { id: 764, postal: '4346', name: 'BRYNE' },
  { id: 765, postal: '4347', name: 'LYE' },
  { id: 766, postal: '4348', name: 'BRYNE' },
  { id: 767, postal: '4350', name: 'KLEPPE' },
  { id: 768, postal: '4351', name: 'KLEPPE' },
  { id: 769, postal: '4361', name: 'SIREVÅG' },
  { id: 770, postal: '4364', name: 'SIREVÅG' },
  { id: 771, postal: '4370', name: 'EGERSUND' },
  { id: 772, postal: '4371', name: 'EGERSUND' },
  { id: 773, postal: '4372', name: 'EGERSUND' },
  { id: 774, postal: '4373', name: 'EGERSUND' },
  { id: 775, postal: '4374', name: 'EGERSUND' },
  { id: 776, postal: '4376', name: 'HELLELAND' },
  { id: 777, postal: '4378', name: 'EGERSUND' },
  { id: 778, postal: '4384', name: 'VIKESÅ' },
  { id: 779, postal: '4385', name: 'HELLELAND' },
  { id: 780, postal: '4389', name: 'VIKESÅ' },
  { id: 781, postal: '4390', name: 'OLTEDAL' },
  { id: 782, postal: '4394', name: 'SANDNES' },
  { id: 783, postal: '4395', name: 'HOMMERSÅK' },
  { id: 784, postal: '4398', name: 'SANDNES' },
  { id: 785, postal: '4399', name: 'SANDNES' },
  { id: 786, postal: '4404', name: 'FLEKKEFJORD' },
  { id: 787, postal: '4405', name: 'FLEKKEFJORD' },
  { id: 788, postal: '4406', name: 'FLEKKEFJORD' },
  { id: 789, postal: '4407', name: 'FLEKKEFJORD' },
  { id: 790, postal: '4438', name: 'SIRA' },
  { id: 791, postal: '4439', name: 'SIRA' },
  { id: 792, postal: '4505', name: 'MANDAL' },
  { id: 793, postal: '4506', name: 'MANDAL' },
  { id: 794, postal: '4507', name: 'MANDAL' },
  { id: 795, postal: '4508', name: 'MANDAL' },
  { id: 796, postal: '4509', name: 'MANDAL' },
  { id: 797, postal: '4512', name: 'LINDESNES' },
  { id: 798, postal: '4520', name: 'SØR-AUDNEDAL' },
  { id: 799, postal: '4521', name: 'LINDESNES' },
  { id: 800, postal: '4521', name: 'SPANGEREID' },
  { id: 801, postal: '4522', name: 'LINDESNES' },
  { id: 802, postal: '4523', name: 'SØR-AUDNEDAL' },
  { id: 803, postal: '4524', name: 'SØR-AUDNEDAL' },
  { id: 804, postal: '4525', name: 'KONSMO' },
  { id: 805, postal: '4526', name: 'KONSMO' },
  { id: 806, postal: '4534', name: 'MARNARDAL' },
  { id: 807, postal: '4535', name: 'MARNARDAL' },
  { id: 808, postal: '4540', name: 'ÅSERAL' },
  { id: 809, postal: '4541', name: 'ÅSERAL' },
  { id: 810, postal: '4596', name: 'EIKEN' },
  { id: 811, postal: '4597', name: 'EIKEN' },
  { id: 812, postal: '4604', name: 'KRISTIANSAND S' },
  { id: 813, postal: '4605', name: 'KRISTIANSAND S' },
  { id: 814, postal: '4606', name: 'KRISTIANSAND S' },
  { id: 815, postal: '4608', name: 'KRISTIANSAND S' },
  { id: 816, postal: '4609', name: 'KARDEMOMME BY' },
  { id: 817, postal: '4612', name: 'KRISTIANSAND S' },
  { id: 818, postal: '4618', name: 'KRISTIANSAND S' },
  { id: 819, postal: '4620', name: 'KRISTIANSAND S' },
  { id: 820, postal: '4626', name: 'KRISTIANSAND S' },
  { id: 821, postal: '4629', name: 'KRISTIANSAND S' },
  { id: 822, postal: '4634', name: 'KRISTIANSAND S' },
  { id: 823, postal: '4639', name: 'KRISTIANSAND S' },
  { id: 824, postal: '4640', name: 'SØGNE' },
  { id: 825, postal: '4641', name: 'SØGNE' },
  { id: 826, postal: '4642', name: 'SØGNE' },
  { id: 827, postal: '4643', name: 'SØGNE' },
  { id: 828, postal: '4644', name: 'SØGNE' },
  { id: 829, postal: '4646', name: 'FINSLAND' },
  { id: 830, postal: '4647', name: 'BRENNÅSEN' },
  { id: 831, postal: '4649', name: 'FINSLAND' },
  { id: 832, postal: '4651', name: 'HAMRESANDEN' },
  { id: 833, postal: '4659', name: 'KRISTIANSAND S' },
  { id: 834, postal: '4668', name: 'KRISTIANSAND S' },
  { id: 835, postal: '4669', name: 'KRISTIANSAND S' },
  { id: 836, postal: '4670', name: 'KRISTIANSAND S' },
  { id: 837, postal: '4671', name: 'MOSBY' },
  { id: 838, postal: '4672', name: 'KRISTIANSAND S' },
  { id: 839, postal: '4681', name: 'SØGNE' },
  { id: 840, postal: '4684', name: 'BRENNÅSEN' },
  { id: 841, postal: '4694', name: 'KRISTIANSAND S' },
  { id: 842, postal: '4695', name: 'KRISTIANSAND S' },
  { id: 843, postal: '4698', name: 'KRISTIANSAND S' },
  { id: 844, postal: '4698', name: 'KJEVIK' },
  { id: 845, postal: '4706', name: 'VENNESLA' },
  { id: 846, postal: '4707', name: 'VENNESLA' },
  { id: 847, postal: '4708', name: 'VENNESLA' },
  { id: 848, postal: '4720', name: 'HÆGELAND' },
  { id: 849, postal: '4721', name: 'HÆGELAND' },
  { id: 850, postal: '4724', name: 'IVELAND' },
  { id: 851, postal: '4725', name: 'IVELAND' },
  { id: 852, postal: '4735', name: 'EVJE' },
  { id: 853, postal: '4738', name: 'EVJEMOEN' },
  { id: 854, postal: '4742', name: 'GRENDI' },
  { id: 855, postal: '4744', name: 'BYGLAND' },
  { id: 856, postal: '4745', name: 'BYGLAND' },
  { id: 857, postal: '4746', name: 'VALLE' },
  { id: 858, postal: '4747', name: 'VALLE' },
  { id: 859, postal: '4748', name: 'RYSSTAD' },
  { id: 860, postal: '4749', name: 'RYSSTAD' },
  { id: 861, postal: '4755', name: 'HOVDEN I SETESDAL' },
  { id: 862, postal: '4756', name: 'HOVDEN I SETESDAL' },
  { id: 863, postal: '4770', name: 'HØVÅG' },
  { id: 864, postal: '4793', name: 'HØVÅG' },
  { id: 865, postal: '4794', name: 'LILLESAND' },
  { id: 866, postal: '4808', name: 'ARENDAL' },
  { id: 867, postal: '4809', name: 'ARENDAL' },
  { id: 868, postal: '4821', name: 'RYKENE' },
  { id: 869, postal: '4822', name: 'RYKENE' },
  { id: 870, postal: '4835', name: 'SKJEGGEDAL' },
  { id: 871, postal: '4861', name: 'ARENDAL' },
  { id: 872, postal: '4893', name: 'GRIMSTAD' },
  { id: 873, postal: '4894', name: 'GRIMSTAD' },
  { id: 874, postal: '4895', name: 'GRIMSTAD' },
  { id: 875, postal: '4896', name: 'GRIMSTAD' },
  { id: 876, postal: '4898', name: 'GRIMSTAD' },
  { id: 877, postal: '4903', name: 'TVEDESTRAND' },
  { id: 878, postal: '4904', name: 'TVEDESTRAND' },
  { id: 879, postal: '4905', name: 'TVEDESTRAND' },
  { id: 880, postal: '4920', name: 'STAUBØ' },
  { id: 881, postal: '4921', name: 'STAUBØ' },
  { id: 882, postal: '4934', name: 'NESBRENDA' },
  { id: 883, postal: '4956', name: 'RISØR' },
  { id: 884, postal: '4957', name: 'RISØR' },
  { id: 885, postal: '5003', name: 'BERGEN' },
  { id: 886, postal: '5006', name: 'BERGEN' },
  { id: 887, postal: '5020', name: 'BERGEN' },
  { id: 888, postal: '5021', name: 'BERGEN' },
  { id: 889, postal: '5022', name: 'BERGEN' },
  { id: 890, postal: '5025', name: 'BERGEN' },
  { id: 891, postal: '5031', name: 'BERGEN' },
  { id: 892, postal: '5032', name: 'BERGEN' },
  { id: 893, postal: '5033', name: 'BERGEN' },
  { id: 894, postal: '5034', name: 'BERGEN' },
  { id: 895, postal: '5035', name: 'BERGEN' },
  { id: 896, postal: '5036', name: 'BERGEN' },
  { id: 897, postal: '5037', name: 'BERGEN' },
  { id: 898, postal: '5041', name: 'BERGEN' },
  { id: 899, postal: '5042', name: 'BERGEN' },
  { id: 900, postal: '5043', name: 'BERGEN' },
  { id: 901, postal: '5045', name: 'BERGEN' },
  { id: 902, postal: '5075', name: 'BERGEN' },
  { id: 903, postal: '5082', name: 'BERGEN' },
  { id: 904, postal: '5099', name: 'BERGEN' },
  { id: 905, postal: '5113', name: 'TERTNES' },
  { id: 906, postal: '5124', name: 'MORVIK' },
  { id: 907, postal: '5130', name: 'NYBORG' },
  { id: 908, postal: '5141', name: 'FYLLINGSDALEN' },
  { id: 909, postal: '5153', name: 'BØNES' },
  { id: 910, postal: '5154', name: 'BØNES' },
  { id: 911, postal: '5160', name: 'LAKSEVÅG' },
  { id: 912, postal: '5165', name: 'LAKSEVÅG' },
  { id: 913, postal: '5170', name: 'BJØRNDALSTRÆ' },
  { id: 914, postal: '5171', name: 'LODDEFJORD' },
  { id: 915, postal: '5174', name: 'MATHOPEN' },
  { id: 916, postal: '5176', name: 'LODDEFJORD' },
  { id: 917, postal: '5206', name: 'OS' },
  { id: 918, postal: '5208', name: 'OS' },
  { id: 919, postal: '5209', name: 'OS' },
  { id: 920, postal: '5210', name: 'OS' },
  { id: 921, postal: '5211', name: 'OS' },
  { id: 922, postal: '5213', name: 'LEPSØY' },
  { id: 923, postal: '5214', name: 'LYSEKLOSTER' },
  { id: 924, postal: '5215', name: 'LYSEKLOSTER' },
  { id: 925, postal: '5216', name: 'LEPSØY' },
  { id: 926, postal: '5219', name: 'SKORPO FERIEHEIM' },
  { id: 927, postal: '5228', name: 'NESTTUN' },
  { id: 928, postal: '5230', name: 'PARADIS' },
  { id: 929, postal: '5236', name: 'RÅDAL' },
  { id: 930, postal: '5237', name: 'RÅDAL' },
  { id: 931, postal: '5251', name: 'SØREIDGREND' },
  { id: 932, postal: '5259', name: 'HJELLESTAD' },
  { id: 933, postal: '5268', name: 'HAUKELAND' },
  { id: 934, postal: '5295', name: 'FOTLANDSVÅG' },
  { id: 935, postal: '5301', name: 'KLEPPESTØ' },
  { id: 936, postal: '5308', name: 'KLEPPESTØ' },
  { id: 937, postal: '5309', name: 'KLEPPESTØ' },
  { id: 938, postal: '5311', name: 'KJERRGARDEN' },
  { id: 939, postal: '5314', name: 'KJERRGARDEN' },
  { id: 940, postal: '5319', name: 'KLEPPESTØ' },
  { id: 941, postal: '5335', name: 'HERNAR' },
  { id: 942, postal: '5347', name: 'ÅGOTNES' },
  { id: 943, postal: '5347', name: 'KYSTBASEN ÅGOTNES' },
  { id: 944, postal: '5354', name: 'STRAUME' },
  { id: 945, postal: '5358', name: 'FJELL' },
  { id: 946, postal: '5360', name: 'KOLLTVEIT' },
  { id: 947, postal: '5366', name: 'MISJE' },
  { id: 948, postal: '5373', name: 'KLOKKARVIK' },
  { id: 949, postal: '5405', name: 'STORD' },
  { id: 950, postal: '5409', name: 'STORD' },
  { id: 951, postal: '5448', name: 'RUBBESTADNESET' },
  { id: 952, postal: '5450', name: 'SUNDE I SUNNHORDLAND' },
  { id: 953, postal: '5462', name: 'HERØYSUNDET' },
  { id: 954, postal: '5497', name: 'HUGLO' },
  { id: 955, postal: '5512', name: 'HAUGESUND' },
  { id: 956, postal: '5513', name: 'HAUGESUND' },
  { id: 957, postal: '5518', name: 'HAUGESUND' },
  { id: 958, postal: '5534', name: 'HAUGESUND' },
  { id: 959, postal: '5554', name: 'VALEVÅG' },
  { id: 960, postal: '5555', name: 'FØRDE I HORDALAND' },
  { id: 961, postal: '5556', name: 'FØRDE I HORDALAND' },
  { id: 962, postal: '5561', name: 'BOKN' },
  { id: 963, postal: '5562', name: 'NEDSTRAND' },
  { id: 964, postal: '5567', name: 'SKJOLDASTRAUMEN' },
  { id: 965, postal: '5569', name: 'BOKN' },
  { id: 966, postal: '5574', name: 'SKJOLD' },
  { id: 967, postal: '5578', name: 'NEDRE VATS' },
  { id: 968, postal: '5584', name: 'BJOA' },
  { id: 969, postal: '5595', name: 'FØRRESFJORDEN' },
  { id: 970, postal: '5630', name: 'STRANDEBARM' },
  { id: 971, postal: '5631', name: 'STRANDEBARM' },
  { id: 972, postal: '5632', name: 'OMASTRAND' },
  { id: 973, postal: '5633', name: 'OMASTRAND' },
  { id: 974, postal: '5635', name: 'HATLESTRAND' },
  { id: 975, postal: '5641', name: 'FUSA' },
  { id: 976, postal: '5644', name: 'SÆVAREID' },
  { id: 977, postal: '5645', name: 'SÆVAREID' },
  { id: 978, postal: '5648', name: 'FUSA' },
  { id: 979, postal: '5650', name: 'TYSSE' },
  { id: 980, postal: '5651', name: 'TYSSE' },
  { id: 981, postal: '5682', name: 'GODØYSUND' },
  { id: 982, postal: '5700', name: 'VOSS' },
  { id: 983, postal: '5704', name: 'VOSS' },
  { id: 984, postal: '5705', name: 'VOSS' },
  { id: 985, postal: '5706', name: 'VOSS' },
  { id: 986, postal: '5708', name: 'VOSS' },
  { id: 987, postal: '5709', name: 'VOSS' },
  { id: 988, postal: '5710', name: 'SKULESTADMO' },
  { id: 989, postal: '5711', name: 'SKULESTADMO' },
  { id: 990, postal: '5714', name: 'VOSS' },
  { id: 991, postal: '5720', name: 'STANGHELLE' },
  { id: 992, postal: '5724', name: 'STANGHELLE' },
  { id: 993, postal: '5727', name: 'STAMNES' },
  { id: 994, postal: '5729', name: 'MODALEN' },
  { id: 995, postal: '5732', name: 'MODALEN' },
  { id: 996, postal: '5747', name: 'GUDVANGEN' },
  { id: 997, postal: '5749', name: 'GUDVANGEN' },
  { id: 998, postal: '5749', name: 'BAKKA' },
  { id: 999, postal: '5752', name: 'ODDA' },
  { id: 1000, postal: '5760', name: 'RØLDAL' },
  { id: 1001, postal: '5775', name: 'NÅ' },
  { id: 1002, postal: '5776', name: 'NÅ' },
  { id: 1003, postal: '5788', name: 'KINSARVIK' },
  { id: 1004, postal: '5802', name: 'BERGEN' },
  { id: 1005, postal: '5810', name: 'BERGEN' },
  { id: 1006, postal: '5813', name: 'BERGEN' },
  { id: 1007, postal: '5814', name: 'BERGEN' },
  { id: 1008, postal: '5820', name: 'BERGEN' },
  { id: 1009, postal: '5823', name: 'BERGEN' },
  { id: 1010, postal: '5826', name: 'BERGEN' },
  { id: 1011, postal: '5827', name: 'BERGEN' },
  { id: 1012, postal: '5828', name: 'BERGEN' },
  { id: 1013, postal: '5830', name: 'BERGEN' },
  { id: 1014, postal: '5831', name: 'BERGEN' },
  { id: 1015, postal: '5832', name: 'BERGEN' },
  { id: 1016, postal: '5833', name: 'BERGEN' },
  { id: 1017, postal: '5834', name: 'BERGEN' },
  { id: 1018, postal: '5837', name: 'BERGEN' },
  { id: 1019, postal: '5841', name: 'BERGEN' },
  { id: 1020, postal: '5843', name: 'BERGEN' },
  { id: 1021, postal: '5844', name: 'BERGEN' },
  { id: 1022, postal: '5846', name: 'BERGEN' },
  { id: 1023, postal: '5855', name: 'BERGEN' },
  { id: 1024, postal: '5856', name: 'BERGEN' },
  { id: 1025, postal: '5865', name: 'BERGEN' },
  { id: 1026, postal: '5866', name: 'BERGEN' },
  { id: 1027, postal: '5867', name: 'BERGEN' },
  { id: 1028, postal: '5871', name: 'BERGEN' },
  { id: 1029, postal: '5874', name: 'BERGEN' },
  { id: 1030, postal: '5882', name: 'BERGEN' },
  { id: 1031, postal: '5883', name: 'BERGEN' },
  { id: 1032, postal: '5887', name: 'BERGEN' },
  { id: 1033, postal: '5895', name: 'BERGEN' },
  { id: 1034, postal: '5907', name: 'ALVERSUND' },
  { id: 1035, postal: '5908', name: 'ISDALSTØ' },
  { id: 1036, postal: '5916', name: 'ISDALSTØ' },
  { id: 1037, postal: '5917', name: 'ROSSLAND' },
  { id: 1038, postal: '5919', name: 'FREKHAUG' },
  { id: 1039, postal: '5935', name: 'BØVÅGEN' },
  { id: 1040, postal: '5937', name: 'BØVÅGEN' },
  { id: 1041, postal: '5938', name: 'SÆBØVÅGEN' },
  { id: 1042, postal: '5952', name: 'FONNES' },
  { id: 1043, postal: '5953', name: 'FONNES' },
  { id: 1044, postal: '5956', name: 'VÅGSEIDET' },
  { id: 1045, postal: '5957', name: 'MYKING' },
  { id: 1046, postal: '5960', name: 'DALSØYRA' },
  { id: 1047, postal: '5961', name: 'BREKKE' },
  { id: 1048, postal: '5962', name: 'BJORDAL' },
  { id: 1049, postal: '5963', name: 'DALSØYRA' },
  { id: 1050, postal: '5964', name: 'BREKKE' },
  { id: 1051, postal: '5965', name: 'BJORDAL' },
  { id: 1052, postal: '5982', name: 'MASFJORDNES' },
  { id: 1053, postal: '5983', name: 'HAUGSVÆR' },
  { id: 1054, postal: '5985', name: 'HAUGSVÆR' },
  { id: 1055, postal: '6025', name: 'ÅLESUND' },
  { id: 1056, postal: '6026', name: 'ÅLESUND' },
  { id: 1057, postal: '6027', name: 'ÅLESUND' },
  { id: 1058, postal: '6029', name: 'ÅLESUND' },
  { id: 1059, postal: '6034', name: 'EIDSNES' },
  { id: 1060, postal: '6037', name: 'EIDSNES' },
  { id: 1061, postal: '6043', name: 'ÅLESUND' },
  { id: 1062, postal: '6044', name: 'ÅLESUND' },
  { id: 1063, postal: '6047', name: 'ÅLESUND' },
  { id: 1064, postal: '6048', name: 'ÅLESUND' },
  { id: 1065, postal: '6050', name: 'VALDERØY' },
  { id: 1066, postal: '6051', name: 'VALDERØYA' },
  { id: 1067, postal: '6051', name: 'VALDERØY' },
  { id: 1068, postal: '6054', name: 'GODØYA' },
  { id: 1069, postal: '6055', name: 'GODØYA' },
  { id: 1070, postal: '6055', name: 'GODØY' },
  { id: 1071, postal: '6058', name: 'VALDERØY' },
  { id: 1072, postal: '6063', name: 'HJØRUNGAVÅG' },
  { id: 1073, postal: '6064', name: 'HADDAL' },
  { id: 1074, postal: '6068', name: 'EIKSUND' },
  { id: 1075, postal: '6075', name: 'MOLTUSTRANDA' },
  { id: 1076, postal: '6076', name: 'MOLTUSTRANDA' },
  { id: 1077, postal: '6076', name: 'MOLDTUSTRANDA' },
  { id: 1078, postal: '6078', name: 'GJERDSVIKA' },
  { id: 1079, postal: '6079', name: 'GURSKØY' },
  { id: 1080, postal: '6080', name: 'GURSKØY' },
  { id: 1081, postal: '6083', name: 'GJERDSVIKA' },
  { id: 1082, postal: '6086', name: 'KVAMSØY' },
  { id: 1083, postal: '6087', name: 'KVAMSØY' },
  { id: 1084, postal: '6088', name: 'SANDSHAMN' },
  { id: 1085, postal: '6089', name: 'SANDSHAMN' },
  { id: 1086, postal: '6091', name: 'FOSNAVÅG' },
  { id: 1087, postal: '6092', name: 'EGGESBØNES' },
  { id: 1088, postal: '6102', name: 'VOLDA' },
  { id: 1089, postal: '6103', name: 'VOLDA' },
  { id: 1090, postal: '6104', name: 'VOLDA' },
  { id: 1091, postal: '6105', name: 'VOLDA' },
  { id: 1092, postal: '6106', name: 'VOLDA' },
  { id: 1093, postal: '6133', name: 'LAUVSTAD' },
  { id: 1094, postal: '6134', name: 'LAUVSTAD' },
  { id: 1095, postal: '6138', name: 'SYVDE' },
  { id: 1096, postal: '6139', name: 'FISKÅBYGD' },
  { id: 1097, postal: '6140', name: 'SYVDE' },
  { id: 1098, postal: '6143', name: 'FISKÅBYGD' },
  { id: 1099, postal: '6146', name: 'ÅHEIM' },
  { id: 1100, postal: '6147', name: 'ÅHEIM' },
  { id: 1101, postal: '6152', name: 'ØRSTA' },
  { id: 1102, postal: '6153', name: 'ØRSTA' },
  { id: 1103, postal: '6154', name: 'ØRSTA' },
  { id: 1104, postal: '6155', name: 'ØRSTA' },
  { id: 1105, postal: '6156', name: 'ØRSTA' },
  { id: 1106, postal: '6170', name: 'VARTDAL' },
  { id: 1107, postal: '6171', name: 'VARTDAL' },
  { id: 1108, postal: '6210', name: 'VALLDAL' },
  { id: 1109, postal: '6211', name: 'VALLDAL' },
  { id: 1110, postal: '6215', name: 'EIDSDAL' },
  { id: 1111, postal: '6216', name: 'GEIRANGER' },
  { id: 1112, postal: '6217', name: 'GEIRANGER' },
  { id: 1113, postal: '6217', name: 'DJUPEVATN' },
  { id: 1114, postal: '6218', name: 'HELLESYLT' },
  { id: 1115, postal: '6219', name: 'HELLESYLT' },
  { id: 1116, postal: '6222', name: 'IKORNNES' },
  { id: 1117, postal: '6223', name: 'IKORNNES' },
  { id: 1118, postal: '6255', name: 'EIDSDAL' },
  { id: 1119, postal: '6290', name: 'HARAMSØY' },
  { id: 1120, postal: '6291', name: 'HARAMSØY' },
  { id: 1121, postal: '6309', name: 'ÅNDALSNES LEIRPLASS' },
  { id: 1122, postal: '6330', name: 'VERMA' },
  { id: 1123, postal: '6331', name: 'VERMA' },
  { id: 1124, postal: '6335', name: 'TROLLSTIGEN' },
  { id: 1125, postal: '6360', name: 'ÅFARNES' },
  { id: 1126, postal: '6361', name: 'ÅFARNES' },
  { id: 1127, postal: '6364', name: 'VISTDAL' },
  { id: 1128, postal: '6365', name: 'VISTDAL' },
  { id: 1129, postal: '6385', name: 'MÅNDALEN' },
  { id: 1130, postal: '6386', name: 'MÅNDALEN' },
  { id: 1131, postal: '6387', name: 'VÅGSTRANDA' },
  { id: 1132, postal: '6388', name: 'VÅGSTRANDA' },
  { id: 1133, postal: '6389', name: 'FIKSDAL' },
  { id: 1134, postal: '6394', name: 'FIKSDAL' },
  { id: 1135, postal: '6404', name: 'MOLDE' },
  { id: 1136, postal: '6405', name: 'MOLDE' },
  { id: 1137, postal: '6406', name: 'MOLDE' },
  { id: 1138, postal: '6407', name: 'MOLDE' },
  { id: 1139, postal: '6410', name: 'MOLDE' },
  { id: 1140, postal: '6423', name: 'MOLDE' },
  { id: 1141, postal: '6430', name: 'BUD' },
  { id: 1142, postal: '6431', name: 'BUD' },
  { id: 1143, postal: '6433', name: 'HUSTAD' },
  { id: 1144, postal: '6434', name: 'MOLDE' },
  { id: 1145, postal: '6435', name: 'MOLDE' },
  { id: 1146, postal: '6436', name: 'MOLDE' },
  { id: 1147, postal: '6444', name: 'FARSTAD' },
  { id: 1148, postal: '6446', name: 'FARSTAD' },
  { id: 1149, postal: '6449', name: 'FARSTAD' },
  { id: 1150, postal: '6452', name: 'KLEIVE' },
  { id: 1151, postal: '6453', name: 'KLEIVE' },
  { id: 1152, postal: '6456', name: 'SKÅLA' },
  { id: 1153, postal: '6458', name: 'SKÅLA' },
  { id: 1154, postal: '6460', name: 'EIDSVÅG I ROMSDAL' },
  { id: 1155, postal: '6461', name: 'EIDSVÅG I ROMSDAL' },
  { id: 1156, postal: '6470', name: 'ERESFJORD' },
  { id: 1157, postal: '6471', name: 'ERESFJORD' },
  { id: 1158, postal: '6472', name: 'EIKESDAL' },
  { id: 1159, postal: '6484', name: 'SANDØY' },
  { id: 1160, postal: '6485', name: 'HARØY' },
  { id: 1161, postal: '6487', name: 'HARØY' },
  { id: 1162, postal: '6504', name: 'KRISTIANSUND N' },
  { id: 1163, postal: '6505', name: 'KRISTIANSUND N' },
  { id: 1164, postal: '6506', name: 'KRISTIANSUND N' },
  { id: 1165, postal: '6516', name: 'KRISTIANSUND N' },
  { id: 1166, postal: '6520', name: 'FREI' },
  { id: 1167, postal: '6521', name: 'FREI' },
  { id: 1168, postal: '6522', name: 'FREI' },
  { id: 1169, postal: '6523', name: 'FREI' },
  { id: 1170, postal: '6524', name: 'FREI' },
  { id: 1171, postal: '6525', name: 'FREI' },
  { id: 1172, postal: '6527', name: 'FREI' },
  { id: 1173, postal: '6528', name: 'FREI' },
  { id: 1174, postal: '6530', name: 'AVERØY' },
  { id: 1175, postal: '6531', name: 'AVERØY' },
  { id: 1176, postal: '6532', name: 'AVERØY' },
  { id: 1177, postal: '6533', name: 'AVERØY' },
  { id: 1178, postal: '6546', name: 'KRISTIANSUND N' },
  { id: 1179, postal: '6547', name: 'KRISTIANSUND N' },
  { id: 1180, postal: '6548', name: 'KRISTIANSUND N' },
  { id: 1181, postal: '6549', name: 'KRISTIANSUND N' },
  { id: 1182, postal: '6590', name: 'TUSTNA' },
  { id: 1183, postal: '6591', name: 'TUSTNA' },
  { id: 1184, postal: '6613', name: 'GJØRA' },
  { id: 1185, postal: '6614', name: 'GJØRA' },
  { id: 1186, postal: '6620', name: 'ÅLVUNDEID' },
  { id: 1187, postal: '6622', name: 'ÅLVUNDFJORD' },
  { id: 1188, postal: '6623', name: 'ÅLVUNDFJORD' },
  { id: 1189, postal: '6627', name: 'TINGVOLL' },
  { id: 1190, postal: '6628', name: 'MEISINGSET' },
  { id: 1191, postal: '6630', name: 'TINGVOLL' },
  { id: 1192, postal: '6631', name: 'BATNFJORDSØRA' },
  { id: 1193, postal: '6632', name: 'BATNFJORDSØRA' },
  { id: 1194, postal: '6639', name: 'TORVIKBUKT' },
  { id: 1195, postal: '6641', name: 'TORVIKBUKT' },
  { id: 1196, postal: '6644', name: 'BÆVERFJORD' },
  { id: 1197, postal: '6645', name: 'TODALEN' },
  { id: 1198, postal: '6652', name: 'SURNA' },
  { id: 1199, postal: '6657', name: 'RINDAL' },
  { id: 1200, postal: '6658', name: 'RINDALSSKOGEN' },
  { id: 1201, postal: '6659', name: 'RINDAL' },
  { id: 1202, postal: '6670', name: 'ØYDEGARD' },
  { id: 1203, postal: '6671', name: 'ØYDEGARD' },
  { id: 1204, postal: '6706', name: 'MÅLØY' },
  { id: 1205, postal: '6728', name: 'KALVÅG' },
  { id: 1206, postal: '6729', name: 'KALVÅG' },
  { id: 1207, postal: '6731', name: 'DAVIK' },
  { id: 1208, postal: '6773', name: 'NORDFJORDEID' },
  { id: 1209, postal: '6774', name: 'NORDFJORDEID' },
  { id: 1210, postal: '6775', name: 'NORDFJORDEID' },
  { id: 1211, postal: '6785', name: 'STRYN' },
  { id: 1212, postal: '6789', name: 'LOEN' },
  { id: 1213, postal: '6790', name: 'LOEN' },
  { id: 1214, postal: '6793', name: 'INNVIK' },
  { id: 1215, postal: '6794', name: 'INNVIK' },
  { id: 1216, postal: '6800', name: 'FØRDE' },
  { id: 1217, postal: '6807', name: 'FØRDE' },
  { id: 1218, postal: '6808', name: 'FØRDE' },
  { id: 1219, postal: '6809', name: 'FØRDE' },
  { id: 1220, postal: '6810', name: 'FØRDE' },
  { id: 1221, postal: '6811', name: 'FØRDE' },
  { id: 1222, postal: '6812', name: 'FØRDE' },
  { id: 1223, postal: '6813', name: 'FØRDE' },
  { id: 1224, postal: '6814', name: 'FØRDE' },
  { id: 1225, postal: '6815', name: 'FØRDE' },
  { id: 1226, postal: '6816', name: 'NAUSTDAL' },
  { id: 1227, postal: '6819', name: 'HOLSEN' },
  { id: 1228, postal: '6820', name: 'FØRDE' },
  { id: 1229, postal: '6824', name: 'SANDANE' },
  { id: 1230, postal: '6826', name: 'BYRKJELO' },
  { id: 1231, postal: '6829', name: 'HYEN' },
  { id: 1232, postal: '6830', name: 'BYRKJELO' },
  { id: 1233, postal: '6831', name: 'HYEN' },
  { id: 1234, postal: '6844', name: 'VASSENDEN' },
  { id: 1235, postal: '6845', name: 'FJÆRLAND' },
  { id: 1236, postal: '6847', name: 'VASSENDEN' },
  { id: 1237, postal: '6848', name: 'FJÆRLAND' },
  { id: 1238, postal: '6849', name: 'KAUPANGER' },
  { id: 1239, postal: '6854', name: 'KAUPANGER' },
  { id: 1240, postal: '6857', name: 'SOGNDAL' },
  { id: 1241, postal: '6867', name: 'HAFSLO' },
  { id: 1242, postal: '6869', name: 'HAFSLO' },
  { id: 1243, postal: '6872', name: 'LUSTER' },
  { id: 1244, postal: '6874', name: 'LUSTER' },
  { id: 1245, postal: '6883', name: 'ÅRDALSTANGEN' },
  { id: 1246, postal: '6888', name: 'STEINKLEPP' },
  { id: 1247, postal: '6900', name: 'FLORØ' },
  { id: 1248, postal: '6904', name: 'FLORØ' },
  { id: 1249, postal: '6905', name: 'FLORØ' },
  { id: 1250, postal: '6906', name: 'FLORØ' },
  { id: 1251, postal: '6907', name: 'FLORØ' },
  { id: 1252, postal: '6908', name: 'FLORØ' },
  { id: 1253, postal: '6909', name: 'FLORØ' },
  { id: 1254, postal: '6910', name: 'FLORØ' },
  { id: 1255, postal: '6913', name: 'FLORØ' },
  { id: 1256, postal: '6939', name: 'STAVANG' },
  { id: 1257, postal: '6958', name: 'SØRBØVÅG' },
  { id: 1258, postal: '6959', name: 'SØRBØVÅG' },
  { id: 1259, postal: '6968', name: 'FLEKKE' },
  { id: 1260, postal: '6976', name: 'BYGSTAD' },
  { id: 1261, postal: '6977', name: 'BYGSTAD' },
  { id: 1262, postal: '6981', name: 'HOLMEDAL' },
  { id: 1263, postal: '6983', name: 'KVAMMEN' },
  { id: 1264, postal: '6985', name: 'ATLØY' },
  { id: 1265, postal: '6994', name: 'NESSANE' },
  { id: 1266, postal: '6996', name: 'VADHEIM' },
  { id: 1267, postal: '6997', name: 'VADHEIM' },
  { id: 1268, postal: '7003', name: 'TRONDHEIM' },
  { id: 1269, postal: '7004', name: 'TRONDHEIM' },
  { id: 1270, postal: '7005', name: 'TRONDHEIM' },
  { id: 1271, postal: '7006', name: 'TRONDHEIM' },
  { id: 1272, postal: '7007', name: 'TRONDHEIM' },
  { id: 1273, postal: '7008', name: 'TRONDHEIM' },
  { id: 1274, postal: '7009', name: 'TRONDHEIM' },
  { id: 1275, postal: '7010', name: 'TRONDHEIM' },
  { id: 1276, postal: '7011', name: 'TRONDHEIM' },
  { id: 1277, postal: '7012', name: 'TRONDHEIM' },
  { id: 1278, postal: '7013', name: 'TRONDHEIM' },
  { id: 1279, postal: '7014', name: 'TRONDHEIM' },
  { id: 1280, postal: '7015', name: 'TRONDHEIM' },
  { id: 1281, postal: '7016', name: 'TRONDHEIM' },
  { id: 1282, postal: '7017', name: 'TRONDHEIM' },
  { id: 1283, postal: '7018', name: 'TRONDHEIM' },
  { id: 1284, postal: '7019', name: 'TRONDHEIM' },
  { id: 1285, postal: '7020', name: 'TRONDHEIM' },
  { id: 1286, postal: '7021', name: 'TRONDHEIM' },
  { id: 1287, postal: '7022', name: 'TRONDHEIM' },
  { id: 1288, postal: '7023', name: 'TRONDHEIM' },
  { id: 1289, postal: '7024', name: 'TRONDHEIM' },
  { id: 1290, postal: '7025', name: 'TRONDHEIM' },
  { id: 1291, postal: '7026', name: 'TRONDHEIM' },
  { id: 1292, postal: '7027', name: 'TRONDHEIM' },
  { id: 1293, postal: '7028', name: 'TRONDHEIM' },
  { id: 1294, postal: '7029', name: 'TRONDHEIM' },
  { id: 1295, postal: '7030', name: 'TRONDHEIM' },
  { id: 1296, postal: '7031', name: 'TRONDHEIM' },
  { id: 1297, postal: '7032', name: 'TRONDHEIM' },
  { id: 1298, postal: '7033', name: 'TRONDHEIM' },
  { id: 1299, postal: '7034', name: 'TRONDHEIM' },
  { id: 1300, postal: '7035', name: 'TRONDHEIM' },
  { id: 1301, postal: '7036', name: 'TRONDHEIM' },
  { id: 1302, postal: '7037', name: 'TRONDHEIM' },
  { id: 1303, postal: '7038', name: 'TRONDHEIM' },
  { id: 1304, postal: '7039', name: 'TRONDHEIM' },
  { id: 1305, postal: '7040', name: 'TRONDHEIM' },
  { id: 1306, postal: '7041', name: 'TRONDHEIM' },
  { id: 1307, postal: '7042', name: 'TRONDHEIM' },
  { id: 1308, postal: '7043', name: 'TRONDHEIM' },
  { id: 1309, postal: '7044', name: 'TRONDHEIM' },
  { id: 1310, postal: '7045', name: 'TRONDHEIM' },
  { id: 1311, postal: '7046', name: 'TRONDHEIM' },
  { id: 1312, postal: '7047', name: 'TRONDHEIM' },
  { id: 1313, postal: '7048', name: 'TRONDHEIM' },
  { id: 1314, postal: '7049', name: 'TRONDHEIM' },
  { id: 1315, postal: '7050', name: 'TRONDHEIM' },
  { id: 1316, postal: '7051', name: 'TRONDHEIM' },
  { id: 1317, postal: '7052', name: 'TRONDHEIM' },
  { id: 1318, postal: '7053', name: 'RANHEIM' },
  { id: 1319, postal: '7054', name: 'RANHEIM' },
  { id: 1320, postal: '7055', name: 'RANHEIM' },
  { id: 1321, postal: '7056', name: 'RANHEIM' },
  { id: 1322, postal: '7057', name: 'JONSVATNET' },
  { id: 1323, postal: '7058', name: 'JAKOBSLI' },
  { id: 1324, postal: '7059', name: 'JAKOBSLI' },
  { id: 1325, postal: '7066', name: 'TRONDHEIM' },
  { id: 1326, postal: '7067', name: 'TRONDHEIM' },
  { id: 1327, postal: '7068', name: 'TRONDHEIM' },
  { id: 1328, postal: '7069', name: 'TRONDHEIM' },
  { id: 1329, postal: '7070', name: 'BOSBERG' },
  { id: 1330, postal: '7071', name: 'TRONDHEIM' },
  { id: 1331, postal: '7072', name: 'HEIMDAL' },
  { id: 1332, postal: '7074', name: 'SPONGDAL' },
  { id: 1333, postal: '7075', name: 'TILLER' },
  { id: 1334, postal: '7078', name: 'SAUPSTAD' },
  { id: 1335, postal: '7079', name: 'FLATÅSEN' },
  { id: 1336, postal: '7080', name: 'HEIMDAL' },
  { id: 1337, postal: '7081', name: 'SJETNEMARKA' },
  { id: 1338, postal: '7082', name: 'KATTEM' },
  { id: 1339, postal: '7083', name: 'LEINSTRAND' },
  { id: 1340, postal: '7088', name: 'HEIMDAL' },
  { id: 1341, postal: '7089', name: 'HEIMDAL' },
  { id: 1342, postal: '7091', name: 'TILLER' },
  { id: 1343, postal: '7092', name: 'TILLER' },
  { id: 1344, postal: '7093', name: 'TILLER' },
  { id: 1345, postal: '7097', name: 'SAUPSTAD' },
  { id: 1346, postal: '7098', name: 'SAUPSTAD' },
  { id: 1347, postal: '7099', name: 'FLATÅSEN' },
  { id: 1348, postal: '7100', name: 'RISSA' },
  { id: 1349, postal: '7101', name: 'RISSA' },
  { id: 1350, postal: '7105', name: 'STADSBYGD' },
  { id: 1351, postal: '7110', name: 'FEVÅG' },
  { id: 1352, postal: '7111', name: 'HASSELVIKA' },
  { id: 1353, postal: '7112', name: 'HASSELVIKA' },
  { id: 1354, postal: '7113', name: 'HUSBYSJØEN' },
  { id: 1355, postal: '7114', name: 'RÅKVÅG' },
  { id: 1356, postal: '7115', name: 'HUSBYSJØEN' },
  { id: 1357, postal: '7116', name: 'RÅKVÅG' },
  { id: 1358, postal: '7119', name: 'STADSBYGD' },
  { id: 1359, postal: '7120', name: 'LEKSVIK' },
  { id: 1360, postal: '7121', name: 'LEKSVIK' },
  { id: 1361, postal: '7125', name: 'VANVIKAN' },
  { id: 1362, postal: '7126', name: 'VANVIKAN' },
  { id: 1363, postal: '7127', name: 'OPPHAUG' },
  { id: 1364, postal: '7128', name: 'UTHAUG' },
  { id: 1365, postal: '7129', name: 'BREKSTAD' },
  { id: 1366, postal: '7130', name: 'BREKSTAD' },
  { id: 1367, postal: '7140', name: 'OPPHAUG' },
  { id: 1368, postal: '7142', name: 'UTHAUG' },
  { id: 1369, postal: '7150', name: 'STORFOSNA' },
  { id: 1370, postal: '7151', name: 'STORFOSNA' },
  { id: 1371, postal: '7152', name: 'KRÅKVÅG' },
  { id: 1372, postal: '7153', name: 'GARTEN' },
  { id: 1373, postal: '7156', name: 'LEKSA' },
  { id: 1374, postal: '7159', name: 'BJUGN' },
  { id: 1375, postal: '7160', name: 'BJUGN' },
  { id: 1376, postal: '7164', name: 'LYSØYSUNDET' },
  { id: 1377, postal: '7165', name: 'OKSVOLL' },
  { id: 1378, postal: '7166', name: 'TARVA' },
  { id: 1379, postal: '7167', name: 'VALLERSUND' },
  { id: 1380, postal: '7168', name: 'LYSØYSUNDET' },
  { id: 1381, postal: '7169', name: 'ÅFJORD' },
  { id: 1382, postal: '7170', name: 'ÅFJORD' },
  { id: 1383, postal: '7174', name: 'REVSNES' },
  { id: 1384, postal: '7175', name: 'STOKKØY' },
  { id: 1385, postal: '7176', name: 'LINESØYA' },
  { id: 1386, postal: '7177', name: 'REVSNES' },
  { id: 1387, postal: '7178', name: 'STOKKØY' },
  { id: 1388, postal: '7180', name: 'ROAN' },
  { id: 1389, postal: '7181', name: 'ROAN' },
  { id: 1390, postal: '7190', name: 'BESSAKER' },
  { id: 1391, postal: '7194', name: 'BRANDSFJORD' },
  { id: 1392, postal: '7200', name: 'KYRKSÆTERØRA' },
  { id: 1393, postal: '7201', name: 'KYRKSÆTERØRA' },
  { id: 1394, postal: '7203', name: 'VINJEØRA' },
  { id: 1395, postal: '7206', name: 'HELLANDSJØEN' },
  { id: 1396, postal: '7211', name: 'KORSVEGEN' },
  { id: 1397, postal: '7212', name: 'KORSVEGEN' },
  { id: 1398, postal: '7213', name: 'GÅSBAKKEN' },
  { id: 1399, postal: '7221', name: 'MELHUS' },
  { id: 1400, postal: '7223', name: 'MELHUS' },
  { id: 1401, postal: '7224', name: 'MELHUS' },
  { id: 1402, postal: '7227', name: 'GIMSE' },
  { id: 1403, postal: '7228', name: 'KVÅL' },
  { id: 1404, postal: '7229', name: 'KVÅL' },
  { id: 1405, postal: '7231', name: 'LUNDAMO' },
  { id: 1406, postal: '7232', name: 'LUNDAMO' },
  { id: 1407, postal: '7234', name: 'LER' },
  { id: 1408, postal: '7235', name: 'LER' },
  { id: 1409, postal: '7236', name: 'HOVIN I GAULDAL' },
  { id: 1410, postal: '7238', name: 'HOVIN I GAULDAL' },
  { id: 1411, postal: '7239', name: 'HITRA' },
  { id: 1412, postal: '7240', name: 'HITRA' },
  { id: 1413, postal: '7241', name: 'ANSNES' },
  { id: 1414, postal: '7242', name: 'KNARRLAGSUND' },
  { id: 1415, postal: '7243', name: 'KVENVÆR' },
  { id: 1416, postal: '7244', name: 'KNARRLAGSUND' },
  { id: 1417, postal: '7245', name: 'KVENVÆR' },
  { id: 1418, postal: '7246', name: 'SANDSTAD' },
  { id: 1419, postal: '7247', name: 'HESTVIKA' },
  { id: 1420, postal: '7250', name: 'MELANDSJØ' },
  { id: 1421, postal: '7252', name: 'DOLMØY' },
  { id: 1422, postal: '7255', name: 'SUNDLANDET' },
  { id: 1423, postal: '7256', name: 'HEMNSKJELA' },
  { id: 1424, postal: '7256', name: 'HEMNSKJEL' },
  { id: 1425, postal: '7257', name: 'SNILLFJORD' },
  { id: 1426, postal: '7259', name: 'SNILLFJORD' },
  { id: 1427, postal: '7260', name: 'SISTRANDA' },
  { id: 1428, postal: '7261', name: 'SISTRANDA' },
  { id: 1429, postal: '7263', name: 'HAMARVIK' },
  { id: 1430, postal: '7264', name: 'HAMARVIK' },
  { id: 1431, postal: '7266', name: 'KVERVA' },
  { id: 1432, postal: '7267', name: 'KVERVA' },
  { id: 1433, postal: '7268', name: 'TITRAN' },
  { id: 1434, postal: '7270', name: 'DYRVIK' },
  { id: 1435, postal: '7273', name: 'NORDDYRØY' },
  { id: 1436, postal: '7274', name: 'NORDDYRØY' },
  { id: 1437, postal: '7280', name: 'SULA' },
  { id: 1438, postal: '7282', name: 'BOGØYVÆR' },
  { id: 1439, postal: '7284', name: 'MAUSUND' },
  { id: 1440, postal: '7285', name: 'GJÆSINGEN' },
  { id: 1441, postal: '7286', name: 'SØRBURØY' },
  { id: 1442, postal: '7287', name: 'SAUØY' },
  { id: 1443, postal: '7288', name: 'SOKNEDAL' },
  { id: 1444, postal: '7289', name: 'SOKNEDAL' },
  { id: 1445, postal: '7290', name: 'STØREN' },
  { id: 1446, postal: '7291', name: 'STØREN' },
  { id: 1447, postal: '7295', name: 'ROGNES' },
  { id: 1448, postal: '7298', name: 'BUDALEN' },
  { id: 1449, postal: '7300', name: 'ORKANGER' },
  { id: 1450, postal: '7301', name: 'ORKANGER' },
  { id: 1451, postal: '7302', name: 'ORKANGER' },
  { id: 1452, postal: '7303', name: 'ORKANGER' },
  { id: 1453, postal: '7310', name: 'GJØLME' },
  { id: 1454, postal: '7315', name: 'LENSVIK' },
  { id: 1455, postal: '7316', name: 'LENSVIK' },
  { id: 1456, postal: '7318', name: 'AGDENES' },
  { id: 1457, postal: '7319', name: 'AGDENES' },
  { id: 1458, postal: '7320', name: 'FANNREM' },
  { id: 1459, postal: '7321', name: 'FANNREM' },
  { id: 1460, postal: '7327', name: 'SVORKMO' },
  { id: 1461, postal: '7329', name: 'SVORKMO' },
  { id: 1462, postal: '7331', name: 'LØKKEN VERK' },
  { id: 1463, postal: '7332', name: 'LØKKEN VERK' },
  { id: 1464, postal: '7333', name: 'STORÅS' },
  { id: 1465, postal: '7334', name: 'STORÅS' },
  { id: 1466, postal: '7335', name: 'JERPSTAD' },
  { id: 1467, postal: '7336', name: 'MELDAL' },
  { id: 1468, postal: '7338', name: 'MELDAL' },
  { id: 1469, postal: '7340', name: 'OPPDAL' },
  { id: 1470, postal: '7341', name: 'OPPDAL' },
  { id: 1471, postal: '7342', name: 'LØNSET' },
  { id: 1472, postal: '7343', name: 'VOGNILL' },
  { id: 1473, postal: '7344', name: 'GJEVILVASSHYTTA' },
  { id: 1474, postal: '7345', name: 'DRIVA' },
  { id: 1475, postal: '7350', name: 'BUVIKA' },
  { id: 1476, postal: '7351', name: 'BUVIKA' },
  { id: 1477, postal: '7353', name: 'BØRSA' },
  { id: 1478, postal: '7354', name: 'VIGGJA' },
  { id: 1479, postal: '7355', name: 'EGGKLEIVA' },
  { id: 1480, postal: '7356', name: 'SKAUN' },
  { id: 1481, postal: '7357', name: 'SKAUN' },
  { id: 1482, postal: '7358', name: 'BØRSA' },
  { id: 1483, postal: '7359', name: 'EGGKLEIVA' },
  { id: 1484, postal: '7361', name: 'RØROS' },
  { id: 1485, postal: '7366', name: 'RØROS' },
  { id: 1486, postal: '7370', name: 'BREKKEBYGD' },
  { id: 1487, postal: '7372', name: 'GLÅMOS' },
  { id: 1488, postal: '7374', name: 'RØROS' },
  { id: 1489, postal: '7375', name: 'RØROS' },
  { id: 1490, postal: '7380', name: 'ÅLEN' },
  { id: 1491, postal: '7383', name: 'HALTDALEN' },
  { id: 1492, postal: '7384', name: 'ÅLEN' },
  { id: 1493, postal: '7386', name: 'SINGSÅS' },
  { id: 1494, postal: '7387', name: 'SINGSÅS' },
  { id: 1495, postal: '7388', name: 'SINGSÅS' },
  { id: 1496, postal: '7391', name: 'RENNEBU' },
  { id: 1497, postal: '7392', name: 'RENNEBU' },
  { id: 1498, postal: '7393', name: 'RENNEBU' },
  { id: 1499, postal: '7397', name: 'RENNEBU' },
  { id: 1500, postal: '7398', name: 'RENNEBU' },
  { id: 1501, postal: '7399', name: 'RENNEBU' },
  { id: 1502, postal: '7400', name: 'TRONDHEIM' },
  { id: 1503, postal: '7401', name: 'TRONDHEIM' },
  { id: 1504, postal: '7402', name: 'TRONDHEIM' },
  { id: 1505, postal: '7403', name: 'TRONDHEIM' },
  { id: 1506, postal: '7404', name: 'TRONDHEIM' },
  { id: 1507, postal: '7405', name: 'TRONDHEIM' },
  { id: 1508, postal: '7406', name: 'TRONDHEIM' },
  { id: 1509, postal: '7407', name: 'TRONDHEIM' },
  { id: 1510, postal: '7408', name: 'TRONDHEIM' },
  { id: 1511, postal: '7409', name: 'TRONDHEIM' },
  { id: 1512, postal: '7410', name: 'TRONDHEIM' },
  { id: 1513, postal: '7411', name: 'TRONDHEIM' },
  { id: 1514, postal: '7412', name: 'TRONDHEIM' },
  { id: 1515, postal: '7413', name: 'TRONDHEIM' },
  { id: 1516, postal: '7414', name: 'TRONDHEIM' },
  { id: 1517, postal: '7415', name: 'TRONDHEIM' },
  { id: 1518, postal: '7416', name: 'TRONDHEIM' },
  { id: 1519, postal: '7417', name: 'TRONDHEIM' },
  { id: 1520, postal: '7418', name: 'TRONDHEIM' },
  { id: 1521, postal: '7419', name: 'TRONDHEIM' },
  { id: 1522, postal: '7420', name: 'TRONDHEIM' },
  { id: 1523, postal: '7421', name: 'TRONDHEIM' },
  { id: 1524, postal: '7422', name: 'TRONDHEIM' },
  { id: 1525, postal: '7423', name: 'TRONDHEIM' },
  { id: 1526, postal: '7424', name: 'TRONDHEIM' },
  { id: 1527, postal: '7425', name: 'TRONDHEIM' },
  { id: 1528, postal: '7426', name: 'TRONDHEIM' },
  { id: 1529, postal: '7427', name: 'TRONDHEIM' },
  { id: 1530, postal: '7428', name: 'TRONDHEIM' },
  { id: 1531, postal: '7429', name: 'TRONDHEIM' },
  { id: 1532, postal: '7430', name: 'TRONDHEIM' },
  { id: 1533, postal: '7431', name: 'TRONDHEIM' },
  { id: 1534, postal: '7432', name: 'TRONDHEIM' },
  { id: 1535, postal: '7433', name: 'TRONDHEIM' },
  { id: 1536, postal: '7434', name: 'TRONDHEIM' },
  { id: 1537, postal: '7435', name: 'TRONDHEIM' },
  { id: 1538, postal: '7436', name: 'TRONDHEIM' },
  { id: 1539, postal: '7437', name: 'TRONDHEIM' },
  { id: 1540, postal: '7438', name: 'TRONDHEIM' },
  { id: 1541, postal: '7439', name: 'TRONDHEIM' },
  { id: 1542, postal: '7440', name: 'TRONDHEIM' },
  { id: 1543, postal: '7441', name: 'TRONDHEIM' },
  { id: 1544, postal: '7442', name: 'TRONDHEIM' },
  { id: 1545, postal: '7443', name: 'TRONDHEIM' },
  { id: 1546, postal: '7444', name: 'TRONDHEIM' },
  { id: 1547, postal: '7445', name: 'TRONDHEIM' },
  { id: 1548, postal: '7446', name: 'TRONDHEIM' },
  { id: 1549, postal: '7447', name: 'TRONDHEIM' },
  { id: 1550, postal: '7448', name: 'TRONDHEIM' },
  { id: 1551, postal: '7449', name: 'TRONDHEIM' },
  { id: 1552, postal: '7450', name: 'TRONDHEIM' },
  { id: 1553, postal: '7451', name: 'TRONDHEIM' },
  { id: 1554, postal: '7452', name: 'TRONDHEIM' },
  { id: 1555, postal: '7453', name: 'TRONDHEIM' },
  { id: 1556, postal: '7454', name: 'TRONDHEIM' },
  { id: 1557, postal: '7455', name: 'TRONDHEIM' },
  { id: 1558, postal: '7456', name: 'TRONDHEIM' },
  { id: 1559, postal: '7457', name: 'TRONDHEIM' },
  { id: 1560, postal: '7458', name: 'TRONDHEIM' },
  { id: 1561, postal: '7459', name: 'TRONDHEIM' },
  { id: 1562, postal: '7461', name: 'TRONDHEIM' },
  { id: 1563, postal: '7462', name: 'TRONDHEIM' },
  { id: 1564, postal: '7463', name: 'TRONDHEIM' },
  { id: 1565, postal: '7464', name: 'TRONDHEIM' },
  { id: 1566, postal: '7465', name: 'TRONDHEIM' },
  { id: 1567, postal: '7466', name: 'TRONDHEIM' },
  { id: 1568, postal: '7467', name: 'TRONDHEIM' },
  { id: 1569, postal: '7468', name: 'TRONDHEIM' },
  { id: 1570, postal: '7469', name: 'TRONDHEIM' },
  { id: 1571, postal: '7470', name: 'TRONDHEIM' },
  { id: 1572, postal: '7471', name: 'TRONDHEIM' },
  { id: 1573, postal: '7472', name: 'TRONDHEIM' },
  { id: 1574, postal: '7473', name: 'TRONDHEIM' },
  { id: 1575, postal: '7474', name: 'TRONDHEIM' },
  { id: 1576, postal: '7475', name: 'TRONDHEIM' },
  { id: 1577, postal: '7476', name: 'TRONDHEIM' },
  { id: 1578, postal: '7477', name: 'TRONDHEIM' },
  { id: 1579, postal: '7478', name: 'TRONDHEIM' },
  { id: 1580, postal: '7479', name: 'TRONDHEIM' },
  { id: 1581, postal: '7480', name: 'TRONDHEIM' },
  { id: 1582, postal: '7481', name: 'TRONDHEIM' },
  { id: 1583, postal: '7482', name: 'TRONDHEIM' },
  { id: 1584, postal: '7483', name: 'TRONDHEIM' },
  { id: 1585, postal: '7484', name: 'TRONDHEIM' },
  { id: 1586, postal: '7485', name: 'TRONDHEIM' },
  { id: 1587, postal: '7486', name: 'TRONDHEIM' },
  { id: 1588, postal: '7487', name: 'TRONDHEIM' },
  { id: 1589, postal: '7488', name: 'TRONDHEIM' },
  { id: 1590, postal: '7489', name: 'TRONDHEIM' },
  { id: 1591, postal: '7490', name: 'TRONDHEIM' },
  { id: 1592, postal: '7491', name: 'TRONDHEIM' },
  { id: 1593, postal: '7492', name: 'TRONDHEIM' },
  { id: 1594, postal: '7493', name: 'TRONDHEIM' },
  { id: 1595, postal: '7494', name: 'TRONDHEIM' },
  { id: 1596, postal: '7495', name: 'TRONDHEIM' },
  { id: 1597, postal: '7496', name: 'TRONDHEIM' },
  { id: 1598, postal: '7497', name: 'TRONDHEIM' },
  { id: 1599, postal: '7498', name: 'TRONDHEIM' },
  { id: 1600, postal: '7499', name: 'TRONDHEIM' },
  { id: 1601, postal: '7500', name: 'STJØRDAL' },
  { id: 1602, postal: '7501', name: 'STJØRDAL' },
  { id: 1603, postal: '7502', name: 'STJØRDAL' },
  { id: 1604, postal: '7503', name: 'STJØRDAL' },
  { id: 1605, postal: '7504', name: 'STJØRDAL' },
  { id: 1606, postal: '7505', name: 'STJØRDAL' },
  { id: 1607, postal: '7506', name: 'STJØRDAL' },
  { id: 1608, postal: '7507', name: 'STJØRDAL' },
  { id: 1609, postal: '7508', name: 'STJØRDAL' },
  { id: 1610, postal: '7509', name: 'STJØRDAL' },
  { id: 1611, postal: '7510', name: 'SKATVAL' },
  { id: 1612, postal: '7511', name: 'SKATVAL' },
  { id: 1613, postal: '7512', name: 'STJØRDAL' },
  { id: 1614, postal: '7513', name: 'STJØRDAL' },
  { id: 1615, postal: '7514', name: 'STJØRDAL' },
  { id: 1616, postal: '7517', name: 'HELL' },
  { id: 1617, postal: '7519', name: 'ELVARLI' },
  { id: 1618, postal: '7520', name: 'HEGRA' },
  { id: 1619, postal: '7525', name: 'FLORNES' },
  { id: 1620, postal: '7529', name: 'HEGRA' },
  { id: 1621, postal: '7530', name: 'MERÅKER' },
  { id: 1622, postal: '7531', name: 'MERÅKER' },
  { id: 1623, postal: '7533', name: 'KOPPERÅ' },
  { id: 1624, postal: '7540', name: 'KLÆBU' },
  { id: 1625, postal: '7541', name: 'KLÆBU' },
  { id: 1626, postal: '7548', name: 'TANEM' },
  { id: 1627, postal: '7549', name: 'TANEM' },
  { id: 1628, postal: '7550', name: 'HOMMELVIK' },
  { id: 1629, postal: '7551', name: 'HOMMELVIK' },
  { id: 1630, postal: '7560', name: 'VIKHAMMER' },
  { id: 1631, postal: '7562', name: 'SAKSVIK' },
  { id: 1632, postal: '7562', name: 'HUNDHAMAREN' },
  { id: 1633, postal: '7563', name: 'MALVIK' },
  { id: 1634, postal: '7566', name: 'VIKHAMMER' },
  { id: 1635, postal: '7570', name: 'HELL' },
  { id: 1636, postal: '7580', name: 'SELBU' },
  { id: 1637, postal: '7581', name: 'SELBU' },
  { id: 1638, postal: '7583', name: 'SELBU' },
  { id: 1639, postal: '7584', name: 'SELBUSTRAND' },
  { id: 1640, postal: '7586', name: 'SELBUSTRAND' },
  { id: 1641, postal: '7590', name: 'TYDAL' },
  { id: 1642, postal: '7591', name: 'TYDAL' },
  { id: 1643, postal: '7596', name: 'FLAKNAN' },
  { id: 1644, postal: '7600', name: 'LEVANGER' },
  { id: 1645, postal: '7601', name: 'LEVANGER' },
  { id: 1646, postal: '7602', name: 'LEVANGER' },
  { id: 1647, postal: '7603', name: 'LEVANGER' },
  { id: 1648, postal: '7604', name: 'LEVANGER' },
  { id: 1649, postal: '7605', name: 'LEVANGER' },
  { id: 1650, postal: '7606', name: 'LEVANGER' },
  { id: 1651, postal: '7607', name: 'LEVANGER' },
  { id: 1652, postal: '7608', name: 'LEVANGER' },
  { id: 1653, postal: '7609', name: 'LEVANGER' },
  { id: 1654, postal: '7610', name: 'LEVANGER' },
  { id: 1655, postal: '7619', name: 'SKOGN' },
  { id: 1656, postal: '7620', name: 'SKOGN' },
  { id: 1657, postal: '7622', name: 'MARKABYGDA' },
  { id: 1658, postal: '7623', name: 'RONGLAN' },
  { id: 1659, postal: '7624', name: 'EKNE' },
  { id: 1660, postal: '7629', name: 'YTTERØY' },
  { id: 1661, postal: '7630', name: 'ÅSEN' },
  { id: 1662, postal: '7631', name: 'ÅSEN' },
  { id: 1663, postal: '7632', name: 'ÅSENFJORD' },
  { id: 1664, postal: '7633', name: 'FROSTA' },
  { id: 1665, postal: '7634', name: 'FROSTA' },
  { id: 1666, postal: '7650', name: 'VERDAL' },
  { id: 1667, postal: '7651', name: 'VERDAL' },
  { id: 1668, postal: '7652', name: 'VERDAL' },
  { id: 1669, postal: '7653', name: 'VERDAL' },
  { id: 1670, postal: '7654', name: 'VERDAL' },
  { id: 1671, postal: '7655', name: 'VERDAL' },
  { id: 1672, postal: '7656', name: 'VERDAL' },
  { id: 1673, postal: '7657', name: 'VERDAL' },
  { id: 1674, postal: '7658', name: 'VERDAL' },
  { id: 1675, postal: '7659', name: 'VERDAL' },
  { id: 1676, postal: '7660', name: 'VUKU' },
  { id: 1677, postal: '7661', name: 'VUKU' },
  { id: 1678, postal: '7663', name: 'STIKLESTAD' },
  { id: 1679, postal: '7670', name: 'INDERØY' },
  { id: 1680, postal: '7671', name: 'INDERØY' },
  { id: 1681, postal: '7672', name: 'INDERØY' },
  { id: 1682, postal: '7690', name: 'MOSVIK' },
  { id: 1683, postal: '7691', name: 'MOSVIK' },
  { id: 1684, postal: '7701', name: 'STEINKJER' },
  { id: 1685, postal: '7702', name: 'STEINKJER' },
  { id: 1686, postal: '7703', name: 'STEINKJER' },
  { id: 1687, postal: '7704', name: 'STEINKJER' },
  { id: 1688, postal: '7705', name: 'STEINKJER' },
  { id: 1689, postal: '7706', name: 'STEINKJER' },
  { id: 1690, postal: '7707', name: 'STEINKJER' },
  { id: 1691, postal: '7708', name: 'STEINKJER' },
  { id: 1692, postal: '7709', name: 'STEINKJER' },
  { id: 1693, postal: '7710', name: 'SPARBU' },
  { id: 1694, postal: '7711', name: 'STEINKJER' },
  { id: 1695, postal: '7712', name: 'STEINKJER' },
  { id: 1696, postal: '7713', name: 'STEINKJER' },
  { id: 1697, postal: '7714', name: 'STEINKJER' },
  { id: 1698, postal: '7715', name: 'STEINKJER' },
  { id: 1699, postal: '7716', name: 'STEINKJER' },
  { id: 1700, postal: '7717', name: 'STEINKJER' },
  { id: 1701, postal: '7718', name: 'STEINKJER' },
  { id: 1702, postal: '7724', name: 'STEINKJER' },
  { id: 1703, postal: '7725', name: 'STEINKJER' },
  { id: 1704, postal: '7726', name: 'STEINKJER' },
  { id: 1705, postal: '7728', name: 'STEINKJER' },
  { id: 1706, postal: '7729', name: 'STEINKJER' },
  { id: 1707, postal: '7730', name: 'BEITSTAD' },
  { id: 1708, postal: '7732', name: 'STEINKJER' },
  { id: 1709, postal: '7733', name: 'SPARBU' },
  { id: 1710, postal: '7734', name: 'STEINKJER' },
  { id: 1711, postal: '7735', name: 'STEINKJER' },
  { id: 1712, postal: '7736', name: 'STEINKJER' },
  { id: 1713, postal: '7737', name: 'STEINKJER' },
  { id: 1714, postal: '7738', name: 'STEINKJER' },
  { id: 1715, postal: '7739', name: 'BEITSTAD' },
  { id: 1716, postal: '7740', name: 'STEINSDALEN' },
  { id: 1717, postal: '7741', name: 'STEINSDALEN' },
  { id: 1718, postal: '7742', name: 'YTTERVÅG' },
  { id: 1719, postal: '7744', name: 'HEPSØY' },
  { id: 1720, postal: '7745', name: 'OPPLAND' },
  { id: 1721, postal: '7746', name: 'HASVÅG' },
  { id: 1722, postal: '7748', name: 'SÆTERVIK' },
  { id: 1723, postal: '7750', name: 'NAMDALSEID' },
  { id: 1724, postal: '7751', name: 'NAMDALSEID' },
  { id: 1725, postal: '7760', name: 'SNÅSA' },
  { id: 1726, postal: '7761', name: 'SNÅSA' },
  { id: 1727, postal: '7770', name: 'FLATANGER' },
  { id: 1728, postal: '7771', name: 'FLATANGER' },
  { id: 1729, postal: '7777', name: 'NORD-STATLAND' },
  { id: 1730, postal: '7790', name: 'MALM' },
  { id: 1731, postal: '7791', name: 'MALM' },
  { id: 1732, postal: '7795', name: 'FOLLAFOSS' },
  { id: 1733, postal: '7796', name: 'FOLLAFOSS' },
  { id: 1734, postal: '7797', name: 'VERRABOTN' },
  { id: 1735, postal: '7800', name: 'NAMSOS' },
  { id: 1736, postal: '7801', name: 'NAMSOS' },
  { id: 1737, postal: '7802', name: 'NAMSOS' },
  { id: 1738, postal: '7803', name: 'NAMSOS' },
  { id: 1739, postal: '7804', name: 'NAMSOS' },
  { id: 1740, postal: '7805', name: 'NAMSOS' },
  { id: 1741, postal: '7808', name: 'NAMSOS' },
  { id: 1742, postal: '7809', name: 'NAMSOS' },
  { id: 1743, postal: '7810', name: 'NAMSOS' },
  { id: 1744, postal: '7817', name: 'SALSNES' },
  { id: 1745, postal: '7818', name: 'LUND' },
  { id: 1746, postal: '7819', name: 'FOSSLANDSOSEN' },
  { id: 1747, postal: '7820', name: 'SPILLUM' },
  { id: 1748, postal: '7821', name: 'SPILLUM' },
  { id: 1749, postal: '7822', name: 'BANGSUND' },
  { id: 1750, postal: '7823', name: 'BANGSUND' },
  { id: 1751, postal: '7855', name: 'JØA' },
  { id: 1752, postal: '7856', name: 'JØA' },
  { id: 1753, postal: '7860', name: 'SKAGE I NAMDALEN' },
  { id: 1754, postal: '7863', name: 'OVERHALLA' },
  { id: 1755, postal: '7864', name: 'OVERHALLA' },
  { id: 1756, postal: '7869', name: 'SKAGE I NAMDALEN' },
  { id: 1757, postal: '7870', name: 'GRONG' },
  { id: 1758, postal: '7871', name: 'GRONG' },
  { id: 1759, postal: '7873', name: 'HARRAN' },
  { id: 1760, postal: '7874', name: 'HARRAN' },
  { id: 1761, postal: '7878', name: 'HØYLANDET' },
  { id: 1762, postal: '7881', name: 'NORDLI' },
  { id: 1763, postal: '7882', name: 'NORDLI' },
  { id: 1764, postal: '7884', name: 'SØRLI' },
  { id: 1765, postal: '7885', name: 'SØRLI' },
  { id: 1766, postal: '7890', name: 'NAMSSKOGAN' },
  { id: 1767, postal: '7891', name: 'NAMSSKOGAN' },
  { id: 1768, postal: '7892', name: 'TRONES' },
  { id: 1769, postal: '7893', name: 'SKOROVATN' },
  { id: 1770, postal: '7896', name: 'BREKKVASSELV' },
  { id: 1771, postal: '7897', name: 'LIMINGEN' },
  { id: 1772, postal: '7898', name: 'LIMINGEN' },
  { id: 1773, postal: '7900', name: 'RØRVIK' },
  { id: 1774, postal: '7901', name: 'RØRVIK' },
  { id: 1775, postal: '7902', name: 'RØRVIK' },
  { id: 1776, postal: '7940', name: 'OTTERSØY' },
  { id: 1777, postal: '7941', name: 'OTTERSØY' },
  { id: 1778, postal: '7944', name: 'INDRE NÆRØY' },
  { id: 1779, postal: '7950', name: 'ABELVÆR' },
  { id: 1780, postal: '7960', name: 'SALSBRUKET' },
  { id: 1781, postal: '7970', name: 'KOLVEREID' },
  { id: 1782, postal: '7971', name: 'KOLVEREID' },
  { id: 1783, postal: '7973', name: 'GJERDINGA' },
  { id: 1784, postal: '7976', name: 'KONGSMOEN' },
  { id: 1785, postal: '7977', name: 'HØYLANDET' },
  { id: 1786, postal: '7979', name: 'TERRÅK' },
  { id: 1787, postal: '7980', name: 'TERRÅK' },
  { id: 1788, postal: '7982', name: 'BINDALSEIDET' },
  { id: 1789, postal: '7983', name: 'BINDALSEIDET' },
  { id: 1790, postal: '7985', name: 'FOLDEREID' },
  { id: 1791, postal: '7986', name: 'FOLDEREID' },
  { id: 1792, postal: '7990', name: 'NAUSTBUKTA' },
  { id: 1793, postal: '7993', name: 'GUTVIK' },
  { id: 1794, postal: '7994', name: 'LEKA' },
  { id: 1795, postal: '7995', name: 'LEKA' },
  { id: 1796, postal: '8002', name: 'BODØ' },
  { id: 1797, postal: '8030', name: 'BODØ' },
  { id: 1798, postal: '8032', name: 'BODØ' },
  { id: 1799, postal: '8037', name: 'BODØ' },
  { id: 1800, postal: '8038', name: 'BODØ' },
  { id: 1801, postal: '8039', name: 'BODØ' },
  { id: 1802, postal: '8041', name: 'BODØ' },
  { id: 1803, postal: '8047', name: 'BODØ' },
  { id: 1804, postal: '8048', name: 'BODØ' },
  { id: 1805, postal: '8049', name: 'BODØ' },
  { id: 1806, postal: '8056', name: 'SALTSTRAUMEN' },
  { id: 1807, postal: '8057', name: 'SALTSTRAUMEN' },
  { id: 1808, postal: '8062', name: 'VÆRØY' },
  { id: 1809, postal: '8063', name: 'VÆRØY' },
  { id: 1810, postal: '8064', name: 'RØST' },
  { id: 1811, postal: '8065', name: 'RØST' },
  { id: 1812, postal: '8070', name: 'BODØ' },
  { id: 1813, postal: '8071', name: 'BODØ' },
  { id: 1814, postal: '8072', name: 'BODØ' },
  { id: 1815, postal: '8073', name: 'BODØ' },
  { id: 1816, postal: '8074', name: 'BODØ' },
  { id: 1817, postal: '8075', name: 'BODØ' },
  { id: 1818, postal: '8076', name: 'BODØ' },
  { id: 1819, postal: '8079', name: 'BODØ' },
  { id: 1820, postal: '8092', name: 'BODØ' },
  { id: 1821, postal: '8093', name: 'KJERRINGØY' },
  { id: 1822, postal: '8134', name: 'SØRARNØY' },
  { id: 1823, postal: '8135', name: 'SØRARNØY' },
  { id: 1824, postal: '8171', name: 'SVARTISEN GÅRD' },
  { id: 1825, postal: '8178', name: 'HALSA' },
  { id: 1826, postal: '8179', name: 'HALSA' },
  { id: 1827, postal: '8181', name: 'MYKEN' },
  { id: 1828, postal: '8183', name: 'VÅGAHOLMEN' },
  { id: 1829, postal: '8185', name: 'VÅGAHOLMEN' },
  { id: 1830, postal: '8195', name: 'GJERØY' },
  { id: 1831, postal: '8196', name: 'SELSØYVIK' },
  { id: 1832, postal: '8203', name: 'FAUSKE' },
  { id: 1833, postal: '8205', name: 'FAUSKE' },
  { id: 1834, postal: '8206', name: 'FAUSKE' },
  { id: 1835, postal: '8207', name: 'FAUSKE' },
  { id: 1836, postal: '8208', name: 'FAUSKE' },
  { id: 1837, postal: '8209', name: 'FAUSKE' },
  { id: 1838, postal: '8210', name: 'FAUSKE' },
  { id: 1839, postal: '8211', name: 'FAUSKE' },
  { id: 1840, postal: '8214', name: 'FAUSKE' },
  { id: 1841, postal: '8218', name: 'FAUSKE' },
  { id: 1842, postal: '8219', name: 'FAUSKE' },
  { id: 1843, postal: '8231', name: 'SULITJELMA' },
  { id: 1844, postal: '8252', name: 'ROGNAN' },
  { id: 1845, postal: '8253', name: 'ROGNAN' },
  { id: 1846, postal: '8287', name: 'ENGELØYA' },
  { id: 1847, postal: '8289', name: 'ENGELØYA' },
  { id: 1848, postal: '8289', name: 'VÅG' },
  { id: 1849, postal: '8290', name: 'SKUTVIK' },
  { id: 1850, postal: '8291', name: 'SKUTVIK' },
  { id: 1851, postal: '8305', name: 'SVOLVÆR' },
  { id: 1852, postal: '8328', name: 'STOREMOLLA' },
  { id: 1853, postal: '8357', name: 'VALBERG' },
  { id: 1854, postal: '8360', name: 'BØSTAD' },
  { id: 1855, postal: '8361', name: 'BØSTAD' },
  { id: 1856, postal: '8373', name: 'BALLSTAD' },
  { id: 1857, postal: '8374', name: 'BALLSTAD' },
  { id: 1858, postal: '8375', name: 'LEKNES' },
  { id: 1859, postal: '8402', name: 'SORTLAND' },
  { id: 1860, postal: '8403', name: 'SORTLAND' },
  { id: 1861, postal: '8404', name: 'SORTLAND' },
  { id: 1862, postal: '8405', name: 'SORTLAND' },
  { id: 1863, postal: '8406', name: 'SORTLAND' },
  { id: 1864, postal: '8407', name: 'GODFJORD' },
  { id: 1865, postal: '8408', name: 'SANDSET' },
  { id: 1866, postal: '8415', name: 'SORTLAND' },
  { id: 1867, postal: '8415', name: 'HENNES' },
  { id: 1868, postal: '8416', name: 'SORTLAND' },
  { id: 1869, postal: '8419', name: 'SORTLAND' },
  { id: 1870, postal: '8446', name: 'MELBU' },
  { id: 1871, postal: '8448', name: 'MYRLAND' },
  { id: 1872, postal: '8450', name: 'STOKMARKNES' },
  { id: 1873, postal: '8452', name: 'STOKMARKNES' },
  { id: 1874, postal: '8487', name: 'BØGARD' },
  { id: 1875, postal: '8511', name: 'NARVIK' },
  { id: 1876, postal: '8512', name: 'NARVIK' },
  { id: 1877, postal: '8513', name: 'ANKENESSTRAND' },
  { id: 1878, postal: '8518', name: 'NARVIK' },
  { id: 1879, postal: '8519', name: 'NARVIK' },
  { id: 1880, postal: '8520', name: 'ANKENESSTRAND' },
  { id: 1881, postal: '8521', name: 'ANKENES' },
  { id: 1882, postal: '8523', name: 'ELVEGÅRD' },
  { id: 1883, postal: '8523', name: 'ELVEGARD' },
  { id: 1884, postal: '8542', name: 'KJELDEBOTN' },
  { id: 1885, postal: '8550', name: 'LØDINGEN' },
  { id: 1886, postal: '8551', name: 'LØDINGEN' },
  { id: 1887, postal: '8581', name: 'VESTBYGD' },
  { id: 1888, postal: '8587', name: 'STORÅ' },
  { id: 1889, postal: '8603', name: 'GRUBHEI' },
  { id: 1890, postal: '8604', name: 'SELFORS' },
  { id: 1891, postal: '8605', name: 'MO I RANA' },
  { id: 1892, postal: '8606', name: 'MO I RANA' },
  { id: 1893, postal: '8607', name: 'MO I RANA' },
  { id: 1894, postal: '8608', name: 'MO I RANA' },
  { id: 1895, postal: '8609', name: 'MO I RANA' },
  { id: 1896, postal: '8609', name: 'YTTEREN' },
  { id: 1897, postal: '8610', name: 'GRUBHEI' },
  { id: 1898, postal: '8611', name: 'MO I RANA' },
  { id: 1899, postal: '8613', name: 'SELFORS' },
  { id: 1900, postal: '8614', name: 'YTTEREN' },
  { id: 1901, postal: '8616', name: 'MO I RANA' },
  { id: 1902, postal: '8616', name: 'BÅSMOEN' },
  { id: 1903, postal: '8617', name: 'DALSELV' },
  { id: 1904, postal: '8618', name: 'MO I RANA' },
  { id: 1905, postal: '8618', name: 'ANDFISKÅ' },
  { id: 1906, postal: '8619', name: 'MO I RANA' },
  { id: 1907, postal: '8628', name: 'MO I RANA' },
  { id: 1908, postal: '8629', name: 'SVARTISDAL' },
  { id: 1909, postal: '8633', name: 'NISSELAND' },
  { id: 1910, postal: '8634', name: 'MO I RANA' },
  { id: 1911, postal: '8635', name: 'POLARSIRKELEN' },
  { id: 1912, postal: '8642', name: 'FINNEIDFJORD' },
  { id: 1913, postal: '8643', name: 'BJERKA' },
  { id: 1914, postal: '8644', name: 'BJERKA' },
  { id: 1915, postal: '8652', name: 'MOSJØEN' },
  { id: 1916, postal: '8654', name: 'MOSJØEN' },
  { id: 1917, postal: '8655', name: 'MOSJØEN' },
  { id: 1918, postal: '8659', name: 'MOSJØEN' },
  { id: 1919, postal: '8660', name: 'MOSJØEN' },
  { id: 1920, postal: '8679', name: 'SUNDØY' },
  { id: 1921, postal: '8726', name: 'UTSKARPEN' },
  { id: 1922, postal: '8754', name: 'ØRESVIK' },
  { id: 1923, postal: '8761', name: 'SLENESET' },
  { id: 1924, postal: '8762', name: 'SLENESET' },
  { id: 1925, postal: '8802', name: 'SANDNESSJØEN' },
  { id: 1926, postal: '8803', name: 'SANDNESSJØEN' },
  { id: 1927, postal: '8804', name: 'SANDNESSJØEN' },
  { id: 1928, postal: '8805', name: 'SANDNESSJØEN' },
  { id: 1929, postal: '8809', name: 'SANDNESSJØEN' },
  { id: 1930, postal: '8813', name: 'KOPARDAL' },
  { id: 1931, postal: '8826', name: 'NORDØYVÅGEN' },
  { id: 1932, postal: '8830', name: 'VANDVE' },
  { id: 1933, postal: '8842', name: 'BRASØY' },
  { id: 1934, postal: '8860', name: 'TJØTTA' },
  { id: 1935, postal: '8861', name: 'TJØTTA' },
  { id: 1936, postal: '8883', name: 'HUSVIKA' },
  { id: 1937, postal: '8897', name: 'BARDAL' },
  { id: 1938, postal: '8902', name: 'BRØNNØYSUND' },
  { id: 1939, postal: '8904', name: 'BRØNNØYSUND' },
  { id: 1940, postal: '8905', name: 'BRØNNØYSUND' },
  { id: 1941, postal: '8906', name: 'BRØNNØYSUND' },
  { id: 1942, postal: '8907', name: 'BRØNNØYSUND' },
  { id: 1943, postal: '8908', name: 'BRØNNØYSUND' },
  { id: 1944, postal: '8909', name: 'BRØNNØYSUND' },
  { id: 1945, postal: '8910', name: 'BRØNNØYSUND' },
  { id: 1946, postal: '8960', name: 'HOMMELSTØ' },
  { id: 1947, postal: '8961', name: 'HOMMELSTØ' },
  { id: 1948, postal: '8976', name: 'VEVELSTAD' },
  { id: 1949, postal: '8977', name: 'VEVELSTAD' },
  { id: 1950, postal: '8978', name: 'HESSTUN' },
  { id: 1951, postal: '8985', name: 'YLVINGEN' },
  { id: 1952, postal: '9001', name: 'TROMSØ' },
  { id: 1953, postal: '9002', name: 'TROMSØ' },
  { id: 1954, postal: '9003', name: 'TROMSØ' },
  { id: 1955, postal: '9013', name: 'TROMSØ' },
  { id: 1956, postal: '9021', name: 'TROMSDALEN' },
  { id: 1957, postal: '9023', name: 'KROKELVDALEN' },
  { id: 1958, postal: '9029', name: 'TROMSDALEN' },
  { id: 1959, postal: '9037', name: 'TROMSØ' },
  { id: 1960, postal: '9038', name: 'TROMSØ' },
  { id: 1961, postal: '9054', name: 'MALANGSEIDET' },
  { id: 1962, postal: '9057', name: 'VIKRAN' },
  { id: 1963, postal: '9101', name: 'KVALØYSLETTA' },
  { id: 1964, postal: '9102', name: 'KVALØYSLETTA' },
  { id: 1965, postal: '9103', name: 'SKULSFJORD' },
  { id: 1966, postal: '9104', name: 'KVALØYA' },
  { id: 1967, postal: '9105', name: 'KVALØYA' },
  { id: 1968, postal: '9107', name: 'TROMVIK' },
  { id: 1969, postal: '9108', name: 'KVALØYA' },
  { id: 1970, postal: '9144', name: 'SAMUELSBERG' },
  { id: 1971, postal: '9145', name: 'SAMUELSBERG' },
  { id: 1972, postal: '9147', name: 'BIRTAVARRE' },
  { id: 1973, postal: '9149', name: 'BIRTAVARRE' },
  { id: 1974, postal: '9152', name: 'SØRKJOSEN' },
  { id: 1975, postal: '9154', name: 'STORSLETT' },
  { id: 1976, postal: '9155', name: 'SØRKJOSEN' },
  { id: 1977, postal: '9157', name: 'STORSLETT' },
  { id: 1978, postal: '9158', name: 'STORSLETT' },
  { id: 1979, postal: '9164', name: 'KVÆNANGSFJELLET' },
  { id: 1980, postal: '9170', name: 'LONGYEARBYEN' },
  { id: 1981, postal: '9171', name: 'LONGYEARBYEN' },
  { id: 1982, postal: '9172', name: 'ISFJORD PÅ SVALBARD' },
  { id: 1983, postal: '9173', name: 'NY-ÅLESUND' },
  { id: 1984, postal: '9174', name: 'HOPEN' },
  { id: 1985, postal: '9175', name: 'SVEAGRUVA' },
  { id: 1986, postal: '9176', name: 'BJØRNØYA' },
  { id: 1987, postal: '9177', name: 'HORNSUND' },
  { id: 1988, postal: '9178', name: 'BARENTSBURG' },
  { id: 1989, postal: '9179', name: 'PYRAMIDEN' },
  { id: 1990, postal: '9187', name: 'VALANHAMN' },
  { id: 1991, postal: '9195', name: 'ÅRVIKSAND' },
  { id: 1992, postal: '9240', name: 'TROMSØ' },
  { id: 1993, postal: '9290', name: 'TROMSØ' },
  { id: 1994, postal: '9291', name: 'TROMSØ' },
  { id: 1995, postal: '9292', name: 'TROMSØ' },
  { id: 1996, postal: '9293', name: 'TROMSØ' },
  { id: 1997, postal: '9294', name: 'TROMSØ' },
  { id: 1998, postal: '9295', name: 'TROMSØ' },
  { id: 1999, postal: '9296', name: 'TROMSØ' },
  { id: 2000, postal: '9297', name: 'TROMSØ' },
  { id: 2001, postal: '9298', name: 'TROMSØ' },
  { id: 2002, postal: '9299', name: 'TROMSØ' },
  { id: 2003, postal: '9306', name: 'FINNSNES' },
  { id: 2004, postal: '9307', name: 'FINNSNES' },
  { id: 2005, postal: '9308', name: 'FINNSNES' },
  { id: 2006, postal: '9309', name: 'FINNSNES' },
  { id: 2007, postal: '9327', name: 'BARDUFOSS' },
  { id: 2008, postal: '9336', name: 'RUNDHAUG' },
  { id: 2009, postal: '9376', name: 'SKATVIK' },
  { id: 2010, postal: '9388', name: 'FJORDGARD' },
  { id: 2011, postal: '9395', name: 'KALDFARNES' },
  { id: 2012, postal: '9402', name: 'HARSTAD' },
  { id: 2013, postal: '9403', name: 'HARSTAD' },
  { id: 2014, postal: '9404', name: 'HARSTAD' },
  { id: 2015, postal: '9405', name: 'HARSTAD' },
  { id: 2016, postal: '9406', name: 'HARSTAD' },
  { id: 2017, postal: '9407', name: 'HARSTAD' },
  { id: 2018, postal: '9408', name: 'HARSTAD' },
  { id: 2019, postal: '9409', name: 'HARSTAD' },
  { id: 2020, postal: '9411', name: 'HARSTAD' },
  { id: 2021, postal: '9414', name: 'HARSTAD' },
  { id: 2022, postal: '9415', name: 'HARSTAD' },
  { id: 2023, postal: '9416', name: 'HARSTAD' },
  { id: 2024, postal: '9419', name: 'SØRVIK' },
  { id: 2025, postal: '9420', name: 'LUNDENES' },
  { id: 2026, postal: '9423', name: 'GRØTAVÆR' },
  { id: 2027, postal: '9424', name: 'KJØTTA' },
  { id: 2028, postal: '9425', name: 'SANDSØY' },
  { id: 2029, postal: '9426', name: 'BJARKØY' },
  { id: 2030, postal: '9427', name: 'MELØYVÆR' },
  { id: 2031, postal: '9430', name: 'SANDTORG' },
  { id: 2032, postal: '9455', name: 'ENGENES' },
  { id: 2033, postal: '9456', name: 'ENGENES' },
  { id: 2034, postal: '9465', name: 'TENNEVOLL' },
  { id: 2035, postal: '9466', name: 'TENNEVOLL' },
  { id: 2036, postal: '9477', name: 'HARSTAD' },
  { id: 2037, postal: '9478', name: 'HARSTAD' },
  { id: 2038, postal: '9479', name: 'HARSTAD' },
  { id: 2039, postal: '9480', name: 'HARSTAD' },
  { id: 2040, postal: '9481', name: 'HARSTAD' },
  { id: 2041, postal: '9482', name: 'HARSTAD' },
  { id: 2042, postal: '9483', name: 'HARSTAD' },
  { id: 2043, postal: '9484', name: 'HARSTAD' },
  { id: 2044, postal: '9485', name: 'HARSTAD' },
  { id: 2045, postal: '9486', name: 'HARSTAD' },
  { id: 2046, postal: '9487', name: 'HARSTAD' },
  { id: 2047, postal: '9488', name: 'HARSTAD' },
  { id: 2048, postal: '9489', name: 'HARSTAD' },
  { id: 2049, postal: '9490', name: 'HARSTAD' },
  { id: 2050, postal: '9491', name: 'HARSTAD' },
  { id: 2051, postal: '9496', name: 'HARSTAD' },
  { id: 2052, postal: '9497', name: 'HARSTAD' },
  { id: 2053, postal: '9498', name: 'HARSTAD' },
  { id: 2054, postal: '9499', name: 'HARSTAD' },
  { id: 2055, postal: '9509', name: 'ALTA' },
  { id: 2056, postal: '9526', name: 'SUOLOVUOPMI' },
  { id: 2057, postal: '9532', name: 'HAKKSTABBEN' },
  { id: 2058, postal: '9551', name: 'ØKSFJORD' },
  { id: 2059, postal: '9590', name: 'HASVIK' },
  { id: 2060, postal: '9591', name: 'HASVIK' },
  { id: 2061, postal: '9600', name: 'HAMMERFEST' },
  { id: 2062, postal: '9601', name: 'HAMMERFEST' },
  { id: 2063, postal: '9602', name: 'HAMMERFEST' },
  { id: 2064, postal: '9603', name: 'HAMMERFEST' },
  { id: 2065, postal: '9609', name: 'HØNSEBY' },
  { id: 2066, postal: '9610', name: 'RYPEFJORD' },
  { id: 2067, postal: '9611', name: 'RYPEFJORD' },
  { id: 2068, postal: '9612', name: 'FORSØL' },
  { id: 2069, postal: '9613', name: 'HAMMERFEST' },
  { id: 2070, postal: '9651', name: 'LANGSTRAND' },
  { id: 2071, postal: '9653', name: 'HELLEFJORD' },
  { id: 2072, postal: '9663', name: 'SKARVFJORDHAMN' },
  { id: 2073, postal: '9709', name: 'PORSANGMOEN' },
  { id: 2074, postal: '9712', name: 'LAKSELV' },
  { id: 2075, postal: '9713', name: 'RUSSENES' },
  { id: 2076, postal: '9716', name: 'BØRSELV' },
  { id: 2077, postal: '9722', name: 'SKOGANVARRE' },
  { id: 2078, postal: '9740', name: 'LEBESBY' },
  { id: 2079, postal: '9755', name: 'HONNINGSVÅG' },
  { id: 2080, postal: '9763', name: 'SKARSVÅG' },
  { id: 2081, postal: '9765', name: 'GJESVÆR' },
  { id: 2082, postal: '9771', name: 'SKJÅNES' },
  { id: 2083, postal: '9782', name: 'DYFJORD' },
  { id: 2084, postal: '9783', name: 'NORDMANNSET I LAKSEFJORD' },
  { id: 2085, postal: '9815', name: 'VADSØ' },
  { id: 2086, postal: '9901', name: 'KIRKENES' },
  { id: 2087, postal: '9911', name: 'JARFJORD' },
  { id: 2088, postal: '9917', name: 'KIRKENES' },
  { id: 2089, postal: '9934', name: 'BUGØYFJORD' },
  { id: 2090, postal: '9952', name: 'VARDØ' },
  { id: 2091, postal: '9960', name: 'KIBERG' }
]

const designations = {
  null: {
    title: 'Standard (,-)',
    value: null
  },
  0: {
    title: 'Ingen',
    value: 0
  },
  1: {
    title: 'Pr stk',
    value: 1
  },
  2: {
    title: 'Pr kvm',
    value: 2
  }
}

export {
  roomCategories,
  projectTypes,
  ownershipTypes,
  energyTypes,
  postalCodes,
  designations
}

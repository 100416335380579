module.exports = {
  dashboard: 'Dashboard',
  projects: 'Prosjekter',
  prospects: 'Prospekter',
  users: 'Brukere',
  products: 'Produkter',
  suppliers: 'Leverandører',
  overview: 'Oversikt',
  unit: 'Enhet',
  units: 'Enheter',
  people: 'Personer',
  settings: 'Innstillinger',
  roles: 'Roller',
  name: 'Navn',
  cabin: 'Hytte',
  cabins: 'Hytter',
  apartment: 'Leilighet',
  apartments: 'Leiligheter',
  house: 'Hus',
  houses: 'Hus',
  industry: 'Næring',
  industrys: 'Næringsbygg',
  other: 'Annet',
  kitchen: 'Kjøkken',
  kitchen_livingroom: 'Kjøkken/Stue',
  livingroom: 'Stue',
  bedroom: 'Soverom',
  bath: 'Baderom',
  wc: 'WC',
  laundryroom: 'Vaskerom',
  wc_laundryroom: 'WC/Vaskerom',
  room: 'Rom',
  rooms: 'Rom',
  cancel: 'Avbryt',
  back: 'Tilbake',
  plan: 'Planlegg',
  planner: 'Planlegger',
  planning: 'Planlegging',
  events: 'Hendelser',
  eventslog: 'Hendelseslogg',
  tasks: 'Oppgaver',
  category: 'Kategori',
  categories: 'Kategorier',
  success: 'Suksess',
  sales: 'Salg',
  title: 'Tittel',
  loading: 'Laster inn',
  styletype: 'Stiltype',
  styletypes: 'Stiltyper',
  landingpage: 'Landingsside',
  designer: 'Designer',
  statistics: 'Statistikk',
  leads: 'Leads',
  userconfigurations: 'Brukerkonfigureringer',
  references: 'Referanser',
  description: 'Beskrivelse',
  jobtitle: 'Arbeidstittel',
  email: 'Epost',
  emails: 'Eposter',
  salestext: 'Salgstekst',
  homedesigner: 'Boligdesigner',
  homeconfigurator: 'Boligkonfigurator',
  homeconfiguratorimage: 'Konfiguratorbilde',
  close: 'Lukk',
  save: 'Lagre',
  productinformation: 'Produktinformasjon',
  price: 'Pris',
  layer: 'Lag',
  confimg: 'Konf.img',
  standard: 'Standard',
  error: 'Error',
  delete: 'Slett',
  none: 'Ingen',
  linked: 'Kobling',
  links: 'Lenker',
  complete: 'Ferdig',
  edit: 'Rediger',
  filter: 'Filter',
  image: 'Bilde',
  order: 'Rekkefølge',
  productimage: 'Produktbilde',
  activities: 'Aktiviteter',
  files: 'Filer',
  exterior: 'Eksteriør',
  plot: 'Tomt',
  plots: 'Tomter',
  varycode: 'Varykode',
  abort: 'Avbryt',
  postal: 'Poststed',
  choose: 'Velg',
  owner: 'Eier',
  share: 'Andel',
  stock: 'Aksje',
  bond: 'Obligasjon',
  companylogo: 'Firmalogo',
  companyname: 'Firmanavn',
  slogan: 'Slagord',
  profileimage: 'Profilbilde',
  position: 'Stilling',
  phone: 'Telefon',
  mobile: 'Mobil',
  phonenumber: 'Telefonnummer',
  chooser: 'Velger',
  administrate: 'Administrer',
  hall: 'Hall',
  residential: 'Bolig',
  loft: 'Hems',
  detached: 'Enebolig',
  icon: 'Ikon',
  folder: 'Mappe',
  folders: 'Mapper'
}
